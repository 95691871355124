import { Link } from 'react-router-dom';
function Writing() {
  return (
    <div className='container-fluid' style={{ margin: '2rem auto' }}>
      <h2>Alfa Print&nbsp;: Les meilleurs prix sur vos imprimantes &amp; scanners.</h2>
      <p className='pComp'><strong>Alfa Print</strong>&nbsp;est l&rsquo;un des plus grands constructeurs de mat&eacute;riel d&rsquo;impression. Nous proposons une grande gamme de mat&eacute;riels comme des&nbsp;<a href='https://alfaprint.fr/imprimante-multifonction/'>imprimantes multifonctions</a>,&nbsp;<strong>scanners, photocopieuses</strong>,&nbsp;le tout avec des consommables d&rsquo;impressions, cartouches d&rsquo;encre et toners laser. Tous nos produits sont &agrave; des prix d&eacute;fiants toute concurrence en&nbsp;<strong>France</strong>&nbsp;et de qualit&eacute;.</p>
      <p className='pComp'>Nous vous accompagnons en mettant &agrave; votre disposition une large gamme sur les&nbsp;<strong>imprimantes, photocopieurs et scanners</strong> en marques Brother,HP&nbsp;, Epson,Lexmark,Canon,Xerox pour r&eacute;pondre &agrave; votre demande.</p>
      <p className='pComp'>&nbsp;</p>
      <p className='pComp'>Alfa Print &oelig;uvre dans l&rsquo;innovation, en vous proposant des solutions in&eacute;dites qui vont r&eacute;volutionner le monde de la distribution. Les solutions mises &agrave; vos dispositions<strong>&nbsp;sont les contrats et abonnements sur les imprimantes et consommables: cartouches d'encre et toners.</strong></p>
      <p className='pComp'>Pour approvisionner votre bureau en imprimantes, scanners, &eacute;crans, casques ou ordinateurs, choisissez Alfa Print qui est le meilleur dans ce domaine. Notre &eacute;quipe est constamment disponible pour vous proposer la meilleure offre qui va r&eacute;pondre &agrave; vos besoins.&nbsp;&nbsp;Nous vous assurons qu&rsquo;en prenant contact avec nos experts, vous pouvez &ecirc;tre s&ucirc;r de prendre seulement le mat&eacute;riel dont vous avez besoin. Nous pouvons vous accompagner sur la construction de vos projets en A4 ou en A3,&nbsp;<strong>monochrome&nbsp;</strong>ou couleur,&nbsp;<strong>imprimante monofonction ou photocopieur multifonction&nbsp;</strong>avec des accessoires, en plus,&nbsp;<strong>meuble support</strong>&nbsp;ou&nbsp;<strong>module de finition avec agrafages et pour finir des bacs &agrave; papiers.</strong></p>
      <p className='pComp'>&nbsp;</p>
      <h2 className='h2Comp'>&nbsp;Laser, jet d'encre, jet d&rsquo;encre professionnel et Poches d&rsquo;encre&nbsp;:</h2>
      <p className='pComp'><strong>&nbsp;</strong></p>
      <p className='pComp'>Les imprimantes &agrave;&nbsp;<strong>jet d&rsquo;encre</strong>&nbsp;utilisent des&nbsp;<a href='https://alfaprint.fr/consommable/cartouche/'>cartouches</a>&nbsp;classiques ou de pigment pour fonctionner. De plus, la nouvelle technologie&nbsp;<strong>jet d&rsquo;encre professionnel</strong>&nbsp;est dot&eacute;e d&rsquo;une qualit&eacute; de jet d&rsquo;encre classique et des&nbsp;<strong>performances sur la rapidit&eacute; d&rsquo;impression.</strong></p>
      <p className='pComp'>Cette nouvelle technologie est adapt&eacute;e pour&nbsp;<strong>le milieu scolaire et professionnel</strong>&nbsp;pour les petits bureaux qui impriment des<strong>&nbsp;documents vari&eacute;s</strong>. Elle se d&eacute;cline sur des mod&egrave;les &agrave; poche d&rsquo;encre pour les gros volumes d&rsquo;impression.&nbsp;<strong>Les poches d&rsquo;encre&nbsp;</strong>vous permettent d&rsquo;obtenir un&nbsp;<strong>co&ucirc;t d&rsquo;impression</strong>&nbsp;plus faible. Gr&acirc;ce aux&nbsp;<strong>imprimantes jet d&rsquo;encre,</strong>&nbsp;vous obtiendrez des couleurs plus riches, une vitesse d&rsquo;impression, des fonctionnalit&eacute;s professionnelles &agrave; un co&ucirc;t par page inf&eacute;rieur.</p>
      <p className='pComp'>&nbsp;</p>
      <p className='pComp'>Lorsque vous achetez&nbsp;<strong>une imprimante &agrave; jet d&rsquo;encre</strong>, veillez &agrave; avoir une r&eacute;serve d&rsquo;encre dans votre bureau, pour toujours en avoir &agrave; proximit&eacute;. Les imprimantes laser couleur sont adapt&eacute;es pour l&rsquo;impression &agrave; grande vitesse et peuvent traiter de gros volumes. Pour l&rsquo;impression de brochures commerciales, ou de radiographie, n&rsquo;h&eacute;sitez pas &agrave; prendre des imprimantes &agrave; jet d&rsquo;encre pour un rendu de qualit&eacute;. Si vous &ecirc;tes &agrave; la recherche de r&eacute;sultats de haute qualit&eacute; et qui impriment des graphiques, par exemple des images vectorielles,&nbsp;<strong>la technologie laser</strong>&nbsp;est la solution qu&rsquo;il vous faut.&nbsp;&nbsp;</p>
      <p className='pComp'>&nbsp;</p>
      <p className='pComp'>Alfa Print met &agrave; disposition des imprimantes&nbsp;<a href='https://alfaprint.fr/imprimante-laser/'>laser couleur, laser monochrome</a>, <a href='https://alfaprint.fr/imprimante-jet-d-encre/'> jet d&rsquo;encre </a>en&nbsp;<strong>A4 et A3</strong>&nbsp;sur les marques HP,Brother,Samsung,Epson et Canon</p>
      <p className='pComp'>&nbsp;</p>
      <h2 className='h2Comp'>Imprimantes&nbsp;: Bluetooth et wifi &agrave; faible co&ucirc;t :</h2>
      <p className='pComp'>Pour avoir une plus grande rapidit&eacute; et faire un travail encore plus efficace, nous vous recommandons de choisir des&nbsp;<strong>imprimantes Bluetooth et/ou Wifi.</strong>&nbsp;Ce type d&rsquo;imprimante vous fait gagner en temps, car vous pouvez lancer vos impressions directement &agrave; partir de votre Smartphone, tablette ou votre ordinateur.</p>
      <h3 className='h3Comp'>Choisissez l'imprimante qui r&eacute;pond &agrave; vos besoins</h3>
      <p className='pComp'>Chez Alfa Print nous pensons avant tout &agrave; vous aider &agrave; continuer de faire un travail efficace, c&rsquo;est pourquoi nous vous proposons une large gamme&nbsp;<strong>d&rsquo;imprimantes Wifi</strong>&nbsp;et r&eacute;seau avec une vitesse d&rsquo;impression sup&eacute;rieure &agrave; 40 pages par minute et une haute qualit&eacute; d&rsquo;impression.</p>
      <p className='pComp'>Si vous souhaitez souscrire &agrave; des&nbsp;<strong>abonnements imprimantes, <a href='https://alfaprint.fr/consommable/cartouche/'>cartouches</a>, toners et contrat de maintenance</strong>, n&rsquo;h&eacute;sitez pas &agrave;&nbsp;<strong>nous contacter directement</strong>. De plus, Alfa Print vous propose la mise en service de votre mat&eacute;riel, la formation sur l&rsquo;utilisation des logiciels et mat&eacute;riels, l&rsquo;assistance technique et la maintenance ou&nbsp;<strong>r&eacute;paration des imprimantes.</strong></p>
      <p className='pComp'>&nbsp;</p>
      <p className='pComp'>Nos commerciaux sont l&agrave; pour apporter une&nbsp;<strong>valeur ajout&eacute;e &agrave; vos demandes, r&eacute;pondre</strong>&nbsp;&agrave; toutes vos questions et vous accompagne dans&nbsp;<strong>la construction de vos projets.</strong></p>

    </div>
  );
}
export default Writing;
