import OwlCarousel from 'react-owl-carousel';
import './evaluation.css';
import ThingsContext from '../../context';
import { useContext } from 'react';
import { useEffect, useState } from 'react';

function Evaluation() {
  const context = useContext(ThingsContext);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    // POST request using fetch inside useEffect React hook

    setIsLoaded(true);

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);



  const Evaluation=[{
    name:"Jawad Bachare",
    description:"Excellent service , des réponses et une assistance efficace ! Qualité du matériel excellente ! Bref une entreprise 5 étoiles.",
    image:"assets/images/clients/client1.webp",
    numberOfstars:"",
    profession:"Directeur Executi chez CCIF* ADDH"

  },
  {
    name:"Jacqueline JOSSERAND",
    description:" Franchement c'est très pratique,commande faite le matin,récupérée le soir.Prix intéressant. Parfait",
    image:"assets/images/clients/client5.webp",
    numberOfstars:"assets/imagev2/5 stars.webp",
    profession:"Avocate"
  },
  {
    name:"Anne FLAMANT",
    description:"Très professionnel Rapide Je recommande!",
    image:"assets/images/clients/client4.webp",
    numberOfstars:"assets/imagev2/5 stars.webp",
    profession:"Assistante de direction"

  },
  {
    name:"Hervé brites",
    description:"",
    image:"assets/images/clients/client8.webp",
    numberOfstars:"assets/imagev2/5 stars.webp",
    profession:"Directeur d’agence de transport"

  },
]
  if (isLoaded) {
    return (
      <div className='testimonials-section'>
        <div className='container-fluid'>
          <h2
            className='section-title'
            style={{
              textTransform: 'none',
            }}
          >
            Ils nous font confiance
          </h2>
          <hr></hr>
          <img
            style={{ margin: 'auto', width: '24rem', marginBottom: '1rem' }}
            className='text-center'
            src={'assets/imagev2/note google.' + context}
            alt='Nos avis de Google'
          />
 
          <OwlCarousel
            autoplay
            loop
            smartSpeed={2888}
            dots={false}
            autoplayTimeout={6000}
            items={1}
            lazyLoad
            className='testimonials-carousel owl-carousel owl-theme images-left'
          >
                     {Evaluation.map((data,key)=>
            <div className='testimonial' key={key}>
              {/* End .testimonial-owner */}

              <p className='text-center'>
                {data.description}
              </p>
              <img
                style={{ margin: 'auto', width: '11rem' }}
                className='text-center owl-lazy'
                data-src={data.image}
              />
              
              <figure>
                <img
                  className='owl-lazy'
                  style={{ margin: '1rem auto', width: '8rem' }}
                  data-src={data.numberOfstars}
                  alt='Notre client Jacqueline JOSSERAND'
                />
              </figure>
              <p
                className='text-center nom-evaluer'
                style={{ color: '#2aaad0' }}
              >
               {data.name}
              </p>
              <p className='text-center' style={{ color: '#777' }}>
              {data.profession}
              </p>
            </div>
              )}
            {/* End .testimonial */}
       
            {/* End .testimonial */}
          </OwlCarousel>
        
          {/* End .testimonials-slider */}
        </div>
        {/* End .container */}
      </div>
    );
  } else {
    return <div></div>;
  }
}
export default Evaluation;
