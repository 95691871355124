import './cookie.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

function Cookie() {
  const [isloaded, setLoaded] = useState(false);
  const [popupcookies, setpopupcookies] = useState(false);
  const [Po, setPo] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      let cookie = localStorage.getItem('cookies');
      if (cookie == null) {
        setLoaded(true);
      }
    }, 5000);
  }, []);
  useEffect(() => {
    if (!localStorage.getItem('popup')) {
      setTimeout(() => {
        setPo(true);
      }, 5000);
    }
  }, []);
  function closemodal() {
    setPo(false);
  }
  const stop_Showing_Popup = (e) => {
    if (e.target.checked) {
      localStorage.setItem('popup', true);
    } else {
      localStorage.removeItem('popup');
    }
  };
  if (isloaded) {
    return (
      <div>
      {popupcookies && (
        <div className='popup-conditions'>
          <div
            className='bg-popup'
            onClick={() => {
              setpopupcookies(false);
              closemodal();
            }}
          ></div>
          <div className='conditions-container'>
            <div
              id='swal2-content'
              style={{ display: 'block', width:'90%',marginLeft:'5%' }}
            >
            <h1 className='h1Comp'>Que représentent les cookies ?</h1>

            <p class='pComp' style={{ textAlign: 'left' }}>
              Les <strong>cookies</strong> sont des fichiers stockés sur votre
              <strong> ordinateur</strong> quand vous vous rendez sur certaines
              pages web. Très souvent, lorsque vous allez sur un site, on vous
              propose deux options. Vous avez le choix de choisir que l’on utilise
              vos cookies ou pas. Il faut savoir que les cookies nous permettent
              de personnaliser vos services. En fonction des différentes pages que
              vous visitez, nous allons vous proposer des produits adaptés à vos
              recherches. C’est avantageux pour vous, car nous faisons les
              recherches à votre place et vous n’avez qu’à choisir en fonction des
              différentes propositions.
            </p>
            <p class='pComp' style={{ textAlign: 'left' }}>
              Cependant, nous tenons à vous rassurer <strong>Alfa Print</strong> ,
              ne sauvegarde en aucun cas vos données personnelles telles que votre
              carte de crédit dans les cookies. Les cookies vont nous permettre
              d’analyser le temps que vous passez sur une page.
            </p>
            <h2
              class='h3Comp'
              style={{
                color: '#005d86',
                fontWeight: '600',
                marginTop: '10px',
                marginBottom: '10px',
                fontSize: '23px',
              }}
            >
              Politique sur les cookies :
            </h2>

            <p class='pComp' style={{ textAlign: 'left' }}>
              Les différents <strong>cookies</strong> que nous utilisons sur notre
              site sont :
            </p>
            <ul class='ulComp'>
              <li>
                Les cookies de ciblage : nous utilisons les cookies de ciblage,
                car ils nous permettent d’analyser la façon dont vous visitez le
                site. Ça nous permet de savoir sur quoi vous vous dirigez dès
                votre arrivée sur le site, vos préférences. Grâce à cela, nous
                pourrons rendre notre site plus promotionnel et adapté à vos
                besoins.
              </li>
              <li>
                Les cookies analytiques : ils représentent les outils qui vont
                nous permettre d’améliorer la conception et la fonctionnalité de
                notre site internet. Nous allons recueillir des données sur les
                différents sites que vous avez l’habitude de visiter. Par la
                suite, nous allons pouvoir, vous proposez une meilleure ergonomie
                de notre site.
              </li>
              <li>
                Les cookies fonctionnels : nous utilisons les cookies
                fonctionnels, car ils nous permettent de personnaliser votre
                visite sur notre site internet. Lors de votre arrivée sur le site,
                on vous montrera les différents articles que vous avez consultés
                lors de votre dernière visite. Les articles qui ont été mis dans
                vos paniers, ça va vous permettre de continuer votre achat. Vous
                pouvez être sûr qu’avec les cookies fonctionnels, vous n’aurez
                plus l’impression d’avoir perdu vos recherches.
              </li>
            </ul>
            <h2
              class='h3Comp'
              style={{
                color: '#005d86',
                fontWeight: '600',
                marginTop: '10px',
                marginBottom: '10px',
                fontSize: '23px',
              }}
            >
              Comment gérer les cookies ?
            </h2>

            <p class='pComp' style={{ textAlign: 'left' }}>
              Nous allons vous redéfinir les différentes options pour pouvoir
              gérer les cookies. Il faut savoir que les cookies ne sont pas
              obligatoires, si cela ne vous intéresse plus, vous aurez la
              possibilité de désactiver l’option. Néanmoins, après votre
              désactivation, nous ne pourrons plus vous proposer directement le
              matériel nécessaire adapté à votre recherche.
            </p>

            <h3
              class='h3Comp'
              style={{
                color: '#005d86',
                fontWeight: '600',
                marginTop: '10px',
                marginBottom: '10px',
                fontSize: '2rem',
              }}
            >
              • Si vous donnez votre accord :
            </h3>

            <p class='pComp' style={{ textAlign: 'left' }}>
              {' '}
              Lorsque vous donnez votre accord pour les cookies, les différents
              navigateurs que vous utilisez dans votre terminal donnent accès a
              l’enregistrement de vos cookies. Les Cookies des différentes pages
              et contenus que vous consultés seront automatiquement enregistrés
              sur votre terminal. Les différents cookies vont permettre de vous
              proposer à chaque fois du contenu qui pourrait vous intéresser et
              donner envie d’acheter. Les cookies donnent accès à une
              personnalisation instantanée des produits que vous pourriez aimer.
            </p>
            <h3
              class='h3Comp'
              style={{
                color: '#005d86',
                fontWeight: '600',
                marginTop: '10px',
                marginBottom: '10px',
                fontSize: '2rem',
              }}
            >
              • Si vous ne donnez pas votre accord :
            </h3>

            <p class='pComp' style={{ textAlign: 'left' }}>
              Lorsque vous refusez de partager vos cookies, vous avez deux options
              :
            </p>
            <ul class='ulComp'>
              <li>
                Quand vous êtes sur une page, refuser directement en cliquant « je
                refuse », ça va empêcher à la page ou au serveur sur lequel vous
                êtes d’enregistrer vos données. Dans ce cas, il faut savoir qu’à
                chaque fois que vous allez vous rendre sur une page, vous recevrez
                toujours la demande de sauvegarder vos cookies ou pas.
              </li>
              <li>
                Vous pouvez paramétrer votre navigateur de sorte à refuser
                directement toute sauvegarde des cookies. Néanmoins, il faut
                savoir que le refus des cookies peut modifier votre navigation sur
                Internet et la possibilité d’accéder à certains privilèges,
                services.{' '}
              </li>
            </ul>

            <h3
              class='h3Comp'
              style={{
                color: '#005d86',
                fontWeight: '600',
                marginTop: '10px',
                marginBottom: '10px',
                fontSize: '2rem',
              }}
            >
              • Si vous supprimez l’accès à vos cookies :
            </h3>

            <p class='pComp' style={{ textAlign: 'left' }}>
              {' '}
              Lorsque vous supprimez l’accès à vos cookies, nous ne pouvons plus
              vous proposer des services entièrement personnalisés sans avoir à
              vous consulter au préalable. Nous ne serons pas responsables de la
              possible dégradation de nos services. Les cookies permettent aussi
              un bon fonctionnement du site, car nous avons la possibilité de voir
              ce que vous aimez, les différents sites que vous visitez. De plus,
              en fonction de ça, nous avons la possibilité de vous proposer des
              services utiles à vos recherches.
            </p>
            <br />
            <p class='pComp' style={{ textAlign: 'left', fontWeight: '600' }}>
              {' '}
              NB : Les cookies représentent un avantage pour vous, meilleure
              navigation sur le site, présentation de services adaptés à vos
              recherches sans avoir à contacter directement l’entreprise. Si vous
              trouvez que les cookies après utilisation, ne représentent aucun
              intérêt pour vous, vous avez la possibilité de vous rétracter en
              prenant en considération la rubrique «Si vous supprimez l’accès à
              vos cookies ».
            </p>

            <br />
            </div>
          </div>
        </div>
      )}
      <div className='cookies2' className={isloaded ? 'dispaly-block cookies2' : 'cookies2'}>

        <p className='cookiep'>
          Notre site internet utilise des cookies <br />
          <span style={{ fontWeight: '300' }}>
            Les cookies sont utilisés pour améliorer l'expérience utilisateur et
            à des fins statistiques. En cliquant sur j'accepte , vous acceptez
            l'utilisation de cookies . Pour plus d'informations{' '}
            <a href='javascript:void()' onClick={() => {
              setpopupcookies(true);
            }}>Cliquez ici</a>{' '}
          </span>{' '}
        </p>
        <button
          className=' btn btn-cookie '
          onClick={() => {
            localStorage.setItem('cookies', 'false');
            setLoaded(false);
            closemodal();
          }}
        >
          J'accepte
        </button>
        <button
          className=' btn  btn-cookie2'
          onClick={() => {
            localStorage.setItem('cookies', 'false');
            setLoaded(false);
            closemodal();
          }}
        >
        <a href='javascript:void()' onClick={() => {
              setpopupcookies(true);
            }}>Je refuse</a>{' '}
        </button>
      </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
export default Cookie;
