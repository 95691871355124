function Counter() {
  return (
    <div className='counters-section bg-gray'>
      <div className='container'>
        <div className='row'>
          <div className='col-6 col-md-4 count-container'>
            <div className='count-wrapper'>
              <span
                className='count-to'
                data-from={0}
                data-to={200}
                data-speed={2000}
                data-refresh-interval={50}
              >
                2000
              </span>
              +
            </div>
            {/* End .count-wrapper */}
            <h4 className='count-title'> Clients PEM-TPE satisfait</h4>
          </div>
          {/* End .col-md-4 */}
          <div className='col-6 col-md-4 count-container'>
            <div className='count-wrapper'>
              <span
                className='count-to'
                data-from={0}
                data-to={1800}
                data-speed={2000}
                data-refresh-interval={50}
              >
                70 000
              </span>
              +
            </div>
            {/* End .count-wrapper */}
            <h4 className='count-title'>Produits disponible</h4>
          </div>
          {/* End .col-md-4 */}
          <div className='col-6 col-md-4 count-container'>
            <div className='count-wrapper'>
              <span
                className='count-to'
                data-from={0}
                data-to={24}
                data-speed={2000}
                data-refresh-interval={50}
              >
                24
              </span>
              <span>Heure</span>
            </div>
            {/* End .count-wrapper */}
            <h4 className='count-title'>Support technique</h4>
          </div>
          {/* End .col-md-4 */}
          <div className='col-6 col-md-4 count-container'>
            <div className='count-wrapper'>
              <span
                className='count-to'
                data-from={0}
                data-to={265}
                data-speed={2000}
                data-refresh-interval={50}
              >
                100
              </span>
              %
            </div>
            {/* End .count-wrapper */}
            <h4 className='count-title'>Livraison rapide</h4>
          </div>
          {/* End .col-md-4 */}
          <div className='col-6 col-md-4 count-container'>
            <div className='count-wrapper'>
              <span
                className='count-to'
                data-from={0}
                data-to={99}
                data-speed={2000}
                data-refresh-interval={50}
              >
                100
              </span>
              <span>%</span>
            </div>
            {/* End .count-wrapper */}
            <h4 className='count-title'>Prix exclusif</h4>
          </div>
          {/* End .col-md-4 */}
        </div>
        {/* End .row */}
      </div>
      {/* End .container */}
    </div>
  );
}
export default Counter;
