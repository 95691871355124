import * as React from 'react';
import "./landingPage.css";
import MetaTags from 'react-meta-tags';
import Printer from "../../assets/landing_page_elements/Printers.webp";
import bg from "../../assets/landing_page_elements/3.png";
import p1 from "../../assets/landing_page_elements/40860.webp";
import p2 from "../../assets/landing_page_elements/37721.webp";
import p3 from "../../assets/landing_page_elements/41748.webp";
import p4 from "../../assets/landing_page_elements/41556.webp";
import p5 from "../../assets/landing_page_elements/38906.webp";
import p6 from "../../assets/landing_page_elements/40869.webp";
import p7 from "../../assets/landing_page_elements/41453.webp";
import p8 from "../../assets/landing_page_elements/37439.webp";
import pr from "../../assets/landing_page_elements/c3110_large.png";
import banner from "../../assets/landing_page_elements/image.png";
import testimonials from "../../assets/landing_page_elements/77.png";
import list_confiance from "../../assets/landing_page_elements/Nos clients.PNG";
import logos from "../../assets/landing_page_elements/logos.png";
import bg_form from "../../assets/landing_page_elements/bg-form.png";
import bg_part6 from "../../assets/landing_page_elements/bg-part6.png";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { Message } from '@mui/icons-material';
function LandingPage(props) {
  const [expanded, setExpanded] = React.useState(false);

  const [popup, setpopup] = React.useState(false);

  const [nom_prenom, setNomPrenom] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [tel, setTel] = React.useState('');
  const [entreprise, setEntreprise] = React.useState('');

  const [nom_prenom2, setNomPrenom2] = React.useState('');
  const [email2, setEmail2] = React.useState('');
  const [tel2, setTel2] = React.useState('');
  const [site, setSite] = React.useState('');
  const [message, setMessage] = React.useState('');

  const handleNomPrenom = (e) => {
    setNomPrenom(e.target.value);
  }

  const handleEmail = (e) => {
    setEmail(e.target.value);
  }

  const handleTel = (e) => {
    setTel(e.target.value);
  }
  const handleEntreprise = (e) => {
    setEntreprise(e.target.value);
  }
  const handleNomPrenom2 = (e) => {
    setNomPrenom2(e.target.value);
  }

  const handleEmail2 = (e) => {
    setEmail2(e.target.value);
  }

  const handleTel2 = (e) => {
    setTel2(e.target.value);
  }
  const handleSite = (e) => {
    setSite(e.target.value);
  }

  const handleMessage = (e) => {
    setMessage(e.target.value);
  }

  const handleChange =
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  const handlesubmit = (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('nom_prenom', nom_prenom);
    form.append('tel', tel);
    form.append('email', email);
    form.append('entreprise', entreprise);
    const requestOptions = {
      method: 'POST',
      body: form,
    };
    fetch(
      'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/contact/demande-devis-v2',
      requestOptions
    ).then((res) =>
      res.json().then((response) => {
        document.getElementById('myform').reset();

        // response = Object.values(response);
        setpopup(true);
        setTimeout(() => {
          setpopup(false);
        }, 3000);
      })
    );
  };
  const handlesubmit2 = (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('nom_prenom', nom_prenom2);
    form.append('tel', tel2);
    form.append('email', email2);
    form.append('site', site);
    form.append('message', message)

    const requestOptions = {
      method: 'POST',
      body: form,
    };
    fetch(
      'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/contact/demande-devis-v2',
      requestOptions
    ).then((res) =>
      res.json().then((response) => {
        document.getElementById("myform2").reset();
        setpopup(true);
        setTimeout(() => {
          setpopup(false);
        }, 3000);
      })
    );
  };
  return (
    <div>
      <MetaTags>
        <title>Formulaire de demande de devis personnalisé - Alfa Print</title>
        <meta
          id='meta-description'
          name='description'
          content='Merci de remplir le formulaire sur notre site web Alfaprint.fr afin que nous répondions au mieux à votre demande.  Alfa Print'
        />
        <meta
          id='og-meta-description'
          property='og:description'
          content='Merci de remplir le formulaire sur notre site web Alfaprint.fr afin que nous répondions au mieux à votre demande.  Alfa Print'
        />
        <meta id='og-meta-type' property='og:type' content='website' />
        <link
          id='meta-canonical'
          rel='canonical'
          href='https://alfaprint.fr/photocopieur/'
        />
        <meta
          id='og-url'
          property='og:url'
          content='https://alfaprint.fr/photocopieur/'
        />
        <meta
          id='title'
          property='title'
          content='Landing Page - Alfa Print'
        />
        <meta id='twitter-card' name="twitter:card" content="summary" />
        <meta id='twitter-site' name="twitter:site" content="Alfa print" />
        <link
          id='hreflang'
          rel='alternate'
          hreflang='fr-fr'
          href='https://alfaprint.fr/photocopieur/'
        />
        <link
          id='hreflangdefault'
          rel='alternate'
          hreflang='x-default'
          href='https://alfaprint.fr/photocopieur/'
        />
        <meta
          id='og-title'
          property='og:title'
          content='Landing Page - Alfa Print'
        />
        <meta
          id='og-image'
          property='og:image'
          content='https://alfaprint.fr/assets/images/head/devis.webp'
        />
      </MetaTags>
      <div className='main-div'>
        <div>
          <div className="background-image">
            <img style={{ width: "100%" }} src={bg} allt="background image" />
          </div>
          <div className="part-1">
            <div className="title-page">
              <h1>
                Votre solution d'impression à partir de 29,90€ HT/mois
              </h1>
            </div>
            <div className="image-form">
              <div className="image">
                <img src={Printer} alt="Image imprimante" />
              </div>
              <div className="part-form">
                <h3>
                  Être rappelé(e) par un conseiller
                </h3>
                <p>
                  Complétez les informations ci-dessous pour être contacté(e)  par un conseiller au plus vite:
                </p>
                <form onSubmit={handlesubmit} id='myform'>
                  <div className="form">
                    <div className="form-line">

                      <input type="text" placeholder="Nom & Prénom*" id="nom_prenom" onChange={handleNomPrenom} required />

                      <input type="email" placeholder="Email" id="email" onChange={handleEmail} />

                      <input type="number" placeholder="Téléphone*" id="tel" onChange={handleTel} required />

                      <input type="text" placeholder="Entreprise" id="entreprise" onChange={handleEntreprise} />

                      <button className="btn-msg" id="btn_msg">ENVOYER LE MESSAGE</button>
                    </div>
                  </div>
                </form>
                {popup == true && (
                  <div className='popup-succes'>
                    <div className='popup-succes-container'>
                      <svg className='popup-succes-icon' style={{ marginTop: '3rem' }}>
                        <use xlinkHref='assets/icons.svg#icon-verified_user' />
                      </svg>
                      <div className='popup-succes-text' style={{ marginTop: '12rem' }}>
                        demande envoyé
                      </div>

                      <div className='popup-succes-text2'></div>
                    </div>
                  </div>
                )}
                {/* <button className="btn-msg" id="btn_msg">ENVOYER LE MESSAGE</button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="part-2">
          <h4>
            Vous souhaitez vous procurer une imprimante à bas prix?
          </h4>
          <p>
            Alfa Print vous propose l'offre contrat regroupant:<br />
            imprimante + consommables + SAV inclus à 29,90€ HT/mois
          </p>
        </div>
        <div className="part-3">
          <h4>
            Photocopieurs professionnels conçus pour les pros
          </h4>
        </div>
        <div className="part-items">
          <div className="row-items">
            <div className="col item">
              <img src={p1} alt="printer image" />
              <p style={{
                fontSize: "10px",
                letterSpacing: "2px",
                backgroundColor: "#e8eaf0",
                width: "fit-content",
                paddingLeft: "5px",
                paddingRight: "5px",
                borderRadius: "2px",
                // marginBottom: "-5px"
              }}>
                PHOTOCOPIEUR A4 NB
              </p>
              <p style={{
                fontSize: "20px",
                marginBottom: "20%"
              }}>
                Photocopieur A4
              </p>
              <p><a href="imprimantes/40860/HP-OfficeJet-Pro-7740-WF/" target="_blank">Découvrir&nbsp;&nbsp;→</a></p>
            </div>
            <div className="col item">
              <img src={p2} alt="printer image" />
              <p style={{
                fontSize: "10px",
                letterSpacing: "2px",
                backgroundColor: "#e8eaf0",
                width: "fit-content",
                paddingLeft: "5px",
                paddingRight: "5px",
                borderRadius: "2px",
                // marginBottom: "-5px"
              }}>
                PHOTOCOPIEUR A4 NB
              </p>
              <p style={{
                fontSize: "20px",
                marginBottom: "20%"
              }}>
                Photocopieur A4
              </p>
              <p><a href="produit-imprimantes/?id=37721" target="_blank">Découvrir&nbsp;&nbsp;→</a></p>
            </div>
            <div className="col item">
              <img src={p3} alt="printer image" />
              <p style={{
                fontSize: "10px",
                letterSpacing: "2px",
                backgroundColor: "#e8eaf0",
                width: "fit-content",
                paddingLeft: "5px",
                paddingRight: "5px",
                borderRadius: "2px",
                // marginBottom: "-5px"
              }}>
                PHOTOCOPIEUR A4 NB
              </p>
              <p style={{
                fontSize: "20px",
                marginBottom: "20%"
              }}>
                Photocopieur A4
              </p>
              <p><a href="produit-imprimantes/?id=41758" target="_blank">Découvrir&nbsp;&nbsp;→</a></p>
            </div>
            <div className="col item">
              <img src={p4} alt="printer image" />
              <p style={{
                fontSize: "10px",
                letterSpacing: "2px",
                backgroundColor: "#e8eaf0",
                width: "fit-content",
                paddingLeft: "5px",
                paddingRight: "5px",
                borderRadius: "2px",
                // marginBottom: "-5px"
              }}>
                PHOTOCOPIEUR A4 NB
              </p>
              <p style={{
                fontSize: "20px",
                marginBottom: "20%"
              }}>
                Photocopieur A4
              </p>
              <p><a href="imprimantes/41556/HP-PageWide-Pro-477-dw/" target="_blank">Découvrir&nbsp;&nbsp;→</a></p>
            </div>
            <div className="col item">
              <img src={p5} alt="printer image" />
              <p style={{
                fontSize: "10px",
                letterSpacing: "2px",
                backgroundColor: "#e8eaf0",
                width: "fit-content",
                paddingLeft: "5px",
                paddingRight: "5px",
                borderRadius: "2px",
                // marginBottom: "-5px"
              }}>
                PHOTOCOPIEUR A4 NB
              </p>
              <p style={{
                fontSize: "20px",
                marginBottom: "20%"
              }}>
                Photocopieur A4
              </p>
              <p><a href="imprimantes/38906/BROTHER-MFC-L-8690-CDW/" target="_blank">Découvrir&nbsp;&nbsp;→</a></p>
            </div>
            <div className="col item">
              <img src={p6} alt="printer image" />
              <p style={{
                fontSize: "10px",
                letterSpacing: "2px",
                backgroundColor: "#e8eaf0",
                width: "fit-content",
                paddingLeft: "5px",
                paddingRight: "5px",
                borderRadius: "2px",
                // marginBottom: "-5px"
              }}>
                PHOTOCOPIEUR A4 NB
              </p>
              <p style={{
                fontSize: "20px",
                marginBottom: "20%"
              }}>
                Photocopieur A4
              </p>
              <p><a href="imprimantes/40869/BROTHER-MFC-L-9570-CDW/" target="_blank">Découvrir&nbsp;&nbsp;→</a></p>
            </div>
            <div className="col item">
              <img src={p7} alt="printer image" />
              <p style={{
                fontSize: "10px",
                letterSpacing: "2px",
                backgroundColor: "#e8eaf0",
                width: "fit-content",
                paddingLeft: "5px",
                paddingRight: "5px",
                borderRadius: "2px",
                // marginBottom: "-5px"
              }}>
                PHOTOCOPIEUR A4 NB
              </p>
              <p style={{
                fontSize: "20px",
                marginBottom: "20%"
              }}>
                Photocopieur A4
              </p>
              <p><a href="imprimantes/41453/Brother-mfc-l6800dw/" target="_blank">Découvrir&nbsp;&nbsp;→</a></p>
            </div>
            <div className="col item">
              <img src={p8} alt="printer image" />
              <p style={{
                fontSize: "10px",
                letterSpacing: "2px",
                backgroundColor: "#e8eaf0",
                width: "fit-content",
                paddingLeft: "5px",
                paddingRight: "5px",
                borderRadius: "2px",
                // marginBottom: "-5px"
              }}>
                PHOTOCOPIEUR A4 NB
              </p>
              <p style={{
                fontSize: "20px",
                marginBottom: "20%"
              }}>
                Photocopieur A4
              </p>
              <p><a href="imprimantes/37439/Lexmark-XM7155/" target="_blank">Découvrir&nbsp;&nbsp;→</a></p>
            </div>
          </div>
        </div>
        <div className="part-4">
          <div className="list-items">
            <div className="item" style={{ backgroundColor: "#f9f8ff" }}>
              <h4>
                Satisfaction Client
              </h4>
              <p>
                Répondre à la demande et offrir les meilleurs résultats à nos clients sont notre priorité. Toute l'équipe Alfa Print est à votre disposition pour vous accompagner dans l'achat de vos imprimantes, photocopieurs et de vos consommables.
              </p>
            </div>
            <div className="item" style={{ backgroundColor: "#fffcf6" }}>
              <h4>
                Expertise
              </h4>
              <p>
                Nos experts de l'impression et de la copie sont à votre entière disposition. Alfa Print accompagne toutes les entreprises professionnelles dans la réalisation de vos documents d'impression.
              </p>
            </div>
            <div className="item" style={{ backgroundColor: "#f0fffc" }}>
              <h4>
                Contrat de maintenance
              </h4>
              <p>
                Service technique à votre disposition à distance ou dans vos locaux. Nos techniciens interviennent dans vos locaux dans un délai de 24h.
              </p>
            </div>
            <div className="item" style={{ backgroundColor: "#faefff" }}>
              <h4>
                Installation à distance
              </h4>
              <p>
                Notre équipe est disponible de 9h à 17h30 pour vous accompagner à distance dans l'installation de votre photocopieur ou votre imprimante.
              </p>
            </div>
          </div>
        </div>
        <div className="part-5">
          <h4>
            Les avantages de souscrire au contrat d'Alfa Print
          </h4>
          <ul className="ul">
            <li>Un tarif avantageux</li>
            <li>Une équipe d'experts à votre disposition pour vous aider à définir vos besoins et vous proposer un produit adapté à votre demande</li>
            <li>Une photocopieuse et des consommables à moindre coût</li>
            <li>Un SAV à votre écoute pour résoudre vos problèmes</li>
          </ul>
        </div>
        <div className="part-6">
          <div className="line">
            <div className="part-accordion">
              <div className="bg-image">
                {/* <img style={{ width: "100%" }} src={bg_part6} allt="background image" /> */}
              </div>
              <div className="accordion-block">
                <h4>
                  Des solutions d'impression au cours de votre business
                </h4>
                <div>
                  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='accordion-title'>Réduire au maximum vos dépenses</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className='accordion-body'>
                        Chez Alfa Print, nous mettons tout à vos dispositions pour vous proposer des photocopieurs performants à moindre coût selon vos besoins.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='accordion-title'>Rapidité d'installation</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className='accordion-body'>
                      Chez Alfa Print, nous mettons  tout en œuvre pour vous accompagner dans l’installation de vos machines et consommables.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='accordion-title'>Suivi personnalisé</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className='accordion-body'>
                      Chez Alfa Print, nous sommes à votre entière disposition pour répondre à toutes vos interrogations et vous proposer un équipement adapté à vos besoins.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className="part-image">
              <div className="bg-image">
                {/* <img style={{ width: "100%" }} src={bg_part6} allt="background image" /> */}
              </div>
              <img className='accordion-image' src={pr} alt="printer image" />
            </div>
          </div>
        </div>
        <div className="part-7">
          <img src={banner} alt="banner imprimante bizhub" />
        </div>
        <div className="part-8">
          <img src={testimonials} alt="image testimonials" />
        </div>
        <div className="part-9">
          <h4>
            Nos partenaires
          </h4>
          <img src={logos} alt="list partenaires" />
        </div>
        <div className="part-10">
          <h4>
            Ils nous font confiance
          </h4>
          <img src={list_confiance} alt="nos clients" />
        </div>
        <div className="part-11">
          <div className="part-contact">
            <div className="contact" style={{ marginLeft: "10%", marginRight: "15%" }}>
              <h4>
                Contactez-nous
              </h4>
              <div>
                <p>
                  Merci de remplir le formulaire ci-dessous, afin que nous répondions au mieux à votre demande. Les champs marqués d'un * sont obligatoires.
                </p>
              </div>
              <div>
                <p style={{ fontWeight: "bold", marginBottom: "-10px" }}>Téléphone:</p>
                <p>(+33)9 82 54 78 30</p>
              </div>
              <div>
                <p style={{ fontWeight: "bold", marginBottom: "-10px" }}>Envoyez un e-mail:</p>
                <p>contact@alfaprint.fr</p>
              </div>
              <div>
                <p style={{ fontWeight: "bold", marginBottom: "-10px" }}>Adresse:</p>
                <p>4120 ROUTE DE TOURNAI, 59500 DOUAI - FRANCE</p>
              </div>
            </div>
            <div className="col">
              <div className="background-image">
                <img style={{ width: "100%", paddingTop: "3%", borderRadius: "5px", borderRadius: "20px" }} src={bg_form} allt="background image" />
              </div>
              <div style={{ padding: "0 8%" }}>
                <h4>
                  Devis de location de photocopieurs
                </h4>
                <p>
                Pour obtenir un devis adapté à vos besoins, laissez vos coordonnées et expliquez-nous brièvement ce que vous recherchez. Notre équipe prendra ensuite contact avec vous au plus vite!
                </p>
                <form onSubmit={handlesubmit2} id='myform2'>
                  <div className="form2">
                    <div className="form-line">
                      <input type="text" placeholder="Votre Nom*" id="f2_nom" onChange={handleNomPrenom2} required />
                      <input type="email" placeholder="E-mail" id="f2_email" onChange={handleEmail2} />
                    </div>
                    <div className="form-line">
                      <input type="number" placeholder="Téléphone*" id="f2_tel" onChange={handleTel2} required />
                      <input type="text" placeholder="Site Internet*" id="f2_site" onChange={handleTel} />
                    </div>
                    <div className="form-line2">
                      <textarea placeholder="Message" id="f2_msg" onChange={handleMessage} />
                    </div>
                    <div className="form-line2">
                      <button className="btn-msg" id="btn_msg">ENVOYER LE MESSAGE</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="part-12">
          <h2>Alfa Print: Les meilleurs prix sur vos imprimantes & scanners</h2>
          <p className="pComp">
            <b>Alfa Print</b> est l'un des plus grands constructeurs de matériel d'impression. Nous proposons une
            grande gamme de matériel comme des <a href="imprimante-multifonction/"><b>imprimantes multifonctions</b>
            </a>, <b>scanners</b>, <b>photocopieuses</b> le tout avec des <a href="consommable/"><b>consommables</b>
            </a> d'impressions, <a href="consommable/cartouche/"><b>cartouches d'encre</b></a>, <a
              href="consommable/toner/"><b>toners laser</b></a>. Tous nos produits sont à des prix défiants toute
            concurrence en <b>France</b> et de qualité.
          </p>
          <p className="pComp">
            Nous vous accompagnons en mettant à votre disposition une large gamme sur les <b>imprimantes</b>
            et les <b>scanners</b> en marques <a href="marques/imprimante-brother/"><b>Brother</b></a>,
            <a href="marques/imprimante-hp/"><b>HP</b></a>, <a href="marques/imprimante-epson/"><b>Epson</b></a>,
            <a href="marques/imprimante-lexmark/"><b>Lexmark</b></a>, <a href="marques/imprimante-canon/"><b>
              Canon</b></a> et <a href="marques/imprimante-xerox/"><b>Xerox</b></a> pour répondre à votre demande.
          </p>
          <p className="pComp">&nbsp;</p>
          <p className="pComp">
            <b>Alfa print</b> œuvre dans l'innovation, en vous proposant des solutions inédites qui vont révolutionner
            le monde de la distribution. Les solutions mises à votre disposition <b>sont les contrats et abonnements
              sur les imprimantes et consommables encres et toners.</b>
          </p>
          <p className="pComp">
            Pour approvisionner votre bureau en imprimantes, photocopieurs ou scanners, choisissez <b>Alfa Print</b> qui est le
            meilleur dans ce domaine. Notre équipe est constamment disponible pour vous proposer la meilleure offre
            qui va répondre à vos besoins. Nous vous assurons qu'en prenant contact avec nos experts, vous pouvez
            être sûr de prendre seulement le matériel dont vous avez besoin. Nous pouvons vous accompagner sur la
            construction de vos projets en <b>A4</b> ou en <b>A3</b>, <b>monochrome</b> ou <b>couleur</b>,
            <b>imprimante monofonction</b> ou <b>photocopieur multifonction</b> avec des accessoires en plus,
            <b>meuble support</b> ou <b>module de finition avec agrafages et pour finir des bacs à papiers</b>.
          </p>
        </div>
      </div>
    </div>
  );
}
export default LandingPage;
