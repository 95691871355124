import './modal.css';
import { useHistory } from 'react-router-dom';

function Modal2() {
  const history = useHistory();

  function handleClick() {
    history.push('/cart');
  }
  return (
    <div className='modal-dialog' style={{transform:'translateY(-60%)',top:'40%'}}> 
      <div className='modal-content' >
        <div className='modal-body add-cart-box text-center'>
          <p>
            Le produit est ajouté à votre panier <br />
          </p>
          <svg
            className='icon '
            style={{ fill: 'green', width: '4rem', height: '4rem' }}
          >
            <use xlinkHref='assets/icons.svg#icon-verified_user' />
          </svg>
          <div className='btn-actions'>
            <button
              onClick={handleClick}
              className='btn-primary'
              data-dismiss='modal'
            >
              Voir panier
            </button>
            <a href='#'>
              <button className='btn-primary' data-dismiss='modal'>
                Continuer
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Modal2;
