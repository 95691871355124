function Features() {
  return (
    <div className='features-section bg-gray'>
      <div className='container'>
        <h2 className='h2Comp'>
          Notre approche de la satisfaction des clients
        </h2>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='feature-box bg-white'>
              <i className='icon-shipped' />
              <div className='feature-box-content'>
                <img
                  className='icon-down'
                  src='assets/icons/truck2.svg'
                  style={{
                    width: '4rem',
                    height: '4rem',
                    marginRight: '1rem',
                  }}
                  alt='Service client disponible 5/7 jours'
                />
                <h3>Livraison ultra-rapide</h3>
                <p className='pComp'>
                  Notre service de messagerie express garantit que votre
                  commande arrive dans les plus brefs délais.
                </p>
              </div>
              {/* End .feature-box-content */}
            </div>
            {/* End .feature-box */}
          </div>
          {/* End .col-lg-4 */}
          <div className='col-lg-4'>
            <div className='feature-box bg-white'>
              <i className='icon-us-dollar' />
              <div className='feature-box-content'>
                <img
                  className='icon-down'
                  src='assets/icons/money-bag2.svg'
                  style={{
                    width: '4rem',
                    height: '4rem',
                    marginRight: '1rem',
                  }}
                  alt='Meilleur prix'
                />
                <h3>Prix le plus bas garanti</h3>
                <p className='pComp'>
                  Nous vérifions tous les jours nos concurrents pour vous
                  assurer les meilleures offres.
                </p>
              </div>
              {/* End .feature-box-content */}
            </div>
            {/* End .feature-box */}
          </div>
          {/* End .col-lg-4 */}
          <div className='col-lg-4'>
            <div className='feature-box bg-white'>
              <i className='icon-online-support' />
              <div className='feature-box-content'>
                <img
                  className='icon-down'
                  src='assets/icons/support_agent2.svg'
                  style={{
                    width: '4rem',
                    height: '4rem',
                    marginRight: '1rem',
                  }}
                  alt='Avec Alfa Print Satisfait ou rembourser'
                />
                <h3>Plus de 40 experts </h3>
                <p className='pComp'>
                  Alfa Print est en relation avec un bon nombre de techniciens
                  qualifiés et agréés pour vous offrir un service de qualité.
                </p>
              </div>
              {/* End .feature-box-content */}
            </div>
            {/* End .feature-box */}
          </div>
          {/* End .col-lg-4 */}
        </div>
        {/* End .row */}
      </div>
      {/* End .container */}
    </div>
  );
}
export default Features;
