import "./searchBar.css";

import { Link, useHistory } from "react-router-dom";
import React from "react";
import { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { fontSize, fontWeight } from "@mui/system";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { fabClasses } from "@mui/material";
function useOutsideAlerter(ref) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        ref.current.style.display = "none";
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function SearchBox(props) {
  const history = useHistory();
  const [style, setStyle] = useState({ display: "none" });
  const [input, setInput] = useState("");

  const [focus, setFocus] = useState(false);

  const [select, setSelect] = useState(0);

  const [loading, setLoading] = useState(false);

  const [vide, setvide] = useState(false);
  const [found, setFound] = useState([]);
 
  const liste_json = props.liste_json;  
  const [prixsupplies, setPrixSupplies] = useState(0);
  const [shortliste, setshortlist] = useState([]);
  const [shortlisteImprimante, setshortlistImprimante] = useState([]);
  const [shortlisteSupplies, setshortlistSupplies] = useState([]);
  const [route, setroute] = useState("");
  const [prix, setprix] = useState();

  const [result, setResult] = useState([]);

  const refDel = React.createRef();
  const refInput = React.createRef();
  const ref = useRef();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleInput = (e) => {
    setInput(e.target.value);

    if (e.target.value === "") {
      setFocus(false);
    } else {
      setFocus(true);
      setIsMenuOpen(true)
    }
  };
  const handleselect = (e) => {
    setSelect(e.target.value);

    if (e.target.value == 1) {
      setroute("/imprimante-multifonction");
    } else if (e.target.value == 2) {
      setroute("/imprimante-laser");
    } else if (e.target.value == 3) {
      setroute("/imprimante-jet-d-encre");
    } else if (e.target.value == 4) {
      setroute("/autres-materiels/imprimante-etiquette");
    } else if (e.target.value == 5) {
      setroute("/traceur");
    } else if (e.target.value == 6) {
      setroute("/consommable");
    } else if (e.target.value == 0) {
      setroute("");
    }
  };
  const handleScroll = (e) => {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      // setInput('');

      setFocus(false);
    }
  };

  const submit = () => {
    if (route != "") history.push(route);
  };
  const delInput = () => {
    setInput("");
    refInput.current.value = "";
    setIsMenuOpen(false)
  };
  const Footer = [
    {
      name: "EPSON",
      img: "assets/images/brands/epson.webp",
    },
    {
      name: "BROTHER",
      img: "assets/images/brands/brother.webp",
    },
    {
      name: "DELL",
      img: "assets/images/brands/dell.webp",
    },
    {
      name: "CANON",
      img: "assets/images/brands/canon.webp",
    },
    {
      name: "HP",
      img: "assets/images/brands/hp.webp",
    },
    {
      name: "KYOCERA",
      img: "assets/images/brands/kyocera.webp",
    },
    {
      name:"SAMSUNG",
      img:"assets/images/brands/samsung.webp"
    },
    {
      name:"LEXMARK",
      img:"assets/images/brands/lexmark.webp"
    },

    
  ];
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

	
	 
 
	var liste_sp = []; 
	
	useEffect(() => {
    if (input != "") {
      refDel.current.style.display = "block";
    } else {
      refDel.current.style.display = "none";
    }

	
	  var sh = ""; 
      var liste_result = []; 
      var liste_result_sp_fin = []; 
      var liste_result_pr_fin = []; 
      var liste_res = []; 
      var liste_pr = []; 
      
	  
      if (input.length > 1 && liste_json.length > 0) { 
      var  tex=input.split(" "); 
      var  chaine='';
      for (let i = 0; i < tex.length; i++) 
      { 
		sh = tex[i]; 
        // sub=sh.substring(0,2);        
        // sh = sh.replace(sub,' '+sub+' ');  
        sh=sh+' ';
        sh = sh.replace('TN','TN ');
        sh = sh.replace('tn','tn ');
        sh = sh.replace('dr','dr ');
        sh = sh.replace('DR','DR '); 
        sh = sh.replace('CF','CF ');
        sh = sh.replace('cf','cf ');
        sh = sh.replace('tb','tb ');
        sh = sh.replace('cl','cl ');
        sh = sh.replace('fc','fc ');
        sh = sh.replace('lc','lc ');
        sh = sh.replace('tk','tk ');
        sh = sh.replace('dv','dv ');
        sh = sh.replace('hg','hg ');
        sh = sh.replace('wx','wx ');
        sh = sh.replace('bci','bci ');
        sh = sh.replace('mk','mk ');
        sh = sh.replace('no','no ');
        sh = sh.replace('tze','tze ');
        sh = sh.replace('clt','clt ');
        sh = sh.replace('fx','fx ');
        sh = sh.replace('ep','ep ');
        sh = sh.replace('tx','tx ');
        sh = sh.replace('cz','cz ');
        sh = sh.replace('mlt','mlt ');
        sh = sh.replace('mc','mc ');
        sh = sh.replace('tnp','tnp '); 
        sh = sh.replace('type','type ');
        sh = sh.replace('pfi','pfi '); 
        sh = sh.replace('npg','npg ');
        chaine=chaine+sh;
      }   
      tex=chaine.split(" ");  
      setvide(true); 
      let index2 = 0;
      var count=tex.length;
      var sub=''; 

      const liste_name = [];
      const liste_names = [];
      const liste_names2 = [];
      for (let index = 0; index < liste_json.length; index++) 
	  {
        let inc=0; 
        let inc2=0;  
        for (let i = 0; i < tex.length; i++) 
		{  
          sh =tex[i].toUpperCase();   
          if (liste_json[index].name.toUpperCase().includes(sh))
		  inc++;  
          if (liste_json[index].names.toUpperCase().includes(sh))
		  inc2++;   
        }
        if(count==inc){ 
          liste_name.push(liste_json[index]);
        } 
        if(count==inc2){ 
          liste_names.push(liste_json[index]);
        }   
      }

		const liste_name_p1=[];
		const liste_name_p2=[];
		let found_supply=0;
		let found_printer=0;
		let p=0;
		
		for (let index = 0; index < liste_name.length; index++) 
		{     
			let text=liste_name[index].name.toUpperCase(); 
			// console.log(text,'textT');
			if(liste_name[index].names2!="" && p==0)
			{
			  liste_names2.push(liste_name[index].names2);
			  p++;
			}
			if(text.indexOf(input.toUpperCase())>=0) 
			{
			  liste_name_p1.push(liste_name[index]); 
			} 
			else
			liste_name_p2.push(liste_name[index]); 
		} 
		
	 
		let liste_res=[]; 
		//resulat = recherche par name 
		liste_result = liste_name_p1.concat(liste_name_p2);
		//resulat = recherche par names( en complement) 
		liste_result = liste_result.concat(liste_names);

		if(liste_names2.length>0)
		{
			let names2=liste_names2[0].split(","); 
			console.log(liste_name,'liste_names222');  
			for (let m = 0; m < liste_json.length; m++) 
			{  
				if(liste_res.length>10)
				break;
				for (let n = 0; n < names2.length; n++) 
				{ 
					if(liste_json[m].product_id==names2[n] ){
					liste_res.push(liste_json[m]);
					}     
				} 
			} 
	    }
 
		var liste_result_sp=liste_result.filter((data) => data.sp === "Supply");
		var liste_result_pr=liste_result.filter((data) => data.sp === "Printer");
		
		liste_sp=liste_res.filter((data) => data.sp === "Supply");
		liste_pr=liste_res.filter((data) => data.sp === "Printer");
		
		 
		  
	    // if(liste_sp.length>0 && liste_result_sp.length==0)


      
	    var liste_result_sp_fin=liste_result_sp.concat(liste_sp);
	    var liste_result_pr_fin=liste_result_pr.concat(liste_pr);
		 
		 
		 
		console.log(liste_name_p1,'liste_name_p1');
		console.log(liste_name_p2,'liste_name_p2');
		
		console.log(liste_pr,'liste_pr');
		console.log(liste_result_pr,'liste_result_pr');
		console.log(liste_result_sp,'liste_result_sp');
		
        if (liste_result.length > 0) 
        setLoading(false); 
        setFocus(true);
	  }
	  
	
 
  
	setshortlist(liste_result_sp_fin);
    setshortlistImprimante(liste_result_pr_fin); 
   
    return () => {};
  }, [input]);
 
  useEffect(() => {
    // setListe(props.list);
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [props.list]);





 
  const [newResult,setNewResult]=useState([])
    
  // console.log(shortliste,"shortliste")
  useEffect(() => {
    const inputChange=input && input.substring(0,2).toUpperCase();
        setFound(Footer.filter(element => {
   
      if (element.name.substring(0,2)  == inputChange) {
        setNewResult(element)
    
      }
    }))
  }, [input]);  


  useEffect(() => {  
    //consommabel en 1er
	if( document.getElementById('choix_A') != null && liste_sp.length==0)
	{  
		document.getElementById('choix_A').style.display='inline';   
		document.getElementById('choix_B').style.display='none';   
		document.getElementById('choix_C').style.display='inline';   
		document.getElementById('choix_D').style.display='none';   
	}
    //consommabel en 2éme
	else if( document.getElementById('choix_A') != null && liste_sp.length>0)
	{ 
		document.getElementById('choix_A').style.display='none';   
		document.getElementById('choix_B').style.display='inline';   
		document.getElementById('choix_C').style.display='none';   
		document.getElementById('choix_D').style.display='inline';  
	}
	// document.getElementById('choix_1').innerHTML ='<div >BBB</div>';
  }, [input]);  





 
function VoirPlus(){
  history.push('/search')
}





  if (props.device == "web") {
    return (
      <div
        className={
          "header-icon header-search header-search-inline header-search-category w-lg-max pl-3 " +
          props.class
        }
      >
        <a href="#" className="search-toggle" role="button">
          <i className="icon-search-3" />
        </a>

        <div className="header-search-wrapper" ref={ref}>
          <input
            className="form-control"
            ref={refInput}
            placeholder={liste_json.length < 1 ? "Loading" : "Chercher..."}
            disabled={liste_json.length < 1}
            required
            style={{
              background: liste_json.length < 1 ? "rgb(179 178 178 / 37%)" : "",
              fontWeight:
                liste_json.length < 1
                  ? { fontWeight: "normal" }
                  : { fontWeight: "bold" },
            }}
            onChange={handleInput}
            onFocus={() => {
              if (input.length > 1) {
                setFocus(true);
              }
            }}
            onClick={() => setIsMenuOpen(true)}
          />
          <button
            className="btn icon-search-3"
            ref={refDel}
            style={{
              display: "none",
              justifyContent: "center",
              borderLeft: "0px",
              alignItems: "center",
              borderRadius: "0",
            }}
            onClick={delInput}
          >
            <svg
              className="icon-down"
              style={{
                fill: "#777",

                width: "20px",

                height: "20px",
              }}
            >
              <use xlinkHref="assets/icons.svg#icon-cross" />
            </svg>
          </button>
          {/* End .select-custom */}{" "}
          <button
            className="btn icon-search-3"
            style={{
              display: "flex",

              justifyContent: "center",

              alignItems: "center",
            }}
            onClick={submit}
          >
            <svg
              className="icon-down"
              style={{
                fill: "#777",
              }}
            >
              <use xlinkHref="assets/icons.svg#icon-search" />
            </svg>
          </button>

          
          {focus && isMenuOpen && (

 
            <div className="choix">
				<div id="choix_A">
				  <div > 
					<h6 style={{ marginBottom: "0rem", marginTop: "" }}>
					Choisissez vos consommables : 
					</h6>
				  {shortliste.length > 0 &&
					shortliste.slice(0,10).map((data, index) => {
					  return (
						<Box
						  md={{
							flexGrow: 1,
							overflow: "hidden",
							maxHeight: " 58px;",
							display: shortliste.length > 0 ? "block" : "none",
						  }}
						>
						  <Paper
							sx={{ maxWidth: 600, marginLeft: "13px", elevation: 1 }}
							style={{ marginTop: "14px", boxShadow: "none" }}
						  >
							{data.sp !== "Printer" && (
							  <Grid
								container
								spacing={2}
								key={shortliste}
								style={
								  index % 2
									? { background: "#f4f4f4" }
									: { background: "white" }
								}
							
							  >
								<Grid item style={{padding: "8px"}}>
								  <Link
									to={
									  data.url_fiche.includes("produit")
										? `/${data.url_fiche}`
										: `/${data.url_fiche}/`
									}
									onClick={() => {
									  setFocus(false);
									}}
								  >
									{data.sp !== "Printer" &&
									  data.sp !== "Printer" && (
										<img
										  className="image-recherche"
										  alt="pas d'image de produit "
										  src={`https://alfaprint.fr/assets/img/supplies_image/${data.model_id}.webp `}
										  onError={(e) => {
											e.target.onerror = null;
											e.target.src = "assets/img/impr.webp";
									   
										  }}
										/>
									  )}
								  </Link>
								</Grid>

								<Grid item xs style={{padding: "8px", display:"table"}}>
								  <Link
                  style={{
                    display: "table-cell",
                    verticalAlign: "middle"
                  }}
									to={
									  data.url_fiche.includes("produit")
										? `/${data.url_fiche}`
										: `/${data.url_fiche}/`
									}
									onClick={() => {
									  setFocus(false);
									}}
								  >
									<div>
									  <span
										style={{
										  fontSize: "12px",
										  color: "black",
										  cursor: "pointer",
										}}
									  >
										{" "}
										{data.name}
									  </span>
									</div>

							   
								  </Link>
								</Grid>
						  
							  </Grid>
							)}
						  </Paper>
						</Box>
					  );
					}) }
	  
				  </div>
				</div>
			 
				<div id="choix_C">
				  <div style={{ width: "auto", height: "auto" }}>
					<h6 style={{ marginBottom: "0rem", marginTop: "" }}>
					Choisissez votre imprimante :
					</h6> 
					<Grid
						container
						spacing={1}
						style={{ marginTop: "10px", marginLeft: "0rem" }}
						> 
						{shortlisteImprimante.length > 0 &&
						shortlisteImprimante
						  .slice(0, 10) 
						  .map((data, index) => {
							return (
							  <Grid xs={3} style={{ marginLeft: "0px",paddingRight:"2px",paddingLeft:"2px",height:"auto" }} >
								<Link
								  to={
									data.url_fiche.includes("produit")
									  ? `/${data.url_fiche}`
									  : `/${data.url_fiche}/`
								  }
								  onClick={() => {
									setFocus(false);
								  }}
								  alt={data.name}
								  title={data.name}
								>
								  {" "}
								  
									<img
									  className="image-recherche"
									  src={`https://alfaprint.fr/assets/img/printers_image/${data.model_id}.webp `}
									  onError={(e) => {
										e.target.onerror = null;
										e.target.src =
										  "https://alfaprint.fr/assets/img/impr.webp";
									  }}
									/>
								 
								</Link>

								 
								  <AddBoxIcon
									style={{
									  color: "#fd5b14",
									  position: "absolute",
									  marginLeft: "-15px",
									  fontSize: "Large",
									  cursor: "pointer",
									}}
								  />
								 
								<br></br>
								<Link
								  to={
									data.url_fiche.includes("produit")
									  ? `/${data.url_fiche}`
									  : `/${data.url_fiche}/`
								  }
								  onClick={() => {
									setFocus(false);
								  }}
								  alt={data.name}
								  title={data.name}
								>
								   
									<span
									  style={{ fontSize: "10px", color: "black" }}
									>
									  { data.name}{" "}
									
									
									</span>
								   
								</Link>
							  </Grid>
							);
						  })}
					</Grid>
				  </div>
				</div>
			
				<div id="choix_D">
				  <div style={{ width: "auto", height: "auto" }}>
					<h6 style={{ marginBottom: "0rem", marginTop: "" }}>
					Choisissez votre imprimante :
					</h6> 
					<Grid
						container
						spacing={1}
						style={{ marginTop: "10px", marginLeft: "0rem" }}
						> 
						{shortlisteImprimante.length > 0 &&
						shortlisteImprimante
						  .slice(0, 10) 
						  .map((data, index) => {
							return (
							  <Grid xs={3} style={{ marginLeft: "0px",paddingRight:"2px",paddingLeft:"2px",height:"150px" }} >
								<Link
								  to={
									data.url_fiche.includes("produit")
									  ? `/${data.url_fiche}`
									  : `/${data.url_fiche}/`
								  }
								  onClick={() => {
									setFocus(false);
								  }}
								  alt={data.name}
								  title={data.name}
								>
								  {" "}
								  
									<img
									  className="image-recherche"
									  src={`https://alfaprint.fr/assets/img/printers_image/${data.model_id}.webp `}
									  onError={(e) => {
										e.target.onerror = null;
										e.target.src =
										  "https://alfaprint.fr/assets/img/impr.webp";
									  }}
									/>
								 
								</Link>

								 
								  <AddBoxIcon
									style={{
									  color: "#fd5b14",
									  position: "absolute",
									  marginLeft: "-15px",
									  fontSize: "Large",
									  cursor: "pointer",
									}}
								  />
								 
								<br></br>
								<Link
								  to={
									data.url_fiche.includes("produit")
									  ? `/${data.url_fiche}`
									  : `/${data.url_fiche}/`
								  }
								  onClick={() => {
									setFocus(false);
								  }}
								  alt={data.name}
								  title={data.name}
								>
								   
									<span
									  style={{ fontSize: "10px", color: "black" }}
									>
									  { data.name}{" "}
									
									
									</span>
								   
								</Link>
							  </Grid>
							);
						  })}
					</Grid>
				  </div>
				</div>
				
				<div id="choix_B">
				  <div > 
					<h6 style={{ marginBottom: "0rem", marginTop: "" }}>
					Choisissez vos consommables : 
					</h6>
				  {shortliste.length > 0 &&
					shortliste.slice(0,10).map((data, index) => {
					  return (
						<Box
						  md={{
							flexGrow: 1,
							overflow: "hidden",
							maxHeight: " 58px;",
							display: shortliste.length > 0 ? "block" : "none",
						  }}
						>
						  <Paper
							sx={{ maxWidth: 600, marginLeft: "13px", elevation: 1 }}
							style={{ marginTop: "14px", boxShadow: "none" }}
						  >
							{data.sp !== "Printer" && (
							  <Grid
								container
								spacing={2}
								key={shortliste}
								style={
								  index % 2
									? { background: "#f4f4f4" }
									: { background: "white" }
								}
							
							  >
								<Grid item>
								  <Link
									to={
									  data.url_fiche.includes("produit")
										? `/${data.url_fiche}`
										: `/${data.url_fiche}/`
									}
									onClick={() => {
									  setFocus(false);
									}}
								  >
									{data.sp !== "Printer" &&
									  data.sp !== "Printer" && (
										<img
										  className="image-recherche"
										  alt="pas d'image de produit "
										  src={`https://alfaprint.fr/assets/img/supplies_image/${data.model_id}.webp `}
										  onError={(e) => {
											e.target.onerror = null;
											e.target.src = "assets/img/impr.webp";
									   
										  }}
										/>
									  )}
								  </Link>
								</Grid>

								<Grid item xs>
								  <Link
									to={
									  data.url_fiche.includes("produit")
										? `/${data.url_fiche}`
										: `/${data.url_fiche}/`
									}
									onClick={() => {
									  setFocus(false);
									}}
								  >
									<div>
									  <span
										style={{
										  fontSize: "12px",
										  color: "black",
										  cursor: "pointer",
										}}
									  >
										{" "}
										{data.name}
									  </span>
									</div>

							   
								  </Link>
								</Grid>
						  
							  </Grid>
							)}
						  </Paper>
						</Box>
					  );
					}) }
	  
				  </div>
				</div>
				
			  
            </div>



          )}

        </div>

        {/* End .header-search-wrapper */}
      </div>
    );
  } else {
    return (
      <div
        className={
          "header-icon header-search header-search-inline header-search-category w-lg-max pl-3 " +
          props.class
        }
      >
        <a href="#" className="search-toggle" role="button">
          <i className="icon-search-3" />
        </a>

        <div className="header-search-wrapper mobile-wrap">
          <input
      
            ref={refInput}
            value={input}
            placeholder={liste_json.length < 1 ? "Loading" : "Chercher..."}
            disabled={liste_json.length < 1}
            required
            style={{
              background: liste_json.length < 1 ? "rgb(179 178 178 / 37%)" : "",
              fontWeight: liste_json.length < 1 ? "bold" : "normal",
              width: "10rem", padding: "1rem 2rem"
            }}
            onChange={handleInput}
            onFocus={() => {
              if (input.length > 1) {
                setFocus(true);
              }
            }}
          />
          s
          <button
            className="btn icon-search-3"
            ref={refDel}
            style={{
              display: "none",

              justifyContent: "center",
              borderLeft: "0px",
              alignItems: "center",
              borderRadius: "0",
            }}
            onClick={delInput}
          >
            <svg
              className="icon-down"
              style={{
                fill: "#777",

                width: "20px",

                height: "20px",
              }}
            >
              <use xlinkHref="assets/icons.svg#icon-cross" />
            </svg>
          </button>
          <div className="select-custom display-flex">
            <select id="cat" name="cat" onChange={handleselect}>
              <option value={0}>Tous</option>

              <option value={1}>Imprimante Multifonction</option>

              <option value={2}>Imprimante Laser</option>

              <option value={3}>Imprimante Jet d'encre</option>

              <option value={4}>Etiquetteuse</option>

              <option value={5}>Traceurs</option>

              <option value={6}>Consommables</option>
            </select>

            <svg
              className="icon-down"
              style={{
                fill: "#777",

                position: "absolute",

                right: "20%",
              }}
            >
              <use xlinkHref="assets/icons.svg#icon-cheveron-down" />
            </svg>
          </div>
          {/* End .select-custom */}{" "}
          <button
            className="btn icon-search-3"
            style={{
              display: "flex",

              justifyContent: "center",

              alignItems: "center",
            }}
            onClick={submit}
          >
            <svg
              className="icon-down"
              style={{
                fill: "#777",

                width: "20px",

                height: "20px",
              }}
            >
              <use xlinkHref="assets/icons.svg#icon-search" />
            </svg>
          </button>
          {focus && (
            <div className="choix choix-mobile">
              {shortliste.length > 0 &&
                shortliste.map((data, index) => {
                  return (
                    <div key={index}>
                      <Link
                        className="recherche_res"
                        to={
                          data.url_fiche.includes("produit")
                            ? `/${data.url_fiche}`
                            : `/${data.url_fiche}/`
                        }
                        onClick={() => {
                          setFocus(false);
                        }}
                        alt={data.name}
                        title={data.name}
                      >
                        {data.sp != "Supply" && (
                          <img
                            src={`/assets/img/printer_image/${data.modelid}.webp`}
                          />
                        )}
                        {data.sp == "Supply" && (
                          <img
                            src={`/assets/img/supplies_image/${data.model_id}.webp `}
                          />
                        )}
                        {data.name}
                      </Link>
                    </div>
                  );
                })}{" "}
              {loading && (
                <div className="bounce-loader">
                  <div className="bounce1"></div>

                  <div className="bounce2"></div>

                  <div className="bounce3"></div>
                </div>
              )}
              {!loading && vide && liste_json.length == 0 && "aucun résultat trouvé"}
            </div>
          )}
        </div>

        {/* End .header-search-wrapper */}
      </div>
    );
  }
}


export default SearchBox;
