import { useEffect, useState } from 'react';
import ReactStars from 'react-stars';
import './avis.css';
import Pagination from 'react-js-pagination';

function Avis(props) {
  const [listavis, setlistavis] = useState([]);
  const [moyenne, setmoyenne] = useState(0);
  const [nombreavis, setnombre] = useState(0);
  const [activepage, setactivepage] = useState(1);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(7);
  useEffect(() => {
    const form = new FormData();
    form.append('id_produit', props.id);
    fetch('https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/getreview', {
      method: 'POST',
      body: form,
    }).then((res) =>
      res.json().then((data) => {
        if (data.length > 0) {
          setlistavis(data);
          let nombre = 0;
          for (let index = 0; index < data.length; index++) {
            nombre = nombre + parseFloat(data[index].nbr_star);
          }
          nombre = (nombre / data.length).toFixed(1);
  
          setmoyenne(parseFloat(nombre));
          props.sendmoyene(nombre, data);
          
          setnombre(data.length);
        }
      })
    );
  }, [props.id]);
  const handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    setactivepage(pageNumber);
    let val = pageNumber * 7;
    setMax(val);
    setMin(val - 7);
  };

  if (listavis.length > 0) {
    return (
      <div className='display-flex allavis'>
        <div className='display-flex big-avis'>
          <p>Note moyenne</p>
          <p className='big-number'>
            {moyenne}
            <span>/5</span>{' '}
          </p>
          <ReactStars
            value={parseFloat(moyenne)}
            edit={false}
            size={24}
            color2={'#ffd700'}
          />
          <div className='avis-nbr display-flex'>
         
            {nombreavis} au total
          </div>
        </div>
        <div className='liste-avis display-flex'>
          {listavis.map((data, index) => {
            if (index >= min && index < max)
              return (
                <div className='display-flex avis-item' key={data.nom}>
                  <p>
                    {data.nom}
                    {data.date_review && (
                      <span className='date-avis'>({data.date_review})</span>
                    )}
                  </p>
                  <ReactStars
                    value={parseFloat(data.nbr_star)}
                    edit={false}
                    size={20}
                    color2={'#ffd700'}
                  />
                </div>
              );
          })}
        </div>
        {nombreavis > 7 && (
          <Pagination
            activePage={activepage}
            itemsCountPerPage={7}
            totalItemsCount={nombreavis}
            pageRangeDisplayed={5}
            onChange={(event) => {
              handlePageChange(event);
            }}
          />
        )}
      </div>
    );
  } else {
    return (
      <div>
        {' '}
        <p className='reviews-title'>Il n'y a aucun avis pour l'instant. </p>
      </div>
    );
  }

  //   Il n'y a aucun avis pour l'instant.
}
export default Avis;
