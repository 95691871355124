import './flashvente.css';
import OwlCarousel from 'react-owl-carousel';
import Timer from '../timer';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ThingsContext from '../../context';
import { useContext } from 'react';
function Flashvente() {
  const context = useContext(ThingsContext);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isscrolled, setisscrolled] = useState(false);
  const [prix6300, setprix6300] = useState(false);
  const [prix6400, setprix6400] = useState(false);
  const [prix5750, setprix5750] = useState(false);
  const [prix477, setprix477] = useState(false);
  const [prix7740, setprix7740] = useState(false);
  const [prix5100, setprix5100] = useState(false);
  const [prix9570, setprix9570] = useState(false);
  function isScrolledIntoView(el) {
    let element = document.getElementById(el);
    var rect = element.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    // Only completely visible elements return true:
    // var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:
    var isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }
  useEffect(() => {
    // POST request using fetch inside useEffect React hook
    if (isscrolled) {
      setIsLoaded(true);
    }
    var formdata = new FormData();
    formdata.append('produit_id', 40860);
    fetch(
      'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/get/price',
      { method: 'POST', body: formdata }
    ).then((response) =>
      response.json().then((response) => {
        setprix7740(response);
      })
    );

    var formdata = new FormData();
    formdata.append('produit_id', 40869);
    fetch(
      'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/get/price',
      { method: 'POST', body: formdata }
    ).then((response) =>
      response.json().then((response) => {
        setprix9570(response);
      })
    );


    var formdata = new FormData();
    formdata.append('produit_id', 40639);
    fetch(
      'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/get/price',
      { method: 'POST', body: formdata }
    ).then((response) =>
      response.json().then((response) => {
        setprix6400(response);
      })
    );

    var formdata = new FormData();
    formdata.append('produit_id', 41452);
    fetch(
      'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/get/price',
      { method: 'POST', body: formdata }
    ).then((response) =>
      response.json().then((response) => {
        setprix5750(response);
      })
    );


    var formdata = new FormData();
    formdata.append('produit_id', 40625);
    fetch(
      'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/get/price',
      { method: 'POST', body: formdata }
    ).then((response) =>
      response.json().then((response) => {
        setprix5100(response);
      })
    );


    var formdata = new FormData();
    formdata.append('produit_id', 40637);
    fetch(
      'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/get/price',
      { method: 'POST', body: formdata }
    ).then((response) =>
      response.json().then((response) => {
        setprix6300(response);
      })
    );


    var formdata = new FormData();
    formdata.append('produit_id', 41556);
    fetch(
      'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/get/price',
      { method: 'POST', body: formdata }
    ).then((response) =>
      response.json().then((response) => {
        setprix477(response);
      })
    );


    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [isscrolled]);
  useEffect(() => {
    window.addEventListener('scroll', checkscroll);
    return () => {
      window.removeEventListener('scroll', checkscroll);
    };
  }, []);
  const checkscroll = () => {
    if (isLoaded == false) {
      const bool = isScrolledIntoView('flashvente');

      if (bool == true) {
        setisscrolled(bool);
        window.removeEventListener('scroll', checkscroll);
      }
    } else {
      window.removeEventListener('scroll', checkscroll);
    }
  };
  console.log(prix6300,"price")
  if (isLoaded == true) {
    return (
      <div className='container-fluid' id='flashvente'>
        <div className='flashvente'>
          <OwlCarousel
            autoplay
            loop
            smartSpeed={2888}
            dots={false}
            autoplayTimeout={7000}
            items={1}
            lazyLoad
            autoplayHoverPause={true}
            className='testimonials-carousel owl-carousel owl-theme images-left'
          >
          {prix477 > 0 && (
            <div className='flashvente-item'>
              <div className='flashvente-text col-lg-4 col-smd-12'>
                <span>OFFRE POUR LES PROS!</span>
                <br /> Dans la limite des stocks disponibles
                <Link to='/imprimantes/41556/HP-PageWide-Pro-477-dw/'>
                  {' '}
                  <button
                    className='item-text-button hoverbutton '
                    style={{
                      borderWidth: '3px',
                      padding: '1rem 5rem',
                      backgroundColor: '#fffcfc26',
                      fontWeight: '500',
                      textTransform: 'none',
                      fontSize: '1.6rem',
                      borderColor: '#03a9f4',
                      color: '#03a9f4',
                      marginTop: '2rem',
                    }}
                  >
                    En profiter
                  </button>
                </Link>
              </div>
              <div className='flasvente-img col-lg-4 col-md-12'>
                <img
                  className='owl-lazy'
                  data-src={'assets/imagev2/promo.' + context}
                  alt={`Offre alfa print pour  HP PageWide Pro 477dw`}
                />
              </div>
              <div className='flashvente-detail col-lg-4 col-md-12'>
                imprimante HP PageWide Pro 477dw <br />
                <span>{(prix477 * 1.2).toFixed(2)} €</span> <br />
                <div className='flashvente-nombre'>
                  <p>Valable:24</p> <span>Vendu:30</span>
                </div>
                <div className='blue-div'></div> <br />
                <div className='flashvente-nombre'>
                  Dépéchez-vous! l'offre se termine dans:
                  <br />
                </div>
                <Timer />
              </div>
            </div>
            )}
            {prix7740 > 0 && (
            <div className='flashvente-item'>
              <div className='flashvente-text col-lg-4 col-smd-12'>
                <span>OFFRE POUR LES PROS!</span>
                <br /> Dans la limite des stocks disponibles .
                <Link to='/imprimantes/40860/HP-OfficeJet-Pro-7740-WF/'>
                  <button
                    className='item-text-button hoverbutton '
                    style={{
                      borderWidth: '3px',
                      padding: '1rem 5rem',
                      backgroundColor: '#fffcfc26',
                      fontWeight: '500',
                      textTransform: 'none',
                      fontSize: '1.6rem',
                      borderColor: '#03a9f4',
                      color: '#03a9f4',
                      marginTop: '2rem',
                    }}
                  >
                    En profiter
                  </button>
                </Link>
              </div>
              <div className='flasvente-img col-lg-4 col-md-12'>
                <img
                  className='owl-lazy'
                  data-src={'assets/imagev2/promo 5.' + context}
                  alt={`Vente flash pour l'imprimante HP OFFICEJET PRO 7740 `}
                />
              </div>
              <div className='flashvente-detail col-lg-4 col-md-12'>
                HP OFFICEJET PRO 7740 Imprimante Multifonction <br />
                <span>{(prix7740 * 1.2).toFixed(2)} €</span> <br />
                <div className='flashvente-nombre'>
                  <p>Valable:4</p> <span>Vendu:8</span>
                </div>
                <div className='blue-div'></div> <br />
                <div className='flashvente-nombre'>
                  Dépéchez-vous! l'offre se termine dans:
                  <br />
                </div>
                <Timer />
              </div>
            </div>
          )}
          {prix5100 > 0 && (
            <div className='flashvente-item'>
              <div className='flashvente-text col-lg-4 col-smd-12'>
                <span>OFFRE POUR LES PROS!</span>
                <br /> Dans la limite des stocks disponibles .
                <Link to='/imprimantes/40625/BROTHER-HL-L-5100-DN/'>
                  <button
                    className='item-text-button hoverbutton '
                    style={{
                      borderWidth: '3px',
                      padding: '1rem 5rem',
                      backgroundColor: '#fffcfc26',
                      fontWeight: '500',
                      textTransform: 'none',
                      fontSize: '1.6rem',
                      borderColor: '#03a9f4',
                      color: '#03a9f4',
                      marginTop: '2rem',
                    }}
                  >
                    En profiter
                  </button>
                </Link>
              </div>
              <div className='flasvente-img col-lg-4 col-md-12'>
                <img
                  className='owl-lazy'
                  data-src={'assets/imagev2/promo 2.' + context}
                  alt={`Offre pour les pros pour l'imprimante  Brother HL-L5100DN`}
                />
              </div>
              <div className='flashvente-detail col-lg-4 col-md-12'>
                Brother HL-L5100DN imprimante laser monochrome A4
                <br />
                <span>{(prix5100 * 1.2).toFixed(2)} €</span> <br />
                <div className='flashvente-nombre'>
                  <p>Valable:39</p> <span>Vendu:50</span>
                </div>
                <div className='blue-div'></div> <br />
                <div className='flashvente-nombre'>
                  Dépéchez-vous! l'offre se termine dans:
                  <br />
                </div>
                <Timer />
              </div>
            </div>
          )}
          {prix9570 > 0 && (
            <div className='flashvente-item'>
              <div className='flashvente-text col-lg-4 col-smd-12'>
                <span>OFFRE POUR LES PROS!</span>
                <br /> Dans la limite des stocks disponibles .
                <Link to='/imprimantes/40869/BROTHER-MFC-L-9570-CDW/'>
                  <button
                    className='item-text-button hoverbutton '
                    style={{
                      borderWidth: '3px',
                      padding: '1rem 5rem',
                      backgroundColor: '#fffcfc26',
                      fontWeight: '500',
                      textTransform: 'none',
                      fontSize: '1.6rem',
                      borderColor: '#03a9f4',
                      color: '#03a9f4',
                      marginTop: '2rem',
                    }}
                  >
                    En profiter
                  </button>
                </Link>
              </div>
              <div className='flasvente-img col-lg-4 col-md-12'>
                <img
                  className='owl-lazy'
                  data-src={'assets/imagev2/Promo 3.' + context}
                  alt={`Notre promotion de  Brother MFC-L9570CDW`}
                />
              </div>
              <div className='flashvente-detail col-lg-4 col-md-12'>
                Brother MFC-L9570CDW imprimante laser multifonction couleur{' '}
                <br />
                <span>{(prix9570 * 1.2).toFixed(2)} €</span> <br />
                <div className='flashvente-nombre'>
                  <p>Valable:14</p> <span>Vendu:20</span>
                </div>
                <div className='blue-div'></div> <br />
                <div className='flashvente-nombre'>
                  Dépéchez-vous! l'offre se termine dans:
                  <br />
                </div>
                <Timer />
              </div>
            </div>
          )}
          {prix6300 > 0 && (
            <div className='flashvente-item'>
              <div className='flashvente-text col-lg-4 col-smd-12'>
                <span>OFFRE POUR LES PROS!</span>
                <br /> Dans la limite des stocks disponibles .
                <Link to='/imprimantes/40637/BROTHER-HL-L-6300-DW/'>
                  <button
                    className='item-text-button hoverbutton '
                    style={{
                      borderWidth: '3px',
                      padding: '1rem 5rem',
                      backgroundColor: '#fffcfc26',
                      fontWeight: '500',
                      textTransform: 'none',
                      fontSize: '1.6rem',
                      borderColor: '#03a9f4',
                      color: '#03a9f4',
                      marginTop: '2rem',
                    }}
                  >
                    En profiter
                  </button>
                </Link>
              </div>
              <div className='flasvente-img col-lg-4 col-md-12'>
                <img
                  className='owl-lazy'
                  data-src={'assets/imagev2/promo 4.' + context}
                  alt=' Brother HL-L6300DW0 en promotion '
                />
              </div>
              <div className='flashvente-detail col-lg-4 col-md-12'>
                Brother HL-L6300DW A4 imprimante laser monochrome
                <br />
                <span>{(prix6300 * 1.2).toFixed(2)} €</span> <br />
                <div className='flashvente-nombre'>
                  <p>Valable:4</p> <span>Vendu:10</span>
                </div>
                <div className='blue-div'></div> <br />
                <div className='flashvente-nombre'>
                  Dépéchez-vous! l'offre se termine dans:
                  <br />
                </div>
                <Timer />
              </div>
            </div>
          )}
          {prix6400 > 0 && (
            <div className='flashvente-item'>
              <div className='flashvente-text col-lg-4 col-smd-12'>
                <span>OFFRE POUR LES PROS!</span>
                <br /> Dans la limite des stocks disponibles .
                <Link to='/imprimantes/40639/BROTHER-HL-L-6400-DW/'>
                  <button
                    className='item-text-button hoverbutton '
                    style={{
                      borderWidth: '3px',
                      padding: '1rem 5rem',
                      backgroundColor: '#fffcfc26',
                      fontWeight: '500',
                      textTransform: 'none',
                      fontSize: '1.6rem',
                      borderColor: '#03a9f4',
                      color: '#03a9f4',
                      marginTop: '2rem',
                    }}
                  >
                    En profiter
                  </button>
                </Link>
              </div>
              <div className='flasvente-img col-lg-4 col-md-12'>
                <img
                  className='owl-lazy'
                  data-src={'assets/imagev2/promo1.' + context}
                  alt='Brother HL-L6400DW conçue pour les pros '
                />
              </div>
              <div className='flashvente-detail col-lg-4 col-md-12'>
                Brother HL-L6400DW imprimante laser monochrome A4
                <br />
                <span>{(prix6400 * 1.2).toFixed(2)} €</span> <br />
                <div className='flashvente-nombre'>
                  <p>Valable:20</p> <span>Vendu:40</span>
                </div>
                <div className='blue-div'></div> <br />
                <div className='flashvente-nombre'>
                  Dépéchez-vous! l'offre se termine dans:
                  <br />
                </div>
                <Timer />
              </div>
            </div>
          )}
          {prix5750 > 0 && (
            <div className='flashvente-item'>
              <div className='flashvente-text col-lg-4 col-smd-12'>
                <span>OFFRE POUR LES PROS!</span>
                <br />
                Dans la limite des stocks disponibles .
                <Link to='/imprimantes/41452/BROTHER-MFC-L-5750-DW/'>
                  <button
                    className='item-text-button hoverbutton '
                    style={{
                      borderWidth: '3px',
                      padding: '1rem 5rem',
                      backgroundColor: '#fffcfc26',
                      fontWeight: '500',
                      textTransform: 'none',
                      fontSize: '1.6rem',
                      borderColor: '#03a9f4',
                      color: '#03a9f4',
                      marginTop: '2rem',
                    }}
                  >
                    En profiter
                  </button>
                </Link>
              </div>
              <div className='flasvente-img col-lg-4 col-md-12'>
                <img
                  className='owl-lazy'
                  data-src={'assets/imagev2/promo 6.' + context}
                  alt={`Offre d'Alfa Print pour  Brother MFC-L5750DW`}
                />
              </div>
              <div className='flashvente-detail col-lg-4 col-md-12'>
                Brother MFC-L5750DW imprimante multifonction monochrome A4{' '}
                <br />
                <span>{(prix5750 * 1.2).toFixed(2)} €</span> <br />
                <div className='flashvente-nombre'>
                  <p>Valable:34</p> <span>Vendu:56</span>
                </div>
                <div className='blue-div'></div> <br />
                <div className='flashvente-nombre'>
                  Dépéchez-vous! l'offre se termine dans:
                  <br />
                </div>
                <Timer />
              </div>
            </div>
)}
          </OwlCarousel>
        </div>
      </div>
    );
  } else {
    return (
      <div className='container-fluid' id='flashvente'>
        <div className='flashvente'></div>
      </div>
    );
  }
}
export default Flashvente;
