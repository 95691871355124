import "./modal.css";
import { useHistory } from "react-router-dom";
import { useState, useEffect, useMemo, useCallback } from "react";
import Fichiercarousel from "../fichiercarousel2";
import { hydrate, render } from "react-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import ThingsContext from "../../context";
import { useContext } from "react";
function Modal(props) {
  const history = useHistory();
  const context = useContext(ThingsContext);
  const [title, settitle] = useState(props.title);
  const [prix, setprix] = useState(props.prix);
  const [img, setimg] = useState(props.img);
  const typeclient = {
    client: {
      adresse: "",
      company_id: "",
      cp: "",
      dataConnexion: "",
      email: "",
      fonction: "",
      gender: "",
      id: "",
      login: "",
      nom: "",
      prenom: "",
    },
    token: "",
  };
  const [id, setid] = useState(props.id);
  const [vp, setvp] = useState(props.vp);
  const [isloaded, setisloaded] = useState(false);

  const [ext, setExt] = useState("webp");
  const [client, setclient] = useState(typeclient);
  const [pannier, setPannier] = useState([]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("copylist_Products"));

    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );
    if (isSafari) {
      setExt("png");
    }

    if (data) {
      setPannier(data);
    }
    setTimeout(() => {
      const clienttest = localStorage.getItem("client");
      if (clienttest) {
        setclient(JSON.parse(clienttest));
      }
      if (window.innerWidth > 700) {
        if (!sessionStorage.fontsLoaded) {
          require(["webfontloader"], function (WebFont) {
            WebFont.load({
              google: {
                families: ["Montserrat:500,700&display=swap", "sans-serif"],
              },
            });
            document.querySelector("body").style.fontFamily = "Montserrat";
          });
        }
      }
    }, 1500);
    if (navigator.userAgent != "ReactSnap") {
      setTimeout(() => {
        const script = document.createElement("script");

        script.src = "/assets/js/chat.js";
        script.async = true;
        document.body.appendChild(script);
      }, 6000);
    }
    // eslint-disable-next-line no-use-before-define
  }, [props]);

  const ajouterpannier = (data) => {
    props.ajouteraupannier(data);
  };
  const delete_From_Pannier = (id) => {
    let i;
    setPannier((prevState) => {
      if (prevState) {
        const list_Produit = [...prevState];
        list_Produit.map((item, index) => {
          if (item.product_id == id) {
            i = index;
          }
          return item;
        });

        list_Produit.splice(i, 1);

        setPannier(list_Produit);
        localStorage.setItem("copylist_Products", JSON.stringify(list_Produit));
      }
    });
  };
  const [qt, setqt] = useState(1);
  const [count, setCount] = useState();

  function handleClick() {
    history.push("/cart/");
  }
  function handleClickPaiment() {
    console.log(client.token, "TOKEN");
    if (client.token === "") {
      history.push("/connecter");
    } else history.push("/checkout");
  }
  function handleClickContinuez() {
    history.push("/");
  }

  // const Child1 = useMemo(
  //   () => (

  //   ),

  //   [props.vp,props.id,id]
  // );

  const type = (url) => {
    return url.slice(0, url.indexOf("/"));
  };

  const getprice = () => {
    if (pannier && pannier.length > 0) {
      return (
        pannier
          .map((data) => {
            return data.prix * data.index;
          })
          .reduce((reduce, curent) => {
            return reduce + curent;
          }) * 1.2
      ).toFixed(2);
    }
  };
  const [hovering, setHovering] = useState(false);

  const handleMouseOver = useCallback(() => {
    setHovering(true);
  }, []);
  const handleMouseOut = useCallback(() => {
    setHovering(false);
  }, []);
  const changeprixtotal = (prix, num) => {
    const total = prix * num;
    return total;
  };

  const modifier_Pannier = (product, index) => {
    if (!product.index) {
      if (index) {
        product.index = index;
      } else {
        product.index = 1;
      }
    }
    if (type(product.url_fiche) == "imprimantes") {
      product.vp = "pr";
    } else if (type(product.url_fiche) == "consommables") {
      product.vp = "cn";
    }
    setPannier((prevState) => {
      let ajout = true;
      if (prevState) {
        const copylist_Products = [...prevState];
        copylist_Products.map((item) => {
          if (item.product_id == product.product_id) {
            if (index) {
              item.index = item.index + index;
            } else {
              item.index++;
            }
            ajout = false;
          }
          return item;
        });
        if (ajout) {
          //if ajout=true=> nouveau produit dans la list donc ajouter le produit dans la liste
          copylist_Products.push(product);
          setPannier(copylist_Products);
          localStorage.setItem(
            "copylist_Products",
            JSON.stringify(copylist_Products)
          );
        } else {
          setPannier(copylist_Products);
          localStorage.setItem(
            "copylist_Products",
            JSON.stringify(copylist_Products)
          );
        }
      } else {
        // ajout=false=>incrementer le nombre de cette produit dans la liste
        if (localStorage.getItem("copylist_Products")) {
          const copylist_Products = JSON.parse(
            localStorage.getItem("copylist_Products")
          );
          copylist_Products.push(product);
          localStorage.setItem(
            "copylist_Products",
            JSON.stringify(copylist_Products)
          );
        } else {
          const copylist_Products = [];
          setPannier(copylist_Products);
          localStorage.setItem(
            "copylist_Products",
            JSON.stringify(copylist_Products)
          );
        }
      }
    });
  };

  const modifier_Pannier2 = (product, index) => {
    if (!product.index) {
      if (index) {
        product.index = index;
      } else {
        product.index = 1;
      }
    }
    if (type(product.url_fiche) == "imprimantes") {
      product.vp = "pr";
    } else if (type(product.url_fiche) == "consommables") {
      product.vp = "cn";
    }
    setPannier((prevState) => {
      let ajout = true;
      if (prevState) {
        const copylist_Products = [...prevState];
        copylist_Products.map((item) => {
          if (item.product_id == product.product_id) {
            if (index) {
              item.index = item.index - index;
            } else {
              item.index--;
            }
            ajout = false;
          }
          return item;
        });
        if (ajout) {
          //if ajout=true=> nouveau produit dans la list donc ajouter le produit dans la liste
          copylist_Products.push(product);
          setPannier(copylist_Products);
          localStorage.setItem(
            "copylist_Products",
            JSON.stringify(copylist_Products)
          );
        } else {
          setPannier(copylist_Products);
          localStorage.setItem(
            "copylist_Products",
            JSON.stringify(copylist_Products)
          );
        }
      } else {
        // ajout=false=>incrementer le nombre de cette produit dans la liste
        if (localStorage.getItem("copylist_Products")) {
          const copylist_Products = JSON.parse(
            localStorage.getItem("copylist_Products")
          );
          copylist_Products.push(product);
          localStorage.setItem(
            "copylist_Products",
            JSON.stringify(copylist_Products)
          );
        } else {
          const copylist_Products = [];
          setPannier(copylist_Products);
          localStorage.setItem(
            "copylist_Products",
            JSON.stringify(copylist_Products)
          );
        }
      }
    });
  };

  const somme = () => {
    var totalS = 0;
    if (pannier && pannier.length > 0) {
      let totalsomm = pannier.map((data) => {
        totalS = totalS + parseFloat(calculPrice(data.prix * data.index));
      });

      return calculPrice(totalS);
    }
  };

  function calculPrice(prix) {
    if (typeof prix != "undefined") {
      console.log(prix)
      if (pannier && pannier.length > 0) {
        // var totalP = "" + prix + "";
        // var totalPSplit = totalP.split(".");

        // if (totalPSplit.length > 0 && typeof totalPSplit[1] != "undefined")
        //   return totalPSplit[0] + "." + totalPSplit[1].substr(0, 2);
        // else if (typeof totalPSplit[0] != "undefined")
        //   return totalPSplit[0].substr(0, 3);
        // else return 0;
        return parseFloat(prix).toFixed(2);
      }
    }
  }
  function links(data){
    if (data.url.includes("?")){
      const tempurl = props.data.url_fiche.split('?');
      return ("/" + tempurl[0] + "/?" + tempurl[1]);
    } else {
      return ("/"+data.url+"/");
    }
  }
  return (
    <div className="modal-dialog custom-dialog">
      <div
        className="modal-content"
        style={{ padding: "2rem", overflow: "auto" }}
      >
        <div class="col-md-2 ml-auto" style={{ display: "flex" }}>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <CloseIcon fontSize="large" />
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4">
              <h4 style={{ backgroundColor: "#f4f4f4" }} class="text-center">
                Panier Rapide
              </h4>
            </div>
            <div class="col-md-7">
              <h4
                style={{ backgroundColor: "#f4f4f4", width: "112%" }}
                class="text-center"
              >
                Votre Commande
              </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p class="text-center">
                {pannier && pannier.length} article dans votre panier
              </p>
              <hr></hr>
              <h5>
                TOTAL TTC
                {pannier && pannier.length > 0 ? (
                  <span style={{ marginLeft: "140px" }}>
                    {" "}
                    {calculPrice(calculPrice(somme()) * 1.2) + "€ "}{" "}
                  </span>
                ) : (
                  <span style={{ marginLeft: "140px" }}> {0 + "€"} </span>
                )}
              </h5>
              <Button
                style={{
                  color: "black",
                  border: "1px solid black",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <span
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  CONTIUNEZ VOS ACHATS
                </span>
              </Button>
              <Button
                style={{
                  color: "black",
                  border: "1px solid black",
                  width: "100%",
                  marginBottom: "10px",
                }}
                onClick={handleClick}
              >
                <span
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  VOIR LE PANIER
                </span>
              </Button>
              <Button
                size="large"
                style={{ width: "100%", backgroundColor: "rgb(45, 93, 142)" }}
                onClick={handleClickPaiment}
              >
                <span
                  style={{
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  PASSER A LA CAISSE
                </span>
              </Button>
            </div>

            <div class="col">
              <div className="col-lg-12">
                <div className="cart-table-container">
                  <table className="table table-cart2">
                    <tbody>
                      {pannier &&
                        pannier.length > 0 &&
                        pannier.map((data) => {
                          return (
                            <tr className="product-row" key={data.product_id}>
                              <td className="product-col">
                                <span style={{ display: "none" }}>
                                  {" "}
                                  {(data.prixtotal = data.prix)}{" "}
                                </span>
                                <figure
                                  className="product-image-container"
                                  style={{
                                    maxWidth: "150px",
                                    minWidth: "130px",
                                  }}
                                >
                                  <Link
                                    to={links(data)}
                                    className="product-image"
                                  >
                                    {type(data.url_fiche) == "imprimantes" && (
                                      <img
                                        src={`assets/img/printers_image/${data.model_id}.${context} `}
                                        alt="product"
                                        style={{
                                          width: "60px",
                                          height: "60px",
                                        }}
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src =
                                            "assets/img/impr." + context;
                                        }}
                                      />
                                    )}
                                    {type(data.url_fiche) == "consommables" && (
                                      <img
                                        src={`assets/img/supplies_image/${data.model_id}.${context} `}
                                        alt="product"
                                        style={{
                                          width: "60px",
                                          height: "60px",
                                        }}
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src =
                                            "assets/img/impr." + context;
                                        }}
                                      />
                                    )}
                                  </Link>
                                </figure>
                              </td>
                              <td style={{ borderTop: "none" }}>
                                {" "}
                                {type(data.url_fiche) == "consommables"
                                  ? data.brand +
                                    " " +
                                    data.oem +
                                    " " +
                                    data.color
                                  : data.brand + " " + data.model}
                              </td>

                              <td>
                                <Box>
                                  <ButtonGroup
                                    variant="black"
                                    aria-label="outlined button group"
                                  >
                                    <Button>
                                      <RemoveIcon
                                        onClick={() => {
                                          if (data.index > 1)
                                            modifier_Pannier2(data, qt);
                                        }}
                                      />
                                    </Button>
                                    <Button> {data.index}</Button>

                                    <Button>
                                      {" "}
                                      <AddIcon
                                        onClick={() =>
                                          modifier_Pannier(data, qt)
                                        }
                                      />
                                    </Button>
                                  </ButtonGroup>
                                </Box>
                              </td>

                              <td id={data.product_id + "prix-total"}>
                                {calculPrice(data.prix * 1.2 * data.index)}€ TTC
                              </td>
                              <td>
                                <CloseIcon
                                  fontSize="large"
                                  onClick={() =>
                                    props.deletepannier(data.product_id)
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}

                      <tr className="product-action-row">
                        <td colSpan={4} className="clearfix">
                          {/* End .float-left */}
                          <div className="float-right">
                            <a
                              href="#"
                              title="Edit product"
                              className="btn-edit"
                            >
                              <span className="sr-only">Edit</span>
                              <i className="icon-pencil" />
                            </a>
                            <a
                              href="#"
                              title="Remove product"
                              className="btn-remove"
                            >
                              <span className="sr-only">Remove</span>
                            </a>
                          </div>
                          {/* End .float-right */}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 ml-auto">
              <hr></hr>
            </div>
            <div class="row">
              <div class="col-md-2 ml-auto">
                <p
                  style={{
                    marginTop: "2rem",
                    fontSize: "2rem",
                    fontWeight: "600",
                    marginLeft: "250px",
                  }}
                >
                  Accessoires recommandés
                </p>
              </div>
            </div>
          </div>
          <div id={type} key={id}>
            {" "}
            {
              <Fichiercarousel
                ajouteraupannier={ajouterpannier}
                vp={vp}
                id={id}
                type={props.type}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
export default Modal;
