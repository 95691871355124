import Modal2 from './modal/modal2new';
import { useEffect } from 'react';
import React, { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import ThingsContext from '../context';
import { useContext } from 'react';
function Fichiercarousel(props) {
  const context = useContext(ThingsContext);
  const [items, setItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [popup, setpopup] = useState(false);
  const [classhidden, sethidden] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    // POST request using fetch inside useEffect React hook
    const requestOptions2 = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ model_id: props.id, type: props.vp }),
    };
    fetch(
      'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/consommable-of-product-v2',
      requestOptions2
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.consommable) {
            data = Object.values(data.consommable);
            const liste = [...data];

            setItems(liste);

            setIsLoaded(true);
          }
        }
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [props.id]);
  const ajouterpanier = (data) => {

    props.ajouteraupannier(data);
  };
  function calculPrice(prix){
    if (typeof(prix) != "undefined"){
  
    // var totalP=''+prix+'';
    // var totalPSplit=totalP.split('.')
  
    // if(totalPSplit.length>0 && typeof(totalPSplit[1]) != "undefined")    
    // return totalPSplit[0]+'.'+totalPSplit[1].substr(0,2);
    // else if(typeof(totalPSplit[0]) != "undefined")
    // return totalPSplit[0];
    // else 
    // return 0;
    return parseFloat(prix).toFixed(2);
    }
  }
  const price = (prix) => {
    return ( prix * 0.15) + prix;
  };
  if (isLoaded) {
    return (
      <div className='container'>
        <h2 className='section-title'>{props.nom}</h2>
        <OwlCarousel
          className='products-slider owl-carousel owl-theme dots-top'
          loop
          margin={10}
          items={5}
          lazyLoad
          responsive={{
            0: { items: 2 },
            700: { items: 3, margin: 15 },
            1200: { items: 3, margin: 15 },
          }}
        >
          {' '}
          {items.map((data) => {
            return (
              <div
                className='product-default inner-quickview inner-icon'
                key={data.product_id}
              >
                <figure>
                  <Link to={`/${data.url_fiche}/`} className='image-product'>
                    <img
                      className='product-image owl-lazy'
                      height='220'
                      style={{
                        height: '120px',
                        width: 'auto',
                        padding: '10px',
                        margin: 'auto',
                      }}
                      alt={data.brand}
                      data-src={`/assets/img/supplies_image/${data.model_id}.${context}`}
                    />
                  </Link>
                  <div className='label-group'>
                    {/* <div className='product-label label-hot'>HOT</div>
                    <div className='product-label label-sale'>20% Off</div> */}
                  </div>
                  <div className='btn-icon-group'></div>
                </figure>
                <div
                  className='product-details'
                  style={{ alignItems: 'center' }}
                >
                  {data.prix ? 
                  <div className='price-box'>
                    {/* <span className='old-price'>{price(data.prix)}</span> */}
                    <span
                      className='product-price'
                      style={{
                        color: '#353333',
                        marginTop: '-1rem',
                        width: 'max-content',
                        fontSize: '2.8rem',
                      }}
                    >
                      
                      { calculPrice(data.prix*1.2)} €{' '}
                      <span style={{ fontSize: '1.2rem', color: '#777' }}>
                        TTC
                      </span>
                    </span>
                  </div>
                      :  <div
                      className='product-details'
                      style={{ alignItems: 'center' }}
                    > </div>}
                  <h3
                    className='product-title'
                    style={{ marginTop: '-1.5rem' }}
                  >
                    <Link to={`/${data.url_fiche}/`}>
                    {data.name}
                    </Link>
                  </h3>

                  {/* End .product-ratings */}
                  {/* End .product-container */}
{data.prix ?  <button
                    className={'btn-primary btn btn-modal'}
                    alt='ajouter au panier'
                    onClick={() => {
                      ajouterpanier(data);
                      setpopup(true);
                      setTimeout(() => {
                        sethidden(true);
                        setTimeout(() => {
                          setpopup(false);
                          sethidden(false);
                        }, 800);
                      }, 1100);
                    }}
                  >
                    Ajouter
                  </button>:<span style={{color:"red",fontSize:"16px",textDecoration:"solid line-through black  2px"}}>Hors stock</span>
                
                  }
                  {/* End .price-box */}
                </div>
                {/* End .product-details */}
              </div>
            );
          })}
        </OwlCarousel>

        <div className='modal' id={props.type + 'ff'}>
          <Modal2 />
        </div>
        {popup == true && (
          <div className='popup-succes'>
            <div
              className={
                classhidden
                  ? 'hidden popup-succes-container'
                  : 'popup-succes-container'
              }
            >
              <svg className='popup-succes-icon' style={{ marginTop: '3rem' }}>
                <use xlinkHref='assets/icons.svg#icon-verified_user' />
              </svg>
              <div className='popup-succes-text' style={{ marginTop: '10rem' }}>
                Le produit est ajouté à votre panier
              </div>

              <div className='popup-succes-text2'></div>
            </div>
          </div>
        )}
      </div>
    );
  } else
    return (
      <div>
        <div className='container'>
          <div className='flex-container'>
            <div className='item'>
              <div className='skelt'>
                <div className='white'></div>
              </div>
            </div>
            <div className='item'>
              <div className='skelt'>
                <div className='white'></div>
              </div>
            </div>
            <div className='item'>
              <div className='skelt'>
                <div className='white'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default Fichiercarousel;
