import Actualite from '../actualite/actualite';
import Evaluation from '../evaluation/evaluation';
import './actualiteevaluation.css';
import { useEffect, useState } from 'react';
function ActuEval() {
  const [isscrolled, setisscrolled] = useState(false);
  const [isloaded, setisloaded] = useState(false);
  function isScrolledIntoView(el) {
    let element = document.getElementById(el);
    var rect = element.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    // Only completely visible elements return true:
    // var isVisible = elemTop < window.innerHeight && elemBottom >= 0
    // Partially visible elements return true:
    var isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }
  useEffect(() => {
    window.addEventListener('scroll', checkscroll);

    return () => {
      window.removeEventListener('scroll', checkscroll);
    };
  }, []);
  const checkscroll = () => {
    if (isloaded == false) {
      const bool = isScrolledIntoView('actualevel');

      if (bool == true) {
        setisscrolled(bool);
        window.removeEventListener('scroll', checkscroll);
      }
    } else {
      window.removeEventListener('scroll', checkscroll);
    }
  };
  useEffect(() => {
    if (isscrolled) {
      setisloaded(true);
    }
  }, [isscrolled]);
  if (isloaded == true) {
    return (
      <div
        className='container-fluid actueval display-flex'
        style={{
          alignItems: 'normal',
          flexWrap: 'wrap',
        }}
        id='actualevel'
      >
        <div className='acteval-1'>
          {/* End .testimonials-slider */}
          <Actualite />
        </div>
        <div className='acteval-2'>
          <Evaluation />
        </div>
      </div>
    );
  } else
    return (
      <div
        className='container-fluid actueval display-flex'
        id='actualevel'
      ></div>
    );
}
export default ActuEval;
