import { Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import Chercher from "./chercher/chercher";
import Cherchermobile from "./chercher/chercher2";
import ThingsContext from "../context";
import jsonFile_sp from "../assets/response.json";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import SearchBox from "./chercher/search/searchBar";
// import SearchBox from "./chercher/search/searchBar";
import Icon3 from "../assets/Icone-3.webp";
import Icon4 from "../assets/Icone-4.webp";
import Icon1 from "../assets/Icone-1.webp";
import Icon2 from "../assets/icone-2.webp";
import Icon5 from "../assets/icone-5.webp";
import Icon6 from "../assets/Icone-6.webp";

function Navbar(props) {
  const history = useHistory();
  const [width, setwidth] = useState(window.innerWidth);
  const [showchercher, setchercher] = useState(false);

  const [liste, setListe] = useState();
  const [listeproduits_sp, setListeproduits_sp] = useState([]);
  const [listeproduits_pr, setListeproduits_pr] = useState([]);
  const [refreshInterval, setRefreshInterval] = useState(1 || 0);
  const variable = useContext(ThingsContext);

  const [client, setclient] = useState();
  const [connecte, setConnecte] = useState(false);
const fetchCartData = () => {
  const data = JSON.parse(localStorage.getItem("copylist_Products"));
    setListe(data);
}
  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(fetchCartData, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);
  const getRandom = () => {
    return Math.floor(Math.random() * 111124963);
  };
  useEffect(() => {
    window.onscroll = () => {
      if (
        document.body.scrollTop > 300 ||
        document.documentElement.scrollTop > 300
      ) {
        let element = document.getElementById("sticky");
        if (element) {
          element.classList.add("fixed");
        }
      } else {
        let element = document.getElementById("sticky");
        if (element) {
          element.classList.remove("fixed");
        }
      }
    };
    setTimeout(() => {
      handleload();
    }, 3000);
    document.getElementById("switch").addEventListener("click", () => {
      setchercher(false);
    });
  }, []);

  const handleload = () => {
    setListeproduits_sp(jsonFile_sp);
  };
  
  const priceDiscount = (prix) => {
    return (prix - prix * 0.1).toFixed(2);
  };

  const getprice = () => {
    if (liste.length > 0) {
      let totalsomm = liste
        .map((data) => {
          return data.prix * data.index;
        })
        .reduce((reduce, curent) => {
          return reduce + curent;
        })
        .toFixed(2);

      return (priceDiscount(totalsomm) * 1.2).toFixed(2);
    }
  };
  const somme = () => {
    var totalS = 0;
    if (liste.length > 0) {
      let totalsomm = liste.map((data) => {
        totalS = totalS + parseFloat(calculPrice(data.prix * data.index));
      });

      return calculPrice(totalS);
    }
  };

  function calculPrice(prix) {
    if (typeof prix != "undefined") {
      if (props.pannier.length > 0) {
        // var totalP=''+prix+'';
        // var totalPSplit=totalP.split('.')

        // if(totalPSplit.length>0 && typeof(totalPSplit[1]) != "undefined")
        // return totalPSplit[0]+'.'+totalPSplit[1].substr(0,2);
        // else if(typeof(totalPSplit[0]) != "undefined")
        // return totalPSplit[0].substr(0,3);
        // else
        // return 0;
        return parseFloat(prix).toFixed(2);
      }
    }
  }
  const send_Results = (data) => {
    props.send_Results(data);
  };
  useEffect(() => {
    setclient(props.client);
    if (props.client.client != undefined) {
      if (props.client.client.nom != "") {
        setConnecte(true);
      }
    }
  }, [props.client]);
  const deconnecter = () => {
    setConnecte(false);
    props.deconnecter();
    history.push("/");
  };

  const type = (url, prod_type) => {
    return String(url).includes(prod_type);
  };
  return (
    <header className="header container-fluid " style={{ padding: "0" }}>
      <div
        className="header-top "
        onClick={() => {
          setchercher(false);
        }}
      >
        <div className="container">
          <div className="header-left  ">
            <p className="top-message ls-0 text-uppercase text-white d-none d-sm-block">
              <a href="#" target="_blank" rel="noopener">
                <img
                  src={"assets/imagev2/local1." + variable}
                  style={{ display: "inline-block" }}
                  alt="Notre adresse"
                />{" "}
                4120 Rte de Tournai, 59500 Douai, France
              </a>
              <span className="separator" />{" "}
              <img
                src={`assets/imagev2/cxcxc.${variable}`}
                style={{ display: "inline-block", marginTop: "-.6rem" }}
                alt="contactez-nous"
              />
              <Link to="/contactez-nous/"> contact@alfaprint.fr</Link>
            </p>
          </div>
          {/* End .header-left */}
          <div className="header-right header-dropdowns w-sm-100">
            <div className="header-dropdown dropdown-expanded d-none d-lg-block mr-4">
              <div className="header-menu">
                <ul>
                  <li>
                    <Link to="/blog/">Blog</Link>
                  </li>
                  <li>
                    <Link to="/a-propos/">À propos</Link>
                  </li>
                  <li>
                    <Link to="/contactez-nous/">Contact</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/faq/">FAQ</Link>
                  </li>
                </ul>
              </div>
              {/* End .header-menu
               */}
            </div>
            {/* End .header-dropown */}
            <span className="separator" />
            <div className="header-menu">
              <div
                className="header-contact  d-lg-flex d-m-flex "
                style={{ display: "flex" }}
              >
                <img
                  alt="phone"
                  src={"assets/imagev2/phone." + variable}
                  width={20}
                  height={20}
                  className="pb-1"
                />
                <p style={{ color: "#006338", lineHeight: "1.3rem" }}>
                  Appelez nous
                  <br />
                  <span
                    href="tel:#"
                    className="font1"
                    style={{ color: "#006338" }}
                  >
                    {" "}
                    (+33)9 82 54 78 30{" "}
                  </span>
                </p>
                <a
                  href="https://api.whatsapp.com/send?phone=33767925428"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    style={{ width: "2.4rem", marginLeft: ".8rem" }}
                    src={"assets/imagev2/whatsapplinked." + variable}
                    alt="whatsapp"
                  />
                </a>
              </div>
            </div>

            {/* End .header-dropown */}

            {/* End .header-dropown */}
            <span className="separator" />
            <div className="social-icons" style={{ marginLeft: "auto" }}>
              <a
                href="https://www.facebook.com/www.alfaprint.fr/"
                className="social-icon social-facebook icon-facebook"
                target="_blank"
                title="Facebook"
                rel="noopener"
              >
                <svg className="icon-down" style={{ fill: "#afadad" }}>
                  <use xlinkHref="assets/icons.svg#icon-facebook" />
                </svg>
              </a>
            </div>

            {/* End .social-icons */}
          </div>
          {/* End .header-right */}
        </div>
        {/* End .container */}
      </div>
      {/* <div className="navBar-search">


<div  style={{ marginTop: '29px' }}>
<div className='info-boxes-container row row-joined mb-1'>
  <div className='info-box info-box-icon-left col-lg-3 'style={{marginTop:"-5px"}}>
    <img
      className='icon-down'
               src={Icon3}
      style={{
        width: '10rem',
        height: '4rem',
        marginRight: '1rem',
      }}
      alt='Ils nous font confiance '
    />
    <div className='info-box-content'>
      <p style={{ textTransform: 'none' ,color:"white"}}>
        Prix compétitifs <br /> 
        jusqu’à 50% de réduction
      </p>
    </div>
  </div>
  <div className='info-box info-box-icon-left col-lg-3' style={{marginTop:"-5px"}}>
    <img
      className='icon-down'
      src={Icon4}
      style={{
        width: '10rem',
        height: '4rem',
        marginRight: '1rem',
      }}
      alt='Service de livraison ultra-rapide'
    />
    <div className='info-box-content'>
      <p style={{ textTransform: 'none',color:"white" }}>
       Livraison express en 24H<br />
        A partir de 49€
      </p>
    </div>
  </div>
  <div className='info-box info-box-icon-left col-lg-2' style={{marginTop:"-5px"}}>
    <img
      className='icon-down'
      src={Icon1}
      style={{
        width: '8rem',
        height: '4rem',
        marginRight: '1rem',
      }}
      alt='Des offres exclusives jusqu’à -50%'
    />
    <div className='info-box-content'>
    <img
      className='icon-down'
      src={Icon2}
      style={{
        width: '8rem',
        height: '4rem',
        marginRight: '1rem',
      }}
      alt='Des offres exclusives jusqu’à -50%'
    />
    </div>
  </div>
  <div className='info-box info-box-icon-left col-lg-2'style={{marginTop:"-5px"}}>
    <img
      className='icon-down'
      src={Icon5}
      style={{
        width: '10rem',
        height: '4rem',
        marginRight: '1rem',
      }}
      alt='+70 000 produits sur notre site web'
    />
    <div className='info-box-content'>
      <p style={{ textTransform: 'none',color:"white" }}>
    Retour et échange
        <br />
        gratuit*{' '}
      </p>
    </div>
  </div>
  <div className='info-box info-box-icon-left col-lg-2'style={{marginTop:"-5px"}}>
    <img
      className='icon-down'
      src={Icon6}
      style={{
        width: '8rem',
        height: '4rem',
        marginRight: '1rem',
      }}
      alt='+70 000 produits sur notre site web'
    />
    <div className='info-box-content'>
      <p style={{ textTransform: 'none' ,color:"white"}}>
        +200.000 Clients PME-TPE
        <br />
        Satisfaits{' '}
      </p>
    </div>
  </div>
</div>
</div>
</div> */}
      {/* End .header-top */}
      <div className="header-middle">
        <div className="container">
          <div
            className="header-left col-lg-2 w-auto pl-0"
            onClick={() => {
              setchercher(false);
            }}
          >
            <button
              className="mobile-menu-toggler mr-2"
              type="button"
              onClick={() => {
                document.body.classList.add("mmenu-active");
              }}
            >
              {" "}
              <img
                className="icon-down"
                src={"assets/icons/menu.svg"}
                alt="menu mobile"
                style={{
                  width: "20px",
                  height: "20px",
                  marginTop: "-5px",
                }}
              />{" "}
            </button>
            <Link to="/" className="logo">
              <img
                src={"assets/imagev2/logo." + variable}
                alt="logo d’Alfa Print"
              />
            </Link>
          </div>
          {/* End .header-left */}
          <div className="header-right w-lg-max ml-0">
            {!isMobile ? (
              // <Chercher
              //   send_Results={send_Results}
              //   class='chercherweb'
              //   device='web'
              //   list={listeproduits}
              // />

              <SearchBox
                send_Results={send_Results}
                class="chercherweb"
                device="web"
                liste_json={listeproduits_sp}
                liste_pr_json={listeproduits_pr}
              />
            ):(<></>)
          }

            {/* {isMobile && (
              <svg
                className="icon-down cherchermobileicon"
                style={{
                  fill: "#777",

                  width: "25px",

                  height: "25px",
                  margin: "0 1rem",
                }}
                onClick={() => {
                  let bool = showchercher;

                  setchercher(!bool);
                }}
              >
                <use xlinkHref="assets/icons.svg#icon-search" />
              </svg>
            )} */}
            {connecte && client.client.nom + " " + client.client.prenom}
            {!connecte && (
              <Link to="/connect/" className="header-icon">
                <img
                  src={"assets/imagev2/icon 7." + variable}
                  alt="alfapint Logo"
                />
              </Link>
            )}
            {connecte && (
              <div
                className="dropdown cart-dropdown"
                style={{ marginLeft: "1rem" }}
              >
                <button
                  className="dropdown-toggle notabtn dropdown-arrow"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-display="static"
                  style={{ display: "flex" }}
                >
                  <img
                    src={"assets/imagev2/icon 7." + variable}
                    alt="alfapint Logo"
                  />
                </button>
                <div className="dropdown-menu">
                  <div className="dropdownmenu-wrapper">
                    <div className="dropdown-cart-header">
                      <Link to="/my-account/">Espace client</Link>
                    </div>
                    <div className="dropdown-cart-products">
                      <div
                        className="dropdown-cart-header"
                        style={{ cursor: "pointer" }}
                        onClick={deconnecter}
                      >
                        DÉCONNEXION
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="dropdown cart-dropdown">
              <button
                className="dropdown-toggle notabtn dropdown-arrow"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-display="static"
                style={{ display: "flex" }}
              >
                <img
                  src={"assets/imagev2/icon 8." + variable}
                  alt="alfapint Logo"
                />{" "}
                {liste && liste.length > 0 ? (
                  <span className="cart-count badge-circle">
                    {liste && liste.length}
                  </span>
                ) : (
                  <span className="cart-count badge-circle">0</span>
                )}
              </button>
              <div className="dropdown-menu">
                <div className="dropdownmenu-wrapper">
                  <div className="dropdown-cart-header">
                    {liste && liste?.length > 0 ? (
                      <span>
                        {liste.length}{" "}
                        {liste.length == 1 ? "ARTICLE" : "ARTICLES"}
                      </span>
                    ) : (
                      <span>0 ARTICLES</span>
                    )}

                    <Link to="/cart/" className="float-right">
                      Voir mon panier
                    </Link>
                  </div>
                  {/* End .dropdown-cart-header */}
                  <div className="dropdown-cart-products">
                    {liste &&
                      liste.length > 0 &&
                      liste.map((data) => {
                        return (
                          <div className="product" key={data.product_id}>
                            <div className="product-details">
                              <p
                                className="product-title"
                                style={{ fontWeight: "600" }}
                              >
                                {type(data.url_fiche.toUpperCase(), "CONSOMMABLES") ? (
                                  <Link to={`/${data.url_fiche}/`}>
                                    {data.name_in_cart}
                                  </Link>
                                ) : (
                                  <Link to={`/${data.url_fiche}/`}>
                                    {data.brand + " " + data.model}
                                  </Link>
                                )}
                              </p>
                              <span className="cart-product-info">
                                <span className="cart-product-qty">
                                  {data.index}
                                </span>
                                x{calculPrice(data.prix * 1.2)}€
                              </span>
                            </div>
                            {/* End .product-details */}
                            <figure className="product-image-container">
                              <Link
                                to={`/${data.url_fiche}/`}
                                className="product-image"
                              >
                                {type(data.url_fiche.toUpperCase(), "IMPRIMANTES") && (
                                  <img
                                    src={`https://ns3156326.ip-51-91-212.eu/ecom_api/public/assets/img/printers_image/${data.model_id}.webp `}
                                    alt="product"
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src =
                                        "https://ns3156326.ip-51-91-212.eu/ecom_api/public/assets/img/impr.webp";
                                    }}
                                  />
                                )}
                                {type(data.url_fiche.toUpperCase(), "CONSOMMABLES") && (
                                  <img
                                    src={`https://ns3156326.ip-51-91-212.eu/ecom_api/public/assets/img/supplies_image/${data.model_id}.webp `}
                                    alt="product"
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src =
                                        "https://ns3156326.ip-51-91-212.eu/ecom_api/public/assets/img/impr.webp";
                                    }}
                                  />
                                )}
                              </Link>
                              <a
                                className="btn-remove icon-cancel"
                                title="Remove Product"
                                onClick={() => {
                                  props.deletfromepannier(data.product_id);
                                }}
                              >
                                <svg
                                  className="icon-down"
                                  style={{
                                    fill: "#777",
                                    width: "2rem",
                                    height: "2rem",
                                    cursor: "pointer",
                                  }}
                                >
                                  <use xlinkHref="assets/icons.svg#icon-cross" />
                                </svg>
                              </a>
                            </figure>
                          </div>
                        );
                      })}
                  </div>
                  {/* End .cart-product */}
                  <div className="dropdown-cart-total">
                    <span>Total TTC</span>
                    {liste && liste.length > 0 ? (
                      <span className="cart-total-price float-right">
                        {calculPrice(calculPrice(somme()) * 1.2) + "€"}
                      </span>
                    ) : (
                      <span className="cart-total-price float-right">
                        {0 + "€"}
                      </span>
                    )}
                  </div>
                  {/* End .dropdown-cart-total */}
                  <div className="dropdown-cart-action">
                    {liste && liste.length > 0 && connecte ? (
                      <Link to="/checkout/" className="btn btn-dark btn-block">
                        Paiement
                      </Link>
                    ) : (
                      <></>
                    )}
                    {liste && liste.length > 0 && !connecte ? (
                      <Link to="/connecter/" className="btn btn-dark btn-block">
                        Paiement
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* End .dropdown-cart-total */}
                </div>
                {/* End .dropdownmenu-wrapper */}
              </div>
              {/* End .dropdown-menu */}
            </div>
            {/* End .dropdown */}
          </div>
          {/* End .header-right */}
        </div>
        {isMobile ? (<div style={{marginTop: "5px", paddingRight:"10px"}}><SearchBox
                send_Results={send_Results}
                class="chercherweb"
                device="web"
                liste_json={listeproduits_sp}
                liste_pr_json={listeproduits_pr}
              /></div>):(<></>)}
        {/* End .container */}
      </div>
      {/* End .header-middle */}

      <div
        id="sticky"
        className="header-bottom sticky-header d-none d-lg-block"
      >
        <div className="container">
          <nav className="main-nav w-100">
            <ul className="menu">
              <li>
                <Link to="/">Accueil</Link>
              </li>
              <li id="menu-imprimante">
                <p>
                  Imprimantes
                  <svg
                    className="icon-down"
                    style={{
                      fill: "white",
                    }}
                  >
                    <use xlinkHref="assets/icons.svg#icon-cheveron-down" />
                  </svg>
                </p>
                <div className="megamenu megamenu-fixed-width megamenu-2cols">
                  <div className="row">
                    <div className="col-lg-8">
                      <ul className="submenu">
                        <li style={{ marginTop: "-1.5rem" }}>
                          <Link to="/imprimante-multifonction/">
                            Multifonction
                          </Link>
                        </li>
                        <li>
                          <Link to="/imprimante-jet-d-encre/">Jet d'encre</Link>
                        </li>
                        <li>
                          <Link to="/imprimante-laser/">Laser</Link>
                        </li>
                      </ul>
                    </div>
                    {/* End .col-lg-4 */}

                    {/* End .col-lg-4 */}

                    {/* End .col-lg-4 */}
                    <div className="col-lg-4 p-0">
                      <img
                        src={"assets/images/124." + variable}
                        alt={`Notre sélection des imprimante jet d'encre, multifonction, laser `}
                        className="product-promo"
                      />
                    </div>
                    {/* End .col-lg-4 */}
                  </div>
                  {/* End .row */}
                </div>
                {/* End .megamenu */}
              </li>
              <li id="menu-consommable">
                <Link to="/consommable/">
                  Consommables
                  <svg
                    className="icon-down"
                    style={{
                      fill: "white",
                    }}
                  >
                    <use xlinkHref="assets/icons.svg#icon-cheveron-down" />
                  </svg>
                </Link>
                <div className="megamenu megamenu-fixed-width megamenu-2cols">
                  <div className="row">
                    <div className="col-lg-8">
                      <ul className="submenu">
                        <li style={{ marginTop: "-1.5rem" }}>
                          <Link to="/consommable/toner/">Toners</Link>
                        </li>
                        <li>
                          <Link to="/consommable/cartouche/">Cartouches</Link>
                        </li>
                        <li>
                          <Link to="/consommable/ruban/">Rubans</Link>
                        </li>
                        <li>
                          <Link to="/consommable/bobine-thermique/">
                            Bobines thermiques
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/* End .col-lg-4 */}
                    <div className="col-lg-4 p-0">
                      <img
                        src={"assets/images/123." + variable}
                        alt={`Consommables toners & cartouche d'encre `}
                        className="product-promo"
                      />
                    </div>
                    {/* End .col-lg-4 */}
                  </div>
                  {/* End .row */}
                </div>
                {/* End .megamenu */}
              </li>
              <li id="menu-autremateriel">
                <p>
                  Autres matériels
                  <svg
                    className="icon-down"
                    style={{
                      fill: "white",
                    }}
                  >
                    <use xlinkHref="assets/icons.svg#icon-cheveron-down" />
                  </svg>
                </p>
                <div className="megamenu megamenu-fixed-width megamenu-2cols">
                  <div className="row">
                    <div className="col-lg-8">
                      <ul className="submenu">
                        <li style={{ marginTop: "-1.5rem" }}>
                          <Link to="/autres-materiels/imprimante-etiquette/">
                            Imprimantes à étiquettes
                          </Link>
                        </li>
                        <li>
                          <Link to="/autres-materiels/imprimante-point-de-vente/">
                            Imprimantes point de vente
                          </Link>
                        </li>
                        <li>
                          <Link to="/traceur/">Traceurs</Link>
                        </li>
                      </ul>
                    </div>
                    {/* End .col-lg-4 */}
                    <div className="col-lg-4 p-0">
                      <img
                        src={"assets/images/1222." + variable}
                        alt="Autre matériel tels que imprimante étiquette et traceurs "
                        className="product-promo"
                      />
                    </div>
                    {/* End .col-lg-4 */}
                  </div>
                  {/* End .row */}
                </div>
                {/* End .megamenu */}
              </li>
              <li id="menu-marque">
                <p>
                  Marques
                  <svg
                    className="icon-down"
                    style={{
                      fill: "white",
                    }}
                  >
                    <use xlinkHref="assets/icons.svg#icon-cheveron-down" />
                  </svg>
                </p>

                <ul className="custom-menu">
                  <li>
                    <Link to="/marques/imprimante-hp/">Hp</Link>
                  </li>

                  <li>
                    <Link to="/marques/imprimante-brother/">Brother</Link>
                  </li>
                  <li>
                    <Link to="/marques/imprimante-epson/">Epson</Link>
                  </li>
                  <li>
                    <Link to="/marques/imprimante-samsung/">Samsung</Link>
                  </li>
                  <li>
                    <Link to="/marques/imprimante-canon/">Canon</Link>
                  </li>
                  <li>
                    <Link to="/marques/imprimante-lexmark/">Lexmark</Link>
                  </li>
                  <li>
                    <Link to="/marques/imprimante-ricoh/">Ricoh</Link>
                  </li>
                  <li>
                    <Link to="/marques/imprimante-xerox/">Xerox</Link>
                  </li>
                  <li>
                    <Link to="/marques/imprimante-kyocera/">Kyocera</Link>
                  </li>
                  <li>
                    <Link to="/marques/imprimante-oki/">Oki</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/promotions/">
                  Promotions
                  <span
                    className="tip tip-hot tip-top"
                    style={{ width: "max-content" }}
                  >
                    Prix exceptionnel !
                  </span>
                </Link>
              </li>

              <li>
                <Link to="/contrat/">
                  Impression illimitée
                  <span className="tip tip-new tip-top" alt="page forfait">
                    Nouveau
                  </span>
                </Link>
              </li>
              <li className="float-right">
                <Link to="/demande-devis/">Demander un devis</Link>
              </li>
            </ul>
          </nav>
        </div>
        {/* End .container */}
      </div>
      {/* End .header-bottom */}
    </header>
  );
}
export default Navbar;
