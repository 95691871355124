import React, { useRef } from 'react';
import * as apis from '../apis/apicall';
import { useState} from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import '../components/navbar.css';
function Footer() {



  const { register, handleSubmit } = useForm();
  const [popupcookies, setpopupcookies] = useState(false);
  const [popuppr, setpopuppr] = useState(false);
  const [popupml, setpopupml] = useState(false);
  const [popupcgv, setpopupcgv] = useState(false);
  const [popup, setpopup] = useState(false);
  const [email, setEmail] = useState();
  const subscribe = async (data) => {
    setEmail(await apis.email(data));
    setpopup(true)
    setTimeout(() => {
      setpopup(false)
      setEmail(false);
    }, 3000);
  };
  return (
    <div>



    {popupml && (
      <div className='popup-conditions'>
        <div
          className='bg-popup'
          onClick={() => {
            setpopupml(false);
          }}
        ></div>
        <div className='conditions-container'>
          <div
            id='swal2-content'
            style={{ display: 'block', width:'90%',marginLeft:'5%' }}
          >
          <h1 className='h1Comp'>1 &ndash; INFORMATIONS LEGALES&nbsp;</h1>
          <p className='pComp'>
            <strong>alfaprint.fr</strong> est un site de l&rsquo;entreprise Alfa
            Print, au capital social de 1000 &euro;,
            <br /> Si&egrave;ge social : 4 120 Route de Tournai 59500 DOUAI,
            num&eacute;ro TVA FR22812955128.
            <br /> Le directeur de publication est ABDELKARIM FEDDAL en
            qualit&eacute; de directeur g&eacute;n&eacute;ral.
            <br /> L&rsquo;H&eacute;bergeur du site Alfa Print est : OVH dont le
            capital social est de 10 069 020 &euro;, immatricul&eacute;e au
            Registre du Commerce et des Soci&eacute;t&eacute;s de Lille
            M&eacute;tropole. Le si&egrave;ge social est situ&eacute; 2 rue
            Kellermann &agrave; Roubaix, code postal : 59 100.
            <br /> Pour toute information ou question, contactez-nous sur notre
            site internet&nbsp;:&nbsp;<strong>alfaprint.fr</strong> ou par
            mail&nbsp;:&nbsp;contact@alfaprint.fr
            <br /> Si vous voulez avoir des informations sur la protection et le
            traitement de vos donn&eacute;es, ainsi que sur les cookies,
            consulter nos pages &laquo; Cookies &raquo; et &laquo; Conditions
            g&eacute;n&eacute;rales de vente &raquo;. Les deux pages sont
            accessibles en bas de chaque page de notre site.&nbsp;
          </p>

          <h2
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '23px',
            }}
          >
            2 - CONDITIONS GENERALES DE VENTE
          </h2>

          <p className='pComp'>
            Alfa Print a mis en place un site internet pour vous permettre de
            commander en toute libert&eacute;. Des agents sont &agrave; votre
            disposition pour vous assister durant toute votre dur&eacute;e sur
            le site internet. De plus, si vous avez des questions &agrave;
            poser, nous serons ravis de r&eacute;pondre &agrave; toutes vos
            interrogations.&nbsp;
            <br /> Nous&nbsp;avons r&eacute;dig&eacute; des Conditions
            G&eacute;n&eacute;rales de Ventre qui permettent de nous informer
            sur notre mani&egrave;re de fonctionner lorsque vous d&eacute;cidez
            de passer une commande aupr&egrave;s de nos services.
            <br /> Merci de lire ces CGV attentivement avant d&rsquo;effectuer
            tout achat, n&eacute;anmoins si vous commandez, c&rsquo;est que vous
            &ecirc;tes d&rsquo;accord avec nos conditions
            g&eacute;n&eacute;rales de vente.
          </p>

          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
            }}
          >
            <strong>Champ d&rsquo;application</strong>
          </h3>

          <p className='pComp'>
            Les pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente
            sont applicables &agrave; tous les produits vendus par Alfa Print
            sur notre site internet :&nbsp;
          </p>
          <p className='pComp'>
            Aucune d&eacute;rogation aux pr&eacute;sentes conditions ne pourra
            &ecirc;tre admise sans accord expr&egrave;s et pr&eacute;alable de
            la soci&eacute;t&eacute; Alfa Print.
          </p>

          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
            }}
          >
            <strong>Comment commander ?</strong>
          </h3>
          <p className='pComp'>
            SI vous &ecirc;tes int&eacute;ress&eacute;s par un ou plusieurs
            produits que l&rsquo;on vous propose, vous pouvez les enregistrer
            dans votre panier. Apr&egrave;s avoir s&eacute;lectionn&eacute; les
            produits que vous voulez, rendez-vous dans votre panier afin de
            passer la commande.
          </p>
          <p className='pComp'>
            Lorsque vous vous rendez dans votre panier, vous avez un
            r&eacute;capitulatif de tous les produits enregistr&eacute;s, leur
            prix et leurs caract&eacute;ristiques. Lorsque vous &ecirc;tes
            pr&ecirc;t &agrave; passer la commande, cliquez sur commander, vous
            allez &ecirc;tre redirig&eacute; vers une fen&ecirc;tre qui, si vous
            n&rsquo;&ecirc;tes pas encore inscrit va vous demander de
            cr&eacute;er un compte. Ensuite, on va vous rediriger &agrave; la
            prochaine &eacute;tape qui est l&rsquo;adresse de livraison. Lorsque
            vous avez rentr&eacute; votre adresse de livraison, on vous propose
            quelques modalit&eacute;s de livraisons, il faut savoir que la
            livraison de vos produits se fait entre 48 h et 72 h. &Agrave; ce
            stade, il ne vous reste plus que le paiement, vous avez deux
            possibilit&eacute;s, pay&eacute;es en ch&egrave;que ou par carte
            bancaire. Apr&egrave;s avoir s&eacute;lectionn&eacute; votre moyen
            de paiement et en validant votre commande, notre &eacute;quipe va
            commencer &agrave; s&rsquo;occuper de celle-ci.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
            }}
          >
            <strong> Commandes &ndash; facturation&nbsp;</strong>
          </h3>
          <p className='pComp'>
            <strong>NB</strong>&nbsp;: Toute commande pass&eacute;e est ferme et
            d&eacute;finitive d&egrave;s l&apos;enregistrement de cette commande
            lors d&apos;un appel t&eacute;l&eacute;phonique ou sa
            r&eacute;ception par fax, courrier postal ou &eacute;lectronique.
            Aucune annulation de commande n&apos;est possible sauf dans le cas
            d&apos;une rupture sup&eacute;rieure &agrave; 1 mois et qui
            n&apos;&eacute;tait pas annonc&eacute;e par le technicien-conseil
            Alfa Print. Votre facture est jointe ensuite par mail apr&egrave;s
            la confirmation de votre paiement.
            <br /> Apr&egrave;s avoir pass&eacute; votre commande, nous vous
            envoyons un mail r&eacute;capitulatif avec toutes les informations
            n&eacute;cessaires pour suivre le colis. &Eacute;galement, nous
            pourrons vous contacter par la suite pour vous donner
            l&rsquo;&eacute;tat d&rsquo;avancement de votre commande.
          </p>
          <h2
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '23px',
            }}
          >
            3 - Livraison &ndash; Transport
          </h2>
          <p className='pComp'>
            Les marchandises disponibles sont livr&eacute;es dans un
            d&eacute;lai de 2 &agrave; 3 jours ouvrables sur toute la France
            m&eacute;tropolitaine.&nbsp;
          </p>
          <p className='pComp'>
            Ce d&eacute;lai est donn&eacute; &agrave; titre indicatif. Le
            d&eacute;passement de ce d&eacute;lai ne pourra en aucun cas
            &ecirc;tre retenu contre la soci&eacute;t&eacute; Alfa Print.&nbsp;
          </p>
          <p className='pComp'>
            Les frais de transport sont pris en charge par Alfa Print pour toute
            commande d&apos;un montant sup&eacute;rieur au &apos;Franco&apos;
            (fix&eacute; &agrave; 159 euros HT).&nbsp;
          </p>
          <p className='pComp'>
            Dans le cas d&apos;une commande d&apos;un montant inf&eacute;rieur
            au &apos;Franco&apos;, une participation forfaitaire sera
            factur&eacute;e (fix&eacute;e &agrave; 19 euros HT). En cas de
            probl&egrave;mes rencontr&eacute;s avec le colis, le Client doit
            indiquer pr&eacute;cis&eacute;ment ses r&eacute;serves sur le
            bordereau de livraison pr&eacute;sent&eacute; par le transporteur et
            les confirmer par &eacute;crit &agrave; son technicien-conseil Alfa
            Print dans les 48h. Apr&egrave;s cette &eacute;ch&eacute;ance, le
            client ne pourra plus proc&eacute;der &agrave; la
            modification.&nbsp;
          </p>
          <h2
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '23px',
            }}
          >
            4 - DROIT DE RETENTION
          </h2>
          <p className='pComp'>
            Lorsque vous commandez un article chez nous, les produits
            livr&eacute;s sont la propri&eacute;t&eacute; d&rsquo;Alfa Print
            jusqu&rsquo;&agrave; ce que nos diff&eacute;rents transporteurs
            prennent le relais.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
            }}
          >
            <strong>Droit legale de retractation de 14 jours</strong>
          </h3>
          <p className='pComp'>
            Selon la direction de l&rsquo;information l&eacute;gale et
            administrative, pour tout achat effectu&eacute; sur un site
            e-commerce le client &agrave; un droit de r&eacute;tractation de 14
            jours.&nbsp;Le droit de r&eacute;tractation vous permet
            d&rsquo;annuler une commande dans un d&eacute;lai de 14 jours sans
            avoir &agrave; donner de motif.&nbsp;&Agrave; partir de
            l&rsquo;instant o&ugrave; vous recevez le colis, ou accepter
            l&rsquo;offre pour une prestation, vous avez un d&eacute;lai de 14
            jours pour proc&eacute;der &agrave; l&rsquo;annulation.&nbsp;Si vous
            avez d&eacute;j&agrave; &eacute;t&eacute; pr&eacute;lev&eacute;, un
            remboursement se fera, dans le cas contraire, vous n&rsquo;allez pas
            &ecirc;tre pr&eacute;lev&eacute;.&nbsp;N&eacute;anmoins, les frais
            de retour du colis pourraient &ecirc;tre &agrave; votre charge.
            <br /> Lorsque vous d&eacute;cidez de vous r&eacute;tracter, nous
            comptons sur vous pour nous contacter sur notre site internet, par
            e-mail ou nous appeler directement, et cela, le plus vite possible
            afin que nous proc&eacute;dions si besoin au remboursement le plus
            vite possible.
            <br />
            &nbsp; Pour &ecirc;tre s&ucirc;r d&rsquo;&ecirc;tre rembours&eacute;
            vous devez respecter le d&eacute;lai de 14 jours, pass&eacute; ce
            d&eacute;lai aucun remboursement ne sera possible.
            <br /> Si vous voulez plus d&rsquo;informations par rapport &agrave;
            votre droit de r&eacute;tractation, n&rsquo;h&eacute;sitez pas
            &agrave; nous contacter sur notre site internet via le service
            client.&nbsp;Nous serons ravis de vous apporter plus
            d&rsquo;informations.
            <h4
              className='h3Comp'
              style={{
                color: '#005d86',
                fontWeight: '600',
                marginTop: '10px',
                marginBottom: '10px',
                fontSize: '21px',
              }}
            >
              <strong>Effet de retractation</strong>
            </h4>
            Lorsque nous proc&eacute;dons au remboursement, nous utilisons les
            moyens de paiement que vous avez utilis&eacute; pour r&eacute;gler
            votre commande, sauf en cas de refus de votre part. Dans ce cas,
            vous devez nous le signaler avant que nous proc&eacute;dions au
            remboursement. De plus, les frais de retours seront &agrave; votre
            enti&egrave;re charge. Nous prenons en charge la livraison lors de
            votre commande, mais en cas de r&eacute;tractation, la charge du
            retour vous revient.
            <br /> Cependant, avant de proc&eacute;der au remboursement nous
            nous assurons d&rsquo;abord que vous avez bien renvoy&eacute; notre
            ou nos produits, soit par une preuve d&rsquo;exp&eacute;dition ou
            lorsque nous avons r&eacute;ceptionn&eacute; le ou les produit(s).
            <br /> Pour renvoyer les produits, prenez contact avec nous afin que
            nous vous indiquions la proc&eacute;dure &agrave; suivre.&nbsp;
          </p>
          <h2
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '23px',
            }}
          >
            5 - PRIX- DISPONIBILITE-MODALITE DE PAIEMENT- MARCHANDISES
          </h2>
          <p className='pComp'>
            <strong>
              D&rsquo;apr&egrave;s les conditions du march&eacute;
            </strong>
            , les prix pourront &ecirc;tre modifi&eacute;s selon vos commandes.
            Selon l&rsquo;article L441-6 du code de commerce &laquo; en cas de
            retard de paiement une indemnit&eacute; forfaitaire calcul&eacute;e
            sur la base de trois fois le taux de l&rsquo;int&eacute;r&ecirc;t
            l&eacute;gal de la Banque de France, entra&icirc;ne des frais
            financiers. Une somme de 40 euros minimum et exigibilit&eacute;
            imm&eacute;diate des factures qui ne se sont pas encore
            arriv&eacute; &agrave; &eacute;ch&eacute;ance. De plus, tout
            incident bancaire entra&icirc;ne le paiement par le Client des frais
            bancaires support&eacute;s par Alfa Print.&nbsp;Aucun escompte
            n&apos;est accept&eacute;.
            <br /> Concernant la disponibilit&eacute; des produits, avant de
            passer toutes commandes, nous prenons le soin de vous
            sp&eacute;cifier si un produit est disponible sur notre site ou
            s&rsquo;il est en rupture de stock.&nbsp;En cas de soucis avec votre
            commande, nous n&rsquo;allons pas h&eacute;siter &agrave; vous
            contacter imm&eacute;diatement pour trouver le moyen de rebondir
            automatiquement.&nbsp;Pour vous informer de la disponibilit&eacute;
            des produits, nous pouvons soit vous appelez ou, vous appeler
            directement au t&eacute;l&eacute;phone.&nbsp;De plus, c&rsquo;est
            aussi pourquoi nous tenons &agrave; avoir vos informations telles
            que votre adresse mail, votre r&eacute;seau social le plus
            utilis&eacute; et votre num&eacute;ro de t&eacute;l&eacute;phone.
          </p>
          <p className='pComp'>
            <br /> <strong>Les marchandises livr&eacute;es</strong>&nbsp;restent
            la propri&eacute;t&eacute; de la soci&eacute;t&eacute; Alfa Print
            jusqu&apos;au paiement de la facture. Le Client devient le
            propri&eacute;taire de la marchandise d&egrave;s sa livraison et sa
            r&eacute;ception. En cas de paiement par carte bancaire, Alfa Print
            ne conserve pas les coordonn&eacute;es communiqu&eacute;es par le
            client. Vous pouvez vous assurer que vos coordonn&eacute;es ne
            fuiteront pas.
          </p>
          <h2
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '23px',
            }}
          >
            6 - GARANTIES
          </h2>
          <p className='pComp'>
            Selon l&rsquo;article L.217-4 du code de la consommation : &laquo;
            &nbsp;Le vendeur livre un bien conforme au contrait et r&eacute;pond
            des d&eacute;fauts de conformit&eacute; existant lors de la
            d&eacute;livrance &raquo;. &nbsp;Vous b&eacute;n&eacute;ficiez de la
            garantie l&eacute;gale de la conformit&eacute; et la garantie des
            vices cach&eacute;s.&nbsp;
          </p>
          <p className='pComp'>
            Alfa Print garantit jusqu&apos;&agrave; compl&egrave;te utilisation
            les produits livr&eacute;s en &eacute;changeant ou remboursant toute
            marchandise d&eacute;fectueuse. Dans le cas d&apos;utilisation
            exclusive de ses produits, la garantie est &eacute;tendue au
            mat&eacute;riel, dans le cadre de l&apos;usage et de la dur&eacute;e
            de vie normale dudit mat&eacute;riel (imprimante de moins de 3 ans).
            Cette garantie sera nulle si le produit Alfa Print est
            d&eacute;mont&eacute; d&apos;une fa&ccedil;on quelconque ou
            s&apos;il est manipul&eacute;, entrepos&eacute; ou utilis&eacute;
            dans des conditions anormales.&nbsp;
          </p>
          <p className='pComp'>
            Pour b&eacute;n&eacute;ficier de la garantie, le Client doit avertir
            son technicien-conseil Alfa Print qui lui indiquera la marche
            &agrave; suivre. Les frais de transport pour le retour seront pris
            en charge par Alfa Print.
          </p>

          <h2
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '23px',
            }}
          >
            7 - LES DONNEES NOMINATIVES, INFORMATIQUE ET LIBERTES.
          </h2>
          <p className='pComp'>
            Les informations demand&eacute;es au client sont importantes, car
            elles permettent de mieux traiter et livre les commandes. De plus,
            gr&acirc;ce &agrave; ces informations, l&rsquo;&eacute;tablissement
            aura la possibilit&eacute; de vous contacter en cas de
            probl&egrave;mes avec vos commandes. Ses informations pourront
            &ecirc;tre communiqu&eacute;es aux partenaires contractuels
            d&apos;Alfa Print intervenants dans le cadre de
            l&apos;ex&eacute;cution de cette commande, ainsi que dans le cadre
            de la lutte contre la fraude. L&apos;absence de ces informations
            entra&icirc;nera l&apos;annulation de la commande. La communication
            de fausses informations est contraire aux pr&eacute;sentes
            conditions g&eacute;n&eacute;rales.
            <br /> Alfa Print&nbsp;est le seul d&eacute;tenteur des informations
            vous concernant. Et seulement si vous l&apos;avez accept&eacute;
            lors de votre enregistrement sur le site, Alfa Print et ses
            partenaires pourront vous envoyer des informations dans le cadre
            d&apos;op&eacute;rations promotionnelles pr&eacute;cises et
            ponctuelles. Cependant, &nbsp;si vous ne souhaitez plus recevoir de
            courrier &eacute;lectronique, vous pouvez d&eacute;sactiver cette
            option en cliquant sur le destin&eacute; &agrave; toute annulation
            d&rsquo;inscription &agrave; la newsletter.
            <br /> Conform&eacute;ment &agrave; la loi &laquo; Informatique et
            libert&eacute; &raquo; N&deg; 78-17 du 6 janvier 1978, les clients
            disposent &agrave; tout moment d&apos;un droit d&apos;acc&egrave;s,
            de rectification ou de suppression des donn&eacute;es qui les
            concernent en nous adressant soit un courrier &eacute;lectronique
            &agrave; contact@alfaprint.fr ou un courrier &agrave;
            :&nbsp;Alfa&nbsp;Print, 4120 Route de&nbsp;tournai&nbsp;59500
            Douai.&nbsp;Pour l&apos;exercice de ces droits, les clients devront
            communiquer une copie de leur pi&egrave;ce d&apos;identit&eacute; en
            cours de validit&eacute;.
          </p>

          <h2
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '23px',
            }}
          >
            8 - LOI APPLICABLE-TRIBUNAL COMPETENT
          </h2>
          <p className='pComp'>
            Les pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente
            sont r&eacute;gies exclusivement par la loi fran&ccedil;aise. En cas
            de Litige, le tribunal de commerce de Douai se chargera de
            l&rsquo;affaire.
          </p>
          <h2
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '23px',
            }}
          >
            9 - EN CAS DE MODIFCATION DES CGV
          </h2>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '2rem',
            }}
          >
            <strong>CGV : Conditions G&eacute;n&eacute;rales de Vente</strong>
          </h3>
          <p className='pComp'>
            Nous sommes dans le droit d&rsquo;apporter des changements &agrave;
            notre site internet, nos termes et conditions, nos
            proc&eacute;dures, nos conditions g&eacute;n&eacute;rales de vente.
            En cas de modification, nous allons vous informer par mail ou lors
            de votre visite sur notre site internet. D&egrave;s le moment ou
            vous passez une commande chez nous, vous &ecirc;tes automatiquement
            soumis aux termes et conditions g&eacute;n&eacute;rales de
            vente.&nbsp;
          </p>
          <h4
            className='h3Comp'
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            <strong>Nos engagements :</strong>
          </h4>
          <p className='pComp'>
            &laquo; &nbsp;La satisfaction client est notre priorit&eacute;
            &raquo;
          </p>
          <h4
            className='h3Comp'
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            <strong>Livraisons&amp;Retour</strong>
          </h4>
          <p className='pComp'>
            Depuis la cr&eacute;ation de notre entreprise, nous veillons
            &agrave; l&rsquo;exp&eacute;dition de nos colis pour que nos soient
            satisfaits de leur commande. Nous misons &eacute;norm&eacute;ment
            sur la satisfaction, nous sommes pr&ecirc;ts &agrave; faire le
            maximum pour r&eacute;pondre &agrave; vos besoins. Avec une
            &eacute;quipe performante et d&eacute;vou&eacute;e, on
            s&rsquo;assure &agrave; ce que vous receviez vos produits dans les
            meilleurs d&eacute;lais.
          </p>
          <h4
            className='h3Comp'
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            <strong>Livraison</strong>
          </h4>
          <p className='pComp'>
            Nous nous engageons &agrave; vous livrer en 2 &agrave; 3 jours
            ouvrables maximum.&nbsp;
          </p>
          <p className='pComp'>
            Vous pouvez passer vos commandes du lundi au vendredi entre 9 h et
            17 h 30.&nbsp;
          </p>
          <p className='pComp'>
            Pour le suivi de votre colis, vous pouvez nous contacter
            directement.
          </p>
          <h4 className='h3Comp'>
            <strong>Retour</strong>&nbsp;:&nbsp;
          </h4>
          <p className='pComp'>
            Si vous voulez renvoyer votre colis, contactez-nous directement sur
            notre site internet via le chatbot, par email ou par
            t&eacute;l&eacute;phone. En cas de retour, les frais sont &agrave;
            votre charge.&nbsp;
          </p>
          <p className='pComp'>
            Nous restons &agrave; votre disposition. Pour toutes informations
            compl&eacute;mentaires.&nbsp;
          </p>
          <h4 className='h3Comp'>
            <strong>Paiement s&eacute;curis&eacute;&nbsp;:</strong>
          </h4>
          <p className='pComp'>
            Notre site e-commerce est totalement s&eacute;curis&eacute;. Il
            dispose d&rsquo;un certificat qui prot&egrave;ge vos donn&eacute;es
            de paiement.&nbsp;
          </p>
          <p className='pComp'>
            De plus, nos employ&eacute;s ont sign&eacute; un contrat de
            confidentialit&eacute; qui emp&ecirc;che toutes divulgations
            d&rsquo;informations.&nbsp;
          </p>
          <p className='pComp'>
            <strong>Moyens de Paiement</strong>&nbsp;: Nous avons deux moyens de
            paiement, soit par carte bancaire ou par ch&egrave;que. Nous vous
            assurons que nous n&rsquo;enregistrons pas les coordonn&eacute;es de
            votre carte bancaire alors vous pouvez &ecirc;tre s&ucirc;r que nous
            ne partagerons pas vos donn&eacute;es.&nbsp;
          </p>
          <p className='pComp'>
            <strong>3D&nbsp;:</strong>&nbsp;Vos cartes bancaires sont
            prot&eacute;g&eacute;es par le syst&egrave;me 3D Secure. Le
            Syst&egrave;me donne droit &agrave; une double v&eacute;rification
            pour tous paiements en ligne. Cette m&eacute;thode garantit une
            s&eacute;curit&eacute; optimale de vos coordonn&eacute;es.
          </p>

          <br />
          </div>
        </div>
      </div>
    )}
    {popuppr && (
      <div className='popup-conditions'>
        <div
          className='bg-popup'
          onClick={() => {
            setpopuppr(false);
          }}
        ></div>
        <div className='conditions-container'>
          <div
            id='swal2-content'
            style={{ display: 'block', width:'90%',marginLeft:'5%' }}
          >
          <br/>
          <h1 className='h1Comp big-title' style={{textAlign:"center",fontSize:"35px"}}>
            POLITIQUE DE RETOUR
          </h1>
          <br/>
          <h2 className='h2Comp'  style={{textDecoration:"underline"}}>Délais de rétractation</h2>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
          Vous avez 14 jours, à date de livraison du dernier produit de votre commande, pour exercer votre droit de rétractation.
Si 14 jours se sont écoulés depuis la livraison du dernier produit, nous ne pouvons malheureusement pas accepter de retour du produit.
</p>{' '}
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Pour pouvoir bénéficier d’un retour, votre article doit être inutilisé et dans le même état où vous l’avez reçu. Il doit être également dans l’emballage d’origine.
          </p>{' '}
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
          Les articles qui ne peuvent pas être retournés sont:
        * Logiciels téléchargeables
        * Certains produits de santé et de soins personnels
        </p>{' '}
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
          Pour effectuer un retour, vous devez formuler votre demande par mail à <a href="mailto:contact@alfaprint.fr" style={{color:'blue'}}>contact@alfaprint.fr</a> en précisant votre numéro de commande et la date de livraison du dernier produit de votre commande.
Vous recevrez ensuite un numéro d’autorisation, à faire apparaitre à l’extérieur du colis.

          </p>{' '}
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
          Il y a certaines situations où seul un remboursement partiel est accordé: (le cas échéant)
        * Tout article qui n’est pas dans son état d’origine, qui est endommagé ou qui présente certaines pièces manquantes pour des raisons qui ne sont pas dues à une erreur de notre part.
        * Tout article qui est retourné plus de 30 jours après la livraison.

          </p>{' '}
          <h2
            className='h2Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            Remboursements (le cas échéant){' '}
          </h2>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
          Une fois que nous aurons reçu et inspecté l’article retourné, nous vous enverrons un e-mail pour vous confirmer que nous l’avons bien reçu. Nous vous informerons également de notre décision quant à l’approbation ou au rejet de votre demande de remboursement.
          Si votre demande est approuvée, alors votre remboursement sera traité, et un crédit sera automatiquement appliqué à votre carte de crédit ou à votre méthode originale de paiement, dans un délai d’un certain nombre de jours.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
          Remboursements en retard ou manquants (le cas échéant){' '}
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
          Si vous n’avez pas encore reçu votre remboursement, veuillez d’abord consulter votre compte bancaire à nouveau.
Ensuite, contactez l’entité émettrice de votre carte de crédit, car il pourrait y avoir un délai avant que votre remboursement ne soit officiellement affiché.
Ensuite, contactez votre banque. Il y a souvent un délai de traitement nécessaire avant qu’un remboursement ne soit affiché.
Si après avoir effectué toutes ces étapes, vous n’avez toujours pas reçu votre remboursement, veuillez s’il vous plait nous contacter à <a href="mailto:contact@alfaprint.fr" style={{color:'blue'}}>contact@alfaprint.fr</a> .

          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            Articles soldés (le cas échéant){' '}
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
          Seuls les articles à prix courant peuvent être remboursés. Malheureusement, les articles soldés ne sont pas remboursables.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            Échanges (le cas échéant)
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            {' '}
            Nous remplaçons un article seulement s’il est défectueux ou endommagé. Si dans ce cas vous souhaitez l’échanger contre le même article, envoyez-nous un e-mail à contact@alfaprint.fr et envoyez-nous votre article à: Alfa Print, 4120 route de Tournai, 59500 Douai.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            Cadeaux{' '}
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
          Si l’article retourné était identifié comme un cadeau lors de l’achat et s’il vous a été envoyé directement, vous recevrez un crédit-cadeau d’une valeur égale à celle de votre retour. Une fois que nous aurons reçu l’article retourné, un chèque-cadeau vous sera envoyé par courrier.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Si l’article n’a pas été identifié comme un cadeau lors de l’achat, ou si le donateur du cadeau a préféré recevoir d’abord l’article pour vous l’offrir plus tard, nous enverrons un remboursement au donateur du cadeau et il saura que vous avez retourné l’article.
            </p>

          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            Expédition
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            {' '}
            Pour retourner un produit, vous devez l’envoyer par la poste à: Alfa Print, 4120 route de Tournai, 59500 Douai.
            </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            {' '}
            Il vous incombera de payer vos propres frais d’expédition pour retourner votre article. Les coûts d’expédition ne sont pas remboursables. Si vous recevez un remboursement, les frais de retour seront déduits de celui-ci.
            </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            {' '}
            En fonction de l’endroit où vous vivez, le délai nécessaire pour recevoir votre produit échangé peut varier.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            {' '}
            Si vous expédiez un article d’une valeur supérieure à 75 euros, vous devriez envisager d’utiliser un service de livraison qui vous permet de suivre l’envoi ou de souscrire à une assurance de livraison. Nous ne garantissons pas que nous recevrons l’article que vous nous retournez.
          </p>

          <br />
          </div>
        </div>
      </div>
    )}
    {popupcgv && (
      <div className='popup-conditions'>
        <div
          className='bg-popup'
          onClick={() => {
            setpopupcgv(false);
          }}
        ></div>
        <div className='conditions-container'>
          <div
            id='swal2-content'
            style={{ display: 'block', width:'90%',marginLeft:'5%' }}
          >
          <br/>
          <h1 className='h1Comp big-title' style={{textAlign:"center",fontSize:"35px"}}>
            CONDITIONS GÉNÉRALES
          </h1>
          <br/>
          <h1 className='h1Comp big-title'>
            CONDITIONS GÉNÉRALES DE VENTE ET D’UTILISATION
          </h1>
          <h2 className='h2Comp'  style={{textDecoration:"underline"}}>VUE D’ENSEMBLE</h2>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Ce site web est exploité par <strong>Alfa Print</strong>. Sur ce site, les termes "nous", "notre" et "nos" font référence à <strong>Alfa Print. Alfa Print</strong> propose ce site web, y compris toutes les informations, tous les outils et tous les services qui y sont disponibles pour vous, l’utilisateur, sous réserve de votre acceptation de l’ensemble des modalités, conditions, politiques et avis énoncés ici.
          </p>{' '}
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            En visitant ce site et/ou en achetant un de nos produits, vous vous engagez dans notre "Service" et acceptez d’être lié par les modalités suivantes ("Conditions Générales de Vente", "Conditions Générales de Vente et d’Utilisation", "Conditions"), y compris les modalités, conditions et politiques additionnelles auxquelles il est fait référence ici et/ou accessibles par hyper lien. Ces Conditions Générales de Vente et d’Utilisations ‘appliquent à tous les utilisateurs de  ce site, incluant mais ne se limitant pas, aux utilisateurs qui naviguent sur le site, qui sont des vendeurs, des clients, des marchands, et/ou des contributeurs de contenu.</p>{' '}
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
          Veuillez lire attentivement ces Conditions Générales de Vente et d’Utilisation avant d’accéder à/ou d’utiliser notre site web. En accédant à	/ou en utilisant une quelconque partie de ce site, vous acceptez d’être lié par ces Conditions Générales de Vente et d’Utilisation. Si vous n’acceptez pas toutes les modalités et toutes les Conditions de cet accord, alors vous ne devez pas accéder au site web n’utiliser les services qui y sont proposés. Si ces Conditions Générales de Vente et d’Utilisation sont considérées comme une offre, l’acceptation se limite expressément à ces Conditions Générales de Vente et d’Utilisation.
          </p>{' '}
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
          Toutes les nouvelles fonctionnalités et tous les nouveaux outils qui seront ajoutés ultérieurement à cette boutique seront également assujettis à ces Conditions Générales de Vente et d’Utilisation. Vous pouvez consulter la version la plus récente des Conditions Générales de Vente et d’Utilisation à tout moment sur cette page. Nous nous réservons le droit de mettre à jour, de changer ou de remplacer toute partie de ces Conditions Générales de Vente et d’Utilisation en publiant les mises à jour et/ou les modifications sur notre site web. Il vous incombe de consulter cette page régulièrement pour vérifier si des modifications ont été apportées. Votre utilisation continue du site web ou votre accès à celui-ci après la publication de toute modification constitue une acception de votre part de ces modifications.
          </p>{' '}
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
          Notre boutique est hébergée sur OVH. Ils nous fournissent la plate-forme e-commerce qui nous permet de vous vendre nos produits et services.
          </p>{' '}
          <h2
            className='h2Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 1 – CONDITIONS D’UTILISATION DE NOTRE BOUTIQUE EN LIGNE{' '}
          </h2>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            En acceptant ces Conditions Générales de Vente et d’Utilisation, vous déclarez que vous avez atteint l’âge de la majorité dans votre pays, État ou province de résidence, et que vous nous avez donné votre consentement pour permettre à toute personne d’âge mineur à votre charge d’utiliser ce site web.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            L’utilisation de nos produits à toute fin illégale ou non autorisée est interdite, et vous ne devez pas non plus, dans le cadre de l’utilisation du Service, violer les lois de votre juridiction (incluant mais ne se limitant pas aux lois relatives aux droits d’auteur).
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Vous ne devez pas transmettre de vers, de virus ou tout autre code de nature destructive.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Toute infraction ou violation des présentes Conditions Générales de Vente et d’Utilisation entraînera la résiliation immédiate de vos Services.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 2 – CONDITIONS GÉNÉRALES{' '}
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Nous nous réservons le droit de refuser à tout moment l’accès aux services à toute personne, et ce, pour quelque raison que ce soit.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Vous comprenez que votre contenu (à l’exclusion de vos informations de carte de crédit) pourrait être transféré de manière non chiffrée, et cela sous-entend (a) des transmissions sur divers réseaux; et (b) des changements pour se conformer et s’adapter aux exigences techniques pour la connexion des réseaux ou appareils. Les informations de carte de crédits ont toujours chiffrées pendant la transmission sur les réseaux.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
          Vous acceptez de ne pas reproduire, dupliquer, copier, vendre, revendre ou exploiter une quelconque partie du Service ou utilisation du Service, ou un quelconque accès au Service ou contact sur le site web, par le biais duquel le Service est fourni, sans autorisation écrite expresse préalable de notre part.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
          Les titres utilisés dans cet accord sont inclus pour votre commodité, et ne vont affecter ces Conditions.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 3 – EXACTITUDE, EXHAUSTIVITÉ ET ACTUALITÉ DES INFORMATIONS{' '}
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Nous ne sommes pas responsables si les informations disponibles sur ce site ne sont pas exactes, complètes ou à jour. Le contenu de ce site est fourni à titre indicatif uniquement et ne devrait pas constituer votre seule source d’information pour prendre des décisions, sans consulter au préalable des sources d’information plus exactes, plus complètes et actualisées. Si vous décidez de vous fier au contenu présenté sur ce site, vous le faites à votre propre risque.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
          Ce site pourrait contenir certaines informations antérieures. Ces informations antérieures, par nature, ne sont pas à jour et sont fournies à titre indicatif seulement. Nous nous réservons le droit de modifier le contenu de ce site à tout moment, mais nous n’avons aucune obligation de mettre à jour les informations sur notre site. Vous acceptez qu’il vous incombe de surveiller les modifications apportées à notre site.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 4 – MODIFICATIONS APPORTÉES AU SERVICE ET AUX PRIX
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            {' '}
            Les prix de nos produits peuvent être modifiés sans préavis.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            {' '}
            Nous nous réservons le droit à tout moment de modifier ou d’interrompre le Service (ainsi que toute partie ou tout contenu du Service) sans préavis et en tout temps.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            {' '}
            Nous ne serons pas responsables envers vous ou toute autre tierce partie de toute modification de prix, suspension ou interruption du Service.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 5 – PRODUITS OU SERVICES (le cas échéant){' '}
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Certains produits ou services peuvent être exclusivement disponibles en ligne sur notre site web. Ces produits ou services peuvent être disponibles en quantités limitées et peuvent uniquement faire l’objet de retours ou d’échanges conformément à notre Politique de Retour.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Nous avons fait de notre mieux pour afficher aussi clairement que possible les couleurs et images de nos produits qui apparaissent sur notre boutique. Nous ne pouvons pas garantir que l’affichage des couleurs par l’écran de votre ordinateur sera précis.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Nous nous réservons le droit, sans toutefois être obligés de le faire, de limiter les ventes de nos produits ou services à toute personne, et dans toute région géographique ou juridiction. Nous pourrions exercer ce droit au cas par cas. Nous nous réservons le droit de limiter les quantités de tout produit ou service que nous offrons. Toutes les descriptions de produits et tous les prix des produits peuvent être modifiés en tout temps sans avis préalable, à notre seule discrétion. Nous nous réservons le droit d’arrêter d’offrir un produit à tout moment. Toute offre de service ou de produit présentée sur ce site est nulle là où la loi l’interdit.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Nous ne garantissons pas que la qualité de tous les produits, services, informations, ou toute autre marchandise que vous avez obtenue ou achetée répondra à vos attentes, nique toute erreur dans le Service sera corrigée.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 6 – EXACTITUDE DE LA FACTURATION ET DES INFORMATIONS DE COMPTE
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            {' '}
            Nous nous réservons le droit de refuser toute commande que vous passez auprès de nous. Nous pourrions, à notre seule discrétion, réduire ou annuler les quantités achetées par personne, par foyer ou par commande. Ces restrictions pourraient inclure des commandes passées par ou depuis le même compte client, la même carte de crédit, et/ou des commandes qui utilisent la même adresse de facturation et/ou d’expédition. Dans le cas où nous modifierions une commande ou si nous venions à l’annuler, nous pourrions tenter de vous avertir en vous contactant à l’e-mail et/ou à l’adresse de facturation/au numéro de téléphone fourni au moment où la commande a été passée. Nous nous réservons le droit de limiter ou d’interdire les commandes qui, à notre seul jugement, pourraient sembler provenir de marchands, de revendeurs ou de distributeurs.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            {' '}
            Vous acceptez de fournir des informations de commande et de compte à jour, complètes et exactes pour toutes les commandes passées sur notre boutique. Vous vous engagez à mettre à jour rapidement votre compte et vos autres informations, y compris votre adresse mail, vos numéros de cartes de crédit et dates d’expiration, pour que nous puissions compléter vos transactions et vous contacter si nécessaire.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            {' '}
            Pour plus de détails, veuillez consulter notre Politique de Retour.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 7 – OUTILS FACULTATIFS
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Nous pourrions vous fournir l’accès à des outils de tierces parties sur lesquels nous n’exerçons ni suivi, ni contrôle, ni influence.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Vous reconnaissez et acceptez le fait que nous fournissons l’accès à de tels outils "tels quels" et "selon la disponibilité", sans aucune garantie, représentation ou condition d’aucune sorte et sans aucune approbation. Nous n’aurons aucune responsabilité légale résultant d’ouliée à l’utilisation de ces outils facultatifs de tiers.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Si vous utilisez les outils facultatifs offerts sur le site, vous le faites à votre propre risque et à votre propre discrétion, et vous devriez consulter les conditions auxquelles ces outils sont offerts par le ou les fournisseurs tiers concernés (s).
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Nous pourrions aussi, à l’avenir, offrir de nouveaux services et/ou de nouvelles fonctionnalités sur notre site (incluant de nouveaux outils et de nouvelles ressources). Ces nouvelles fonctionnalités et ces nouveaux services seront également assujettis à ces Conditions Générales de Vente et d’Utilisation.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 8 – LIENS DE TIERS
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Certains contenus, produits et services disponibles par le biais de notre Service pourraient inclure des éléments provenant de tierces parties.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Les liens provenant de tierces parties sur ce site pourraient vous rediriger vers des sites web de tiers qui ne sont pas affiliés à nous. Nous ne sommes pas tenus d’examiner ou d’évaluer le contenu ou l’exactitude de ces sites, et nous ne garantissons pas et n’assumons aucune responsabilité quant à tout contenu, site web, produit, service ou autre élément accessible sur ou depuis ces sites tiers.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Nous ne sommes pas responsables des préjudices ou dommages liés à l’achat ou à l’utilisation de biens, de services, de ressources, de contenu, ou de toute autre transaction effectuée en rapport avec ces sites web de tiers. Veuillez lire attentivement les politiques et pratiques des tierces parties et assurez-vous de bien les comprendre avant de vous engager dans toute transaction. Les plaintes, réclamations, préoccupations, ou questions concernant les produits de ces tiers doivent être soumises à ces mêmes tiers.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 9 – COMMENTAIRES, SUGGESTIONS ET AUTRES PROPOSITIONS D’UTILISATEURS
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Si, à notre demande, vous soumettez des contenus spécifiques (par exemple, pour participer à des concours), ou si sans demande de notre part, vous envoyez des idées créatives, des suggestions, des propositions, des plans ou d’autres éléments, que ce soit en ligne, par e-mail, par courrier, ou autrement (collectivement, "commentaires"), vous nous accordez le droit, en tout temps, et sans restriction, d’éditer, de copier, de publier, de distribuer, de traduire et d’utiliser autrement et dans tout média tout commentaire que vous nous envoyez. Nous ne sommes pas et ne devrons pas être tenus (1) de maintenir la confidentialité des commentaires; (2) de payer une compensation à quiconque pour tout commentaire fourni; (3) de répondre aux commentaires.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Nous pourrions, mais n’avons aucune obligation de le faire, surveiller, modifier ou supprimer le contenu que nous estimons, à notre seule discrétion, être illégal, offensant, menaçant, injurieux, diffamatoire, pornographique, obscène ou autrement répréhensible, ou qui enfreint toute propriété intellectuelle à ces Conditions Générales de Vente et d’Utilisation.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Vous vous engagez à écrire des commentaires qui ne violent pas les droits de tierces parties, y compris les droits d’auteur, les marques déposées, la confidentialité, la personnalité, ou d’autres droits personnels ou de propriété. Vous convenez également que vos commentaires ne contiendront pas de contenu illégal, diffamatoire, offensif ou obscène, et qu’ils ne contiendront non plus pas de virus informatique ou d’autres logiciels malveillants qui pourraient affecter de quelque manière que ce soit le fonctionnement du service ou tout autre site web associé. Vous ne pouvez pas utiliser de fausse adresse e-mail, prétendre être quelqu’un que vous n’êtes pas, ou essayer de nous induire nous et/ou les tierces parties en erreur quant à l’origine de vos commentaires. Vous êtes entièrement responsable de tous les commentaires que vous publiez ainsi que de leur exactitude. Nous n’assumons aucune responsabilité et déclinons tout engagement quant à tout commentaire que vous publiez ou que toute autre tierce partie publie.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 10 – RENSEIGNEMENTS PERSONNELS
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            La soumission de vos renseignements personnels sur notre boutique est régie par notre Politique de Confidentialité. Cliquez ici pour consulter notre Politique de Confidentialité.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 11 – ERREURS, INEXACTITUDES ET OMISSIONS
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Il se pourrait qu’il y ait parfois des informations sur notre site ou dans le Service qui pourraient contenir des erreurs typographiques, des inexactitudes ou des omissions qui pourraient être relatives aux descriptions de produits, aux prix, aux promotions, aux offres, aux frais d’expédition des produits, aux délais de livraison et à la disponibilité. Nous nous réservons le droit de corriger toute erreur, inexactitude, omission, et de changer ou de mettre à jour des informations ou d’annuler des commandes, si une quelconque information dans le Service ou surtout autre site web associé est inexacte, et ce, en tout temps et sans préavis (y compris après que vous ayez passé votre commande).
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Nous ne sommes pas tenus de mettre à jour, de modifier ou de clarifier les informations dans le Service ou sur tout autre site web associé, incluant mais ne se limitant pas aux informations sur les prix, sauf si requis par la loi. Aucune date définie de mise à jour ou d’actualisation dans le Service ou sur tout autre site web associé ne devrait être prise en compte pour conclure que les informations dans le Service ou sur tout autre site web associé ont été modifiées ou mises à jour.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 12 – UTILISATIONS INTERDITES
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            En plus des interdictions énoncées dans les Conditions Générales de Vente et d’Utilisation, il vous est interdit d’utiliser le site ou son contenu: (a) à des fins illégales; (b) pour inciter des tiers à réaliser des actes illégaux ou à y prendre part; (c) pour enfreindre toute ordonnance régionale ou toute loi, règle ou régulation internationale, fédérale, provinciale ou étatique; (d) pour porter atteinte à/ou violer nos droits de propriété intellectuelle ou ceux de tierces parties; (e) pour harceler, maltraiter, insulter, blesser, diffamer, calomnier, dénigrer, intimider ou discriminer quiconque en fonction du sexe, de l’orientation sexuelle, de la religion, de l’origine ethnique, de la race, de l’âge, de l’origine nationale, ou d’un handicap; (f) pour soumettre des renseignements faux ou trompeurs; (g) pour téléverser ou transmettre des virus ou tout autre type de code malveillant qui sera ou pourrait être utilisé de manière à compromettre la fonctionnalité ou le fonctionnement du Service ou de tout autre site web associé, indépendant, ou d’Internet; (h) pour recueillir ou suivre les renseignements personnels d’autrui; (i) pour polluposteur, hameçonner, détourner un domaine, extorquer des informations, parcourir, explorer ou balayer le web (ou toute autre ressource); (j) à des fins obscènes ou immorales; ou (k) pour porter atteinte ou contourner les mesures de sécurité de notre Service, de tout autre site web, ou d’Internet. Nous nous réservons le droit de résilier votre utilisation du Service ou de tout site web connexe pour avoir enfreint les utilisations interdites.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 13 – EXCLUSION DE GARANTIES ET LIMITATION DE RESPONSABILITÉ
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Nous ne garantissons ni ne prétendons en aucun cas que votre utilisation de notre Service sera ininterrompue, rapide, sécurisée ou sans erreur.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Nous ne garantissons pas que les résultats qui pourraient être obtenus par le biais de l’utilisation du Service soient exacts ou fiables.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Vous acceptez que de temps à autre, nous puissions supprimer le Service pour des périodes de temps indéfinies ou annuler le Service à tout moment, sans vous avertir au préalable.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Vous convenez expressément que votre utilisation du Service, ou votre incapacité à utiliser celui-ci, est à votre seul risque. Le Service ainsi que tous les produits et services qui vous sont fournis par le biais du Service sont (sauf mention express du contraire de notre part) fournis "tels quels" et "selon la disponibilité" pour votre utilisation, et ce sans représentation, sans garanties et sans conditions d'aucune sorte, expresses ou implicites, y compris toutes les garanties implicites de commercialisation ou de qualité marchande, d’adaptation à un usage particulier, de durabilité, de titre et d’absence de contrefaçon.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            <strong>Alfa Print</strong>, nos directeurs, responsables, employés, sociétés affiliées, agents, contractants, stagiaires, fournisseurs, prestataires de services et concédants ne peuvent en aucun cas être tenus responsables de toute blessure, perte, réclamation, ou de dommages directs, indirects, accessoires, punitifs, spéciaux, ou dommages consécutifs de quelque nature qu’ils soient, incluant mais ne se limitant pas à la perte de profits, de revenus, d’économies, de données, aux coûts de remplacement ou tous dommages similaires, qu’ils soient contractuels, délictuels (même en cas de négligence), de responsabilité stricte ou autre, résultant de votre utilisation de tout service ou produit provenant de ce Service, ou quant à toute autre réclamation liée de quelque manière que ce soit à votre utilisation du Service ou de tout produit, incluant mais ne se limitant à toute erreur ou omission dans tout contenu, ou à toute perte ou tout dommage de toute sorte découlant de l’utilisation du Service ou de tout contenu (ou produit) publié, transmis, ou autrement rendu disponible par le biais du Service, même si vous avez été avertis de la possibilité qu’ils surviennent. Certains États ou certaines juridictions ne permettent pas d’exclure ou de limiter la responsabilité quant aux dommages consécutifs ou accessoires, notre responsabilité sera limitée dans la mesure maximale permise par la loi.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 14 – INDEMNISATION
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Vous acceptez d’indemniser, de défendre et de protéger Alfa Print SASU, notre société-mère, nos filiales, sociétés affiliées, partenaires, responsables, directeurs, agents, contractants, concédants, prestataires de services, sous-traitants, fournisseurs, stagiaires et employés, quant à toute réclamation ou demande, incluant les honoraires raisonnables d’avocat, faite par toute tierce partie à cause de ou découlant de votre violation de ces Conditions Générales de Vente et d’Utilisation ou des documents auxquels ils font référence, ou de votre violation de toute loi ou des droits d’un tiers.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 15 – DISSOCIABILITÉ
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Dans le cas où une disposition des présentes Conditions Générales de Vente et d’Utilisation serait jugée comme étant illégale, nulle ou inapplicable, cette disposition pourra néanmoins être appliquée dans la pleine mesure permise par la loi, et la partie non applicable devra être considérée comme étant dissociée de ces Conditions Générales de Vente et d’Utilisation, cette dissociation ne devra pas affecter la validité et l’applicabilité de toutes les autres dispositions restantes.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 16 – RÉSILIATION
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Les obligations et responsabilités engagées par les parties avant la date de résiliation resteront en vigueur après la résiliation de cet accord et ce à toutes les fins.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Ces Conditions Générales de Vente et d’Utilisation sont effectives à moins et jusqu’à ce qu’elles soient résiliées par vous. Vous pouvez résilier ces Conditions Générales de Vente et d’Utilisation à tout moment en nous avisant que vous ne souhaitez plus utiliser nos Services, ou lorsque vous cessez d’utiliser notre site.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Si nous jugeons, à notre seule discrétion, que vous échouez, ou si nous soupçonnons que vous avez été incapable de vous conformer aux modalités de ces Conditions Générales de Vente et d’Utilisation, nous pourrions aussi résilier cet accord à tout moment sans vous prévenir à l’avance et vous resterez responsable de toutes les sommes redevables jusqu’à la date de résiliation (celle-ci étant incluse), et/ou nous pourrions vous refuser l’accès à nos Services (ou à toute partie de ceux-ci).
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 17 – INTÉGRALITÉ DE L’ACCORD
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Tout manquement de notre part à l’exercice ou à l’application de tout droit ou de toute disposition des présentes Conditions Générales de Vente et d’Utilisation ne devrait pas constituer une renonciation à ce droit ou à cette disposition.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Ces Conditions Générales de Vente et d’Utilisation ou toute autre politique ou règle d’exploitation que nous publions sur ce site ou relativement au Service constituent l’intégralité de l’entente et de l’accord entre vous et nous et régissent votre utilisation du Service, et remplacent toutes les communications, propositions et tous les accords, antérieurs et contemporains, oraux ou écrits, entre vous et nous (incluant, mais ne se limitant pas à toute version antérieure des Conditions Générales de Vente et d’Utilisation).
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Toute ambiguïté quant à l’interprétation de ces Conditions Générales de Vente et d’Utilisation ne doit pas être interprétée en défaveur de la partie rédactrice.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 18 – LOI APPLICABLE
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
          Ces Conditions Générales de Vente et d’Utilisation, ainsi que tout autre accord séparé par le biais duquel nous vous fournissons des Services seront régis et interprétés en vertu des lois en vigueur à <strong>4120 RTE DE TOURNAI, DOUAI, 59500, France.</strong>
          Pour tout litige, le tribunal compétent est celui de Douai.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 19 – MODIFICATIONS APPORTÉES AUX CONDITIONS GÉNÉRALES DE VENTE ET D’UTILISATION
          </h3>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
          Vous pouvez consulter la version la plus récente des Conditions Générales de Vente et d’Utilisation à tout moment sur cette page.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Nous nous réservons le droit, à notre seule discrétion, de mettre à jour, de modifier ou de remplacer toute partie de ces Conditions Générales de Vente et d’Utilisation en publiant les mises à jour et les changements sur notre site. Il vous incombe de visiter notre site régulièrement pour vérifier si des changements ont été apportés. Votre utilisation continue sur notre site après la publication de toute modification apportée à ces Conditions Générales de Vente et d’Utilisation constitue une acceptation de ces modifications.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '21px',
              textDecoration:"underline"
            }}
          >
            ARTICLE 20 – COORDONNÉES
          </h3>
          <ul className="ulComp">
          <li>Alfa Print SASU</li>
          <li>4120 RTE DE TOURNAI</li>
          <li>59500 DOUAI</li>
          <li>Tel: 00 33(0) 9 82 54 78 30</li>
          <li>Mail: info@alfaprint.fr</li>
          <li>Numéro SIRET (siège): 81295512800036</li>
          <li>Numéro SIREN: 812955128</li>
          <li>Numéro TVA Intracommunautaire: FR22812955128</li>

          </ul>
          <p className="pComp">
          Pour tout retour de produit autorisé, merci de retourner le produit à Alfa Print , 4120 route de Tournai, 59500 Douai.  Pour plus de détails, veuillez consulter notre Politique de Retour.
          </p>

          <br />
          </div>
        </div>
      </div>
    )}
    {popupcookies && (
      <div className='popup-conditions'>
        <div
          className='bg-popup'
          onClick={() => {
            setpopupcookies(false);
          }}
        ></div>
        <div className='conditions-container'>
          <div
            id='swal2-content'
            style={{ display: 'block', width:'90%',marginLeft:'5%' }}
          >
          <h1 className='h1Comp'>Que représentent les cookies ?</h1>

          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Les <strong>cookies</strong> sont des fichiers stockés sur votre
            <strong> ordinateur</strong> quand vous vous rendez sur certaines
            pages web. Très souvent, lorsque vous allez sur un site, on vous
            propose deux options. Vous avez le choix de choisir que l’on utilise
            vos cookies ou pas. Il faut savoir que les cookies nous permettent
            de personnaliser vos services. En fonction des différentes pages que
            vous visitez, nous allons vous proposer des produits adaptés à vos
            recherches. C’est avantageux pour vous, car nous faisons les
            recherches à votre place et vous n’avez qu’à choisir en fonction des
            différentes propositions.
          </p>
          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Cependant, nous tenons à vous rassurer <strong>Alfa Print</strong> ,
            ne sauvegarde en aucun cas vos données personnelles telles que votre
            carte de crédit dans les cookies. Les cookies vont nous permettre
            d’analyser le temps que vous passez sur une page.
          </p>
          <h2
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '23px',
            }}
          >
            Politique sur les cookies :
          </h2>

          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Les différents <strong>cookies</strong> que nous utilisons sur notre
            site sont :
          </p>
          <ul className='ulComp'>
            <li>
              Les cookies de ciblage : nous utilisons les cookies de ciblage,
              car ils nous permettent d’analyser la façon dont vous visitez le
              site. Ça nous permet de savoir sur quoi vous vous dirigez dès
              votre arrivée sur le site, vos préférences. Grâce à cela, nous
              pourrons rendre notre site plus promotionnel et adapté à vos
              besoins.
            </li>
            <li>
              Les cookies analytiques : ils représentent les outils qui vont
              nous permettre d’améliorer la conception et la fonctionnalité de
              notre site internet. Nous allons recueillir des données sur les
              différents sites que vous avez l’habitude de visiter. Par la
              suite, nous allons pouvoir, vous proposez une meilleure ergonomie
              de notre site.
            </li>
            <li>
              Les cookies fonctionnels : nous utilisons les cookies
              fonctionnels, car ils nous permettent de personnaliser votre
              visite sur notre site internet. Lors de votre arrivée sur le site,
              on vous montrera les différents articles que vous avez consultés
              lors de votre dernière visite. Les articles qui ont été mis dans
              vos paniers, ça va vous permettre de continuer votre achat. Vous
              pouvez être sûr qu’avec les cookies fonctionnels, vous n’aurez
              plus l’impression d’avoir perdu vos recherches.
            </li>
          </ul>
          <h2
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '23px',
            }}
          >
            Comment gérer les cookies ?
          </h2>

          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Nous allons vous redéfinir les différentes options pour pouvoir
            gérer les cookies. Il faut savoir que les cookies ne sont pas
            obligatoires, si cela ne vous intéresse plus, vous aurez la
            possibilité de désactiver l’option. Néanmoins, après votre
            désactivation, nous ne pourrons plus vous proposer directement le
            matériel nécessaire adapté à votre recherche.
          </p>

          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '2rem',
            }}
          >
            • Si vous donnez votre accord :
          </h3>

          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            {' '}
            Lorsque vous donnez votre accord pour les cookies, les différents
            navigateurs que vous utilisez dans votre terminal donnent accès a
            l’enregistrement de vos cookies. Les Cookies des différentes pages
            et contenus que vous consultés seront automatiquement enregistrés
            sur votre terminal. Les différents cookies vont permettre de vous
            proposer à chaque fois du contenu qui pourrait vous intéresser et
            donner envie d’acheter. Les cookies donnent accès à une
            personnalisation instantanée des produits que vous pourriez aimer.
          </p>
          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '2rem',
            }}
          >
            • Si vous ne donnez pas votre accord :
          </h3>

          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            Lorsque vous refusez de partager vos cookies, vous avez deux options
            :
          </p>
          <ul className='ulComp'>
            <li>
              Quand vous êtes sur une page, refuser directement en cliquant « je
              refuse », ça va empêcher à la page ou au serveur sur lequel vous
              êtes d’enregistrer vos données. Dans ce cas, il faut savoir qu’à
              chaque fois que vous allez vous rendre sur une page, vous recevrez
              toujours la demande de sauvegarder vos cookies ou pas.
            </li>
            <li>
              Vous pouvez paramétrer votre navigateur de sorte à refuser
              directement toute sauvegarde des cookies. Néanmoins, il faut
              savoir que le refus des cookies peut modifier votre navigation sur
              Internet et la possibilité d’accéder à certains privilèges,
              services.{' '}
            </li>
          </ul>

          <h3
            className='h3Comp'
            style={{
              color: '#005d86',
              fontWeight: '600',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '2rem',
            }}
          >
            • Si vous supprimez l’accès à vos cookies :
          </h3>

          <p className='pComp' style={{ textAlign: 'left' ,marginBottom:'10px'}}>
            {' '}
            Lorsque vous supprimez l’accès à vos cookies, nous ne pouvons plus
            vous proposer des services entièrement personnalisés sans avoir à
            vous consulter au préalable. Nous ne serons pas responsables de la
            possible dégradation de nos services. Les cookies permettent aussi
            un bon fonctionnement du site, car nous avons la possibilité de voir
            ce que vous aimez, les différents sites que vous visitez. De plus,
            en fonction de ça, nous avons la possibilité de vous proposer des
            services utiles à vos recherches.
          </p>
          <br />
          <p className='pComp' style={{ textAlign: 'left', fontWeight: '600' }}>
            {' '}
            NB : Les cookies représentent un avantage pour vous, meilleure
            navigation sur le site, présentation de services adaptés à vos
            recherches sans avoir à contacter directement l’entreprise. Si vous
            trouvez que les cookies après utilisation, ne représentent aucun
            intérêt pour vous, vous avez la possibilité de vous rétracter en
            prenant en considération la rubrique «Si vous supprimez l’accès à
            vos cookies ».
          </p>

          <br />
          </div>
        </div>
      </div>
    )}
      <footer className='footer bg-dark container-fluid'>

        <div className='footer-middle'>
          <div className='container'>
            {/* End .footer-ribbon */}
            <div className='row'>
              <div className='col-lg-3 col-md-4'>
                <div className='widget'>
                  <p className='widget-title'>Informations </p>
                  <ul className='contact-info'>
                    <li>
                      <span className='contact-info-label'>Adresse</span>4120 Rte de Tournai, 59500 Douai, France
                    </li>
                    <li>
                      <span className='contact-info-label'>Telephone</span>Appel
                      gratuit <a href='tel:'>(+33)9 82 54 78 30</a>
                    </li>
                    <li>
                      <span className='contact-info-label'>Email</span>{' '}
                      <a href='mailto:mail@example.com'>
                        contact@alfaprint.fr{' '}
                      </a>
                    </li>
                  </ul>
                  <div className='social-icons' style={{ display: 'flex' }}>
                    <a
                      href='https://www.instagram.com/alfaprint.fr/'
                      className='social-icon social-instagram icon-instagram'
                      target='_blank'
                      title='Instagram'
                      rel='noopener'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <svg className='icon-down' style={{ fill: 'white' }}>
                        <use xlinkHref='assets/icons.svg#icon-instagram' />
                      </svg>
                    </a>
                    <a
                      href='https://twitter.com/AlfaPrint01'
                      className='social-icon social-twitter icon-twitter'
                      target='_blank'
                      title='Twitter'
                      rel='noopener'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <svg className='icon-down' style={{ fill: 'white' }}>
                        <use xlinkHref='assets/icons.svg#icon-twitter' />
                      </svg>
                    </a>
                    <a
                      href='https://www.facebook.com/www.alfaprint.fr/'
                      className='social-icon social-facebook icon-facebook'
                      target='_blank'
                      title='Facebook'
                      rel='noopener'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <svg className='icon-down' style={{ fill: 'white' }}>
                        <use xlinkHref='assets/icons.svg#icon-facebook' />
                      </svg>
                    </a>
                  </div>

                  {/* End .social-icons */}
                </div>
                {/* End .widget */}
              </div>
              {/* End .col-lg-3 */}
              <div className='col-lg-9 col-md-8'>
                <div className='widget widget-newsletter'>
                  <p className='widget-title'>S'inscrire a la newsletter</p>
                  <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                      <p>
                        Inscrivez-vous à notre newsletter
                        <br /> pour recevoir nos meilleures offres !
                      </p>
                    </div>
                    {/* End .col-lg-6 */}
                    <div className='col-lg-6 col-md-12'>
                      <form
                        action='#'
                        className='d-flex mb-0 w-100'
                        onSubmit={handleSubmit(subscribe)}
                      >
                        <input
                          type='email'
                          className='form-control mb-0'
                          placeholder='Adresse mail'
                          required
                          name='email'
                          ref={register}
                        />
                        <input
                          type='submit'
                          className='btn btn-primary shadow-none'
                          defaultValue="S'inscrire"
                        />
                      </form>
                    </div>
                    {popup == true && (
              <div className="popup-succes">
                <div className="popup-succes-container">
                  <svg
                    className="popup-succes-icon"
                    style={{ marginTop: "3rem" }}
                  >
                    <use xlinkHref="assets/icons.svg#icon-verified_user" />
                  </svg>
                  <div
                    className="popup-succes-text"
                    style={{ marginTop: "10rem" }}
                  >
                Email Envoyé <br />
                
                  </div>

                  <div className="popup-succes-text2">
                    {/* <button
                className='btn btn-light'
                style={{ padding: '.2rem 2rem', color: '#4caf50' }}
                onClick={gotocart}
              >
                Continuer
              </button> */}
                  </div>
                </div>
              </div>
            )}
                    {/* End .col-lg-6 */}
                  </div>
                  {/* End .row */}
                </div>


                {/* End .widget */}
                <div className='row'>
                  <div className='col-sm-6'>
                    <div className='widget'>
                      <p className='widget-title'>Nos Produits</p>
                      <ul className='links link-parts row mb-0'>
                        <div className='link-part col-lg-6 col-md-12'>
                          <li>
                            <Link to='/imprimante-multifonction/'>
                              Imprimantes multifonctions
                            </Link>
                          </li>
                          <li>
                            <Link to='/imprimante-jet-d-encre/'>
                              Imprimantes jet d'encre
                            </Link>
                          </li>
                          <li>
                            <Link to='/imprimante-laser/'>
                              Imprimantes Laser
                            </Link>
                          </li>
                          <li>
                            <Link to='/contrat/'>Impression illimitée</Link>
                          </li>
                          <li>
                            <Link to='/demande-devis/'>Demander un devis</Link>
                          </li>
                        </div>
                        <div className='link-part col-lg-6 col-md-12'>
                          <li>
                            <Link to='/consommable/toner/'>Toners</Link>
                          </li>
                          <li>
                            <Link to='/consommable/cartouche/'>Cartouches</Link>
                          </li>
                          <li>
                            <Link to='/consommable/ruban/'>Rubans</Link>
                          </li>
                          <li><Link to='/connecter/'>Se connecter</Link></li>
                          <li><Link to="/blog/"> Blog</Link></li>
                          </div></ul>
                          </div>
                          </div>
                          <div className="col-sm-6">
                          <div className="widget">
                          <ul className="links link-parts row mb-0">
                          <div className="link-part col-lg-6 col-md-12">
                          <p className="widget-title">Infos pratiques</p>
                          <li><a href='#' onClick={(e) => {e.preventDefault()
                            setpopupcgv(true);
                          }} >CGV</a></li>
                          {/* <li><Link to="/privacy-policy/">CGV</Link></li> */}
                          <li><a href='#' onClick={(e) => { e.preventDefault()
                            setpopuppr(true);
                          }} >Politique de retour</a></li>
                          <li><a href='#' onClick={(e) => { e.preventDefault()
                            setpopupml(true);
                          }}>Mentions légales</a></li>
                          <li><a href='#' onClick={(e) => { e.preventDefault()
                            setpopupcookies(true);
                          }}>Vie privée et cookies</a></li>
                          </div><div className="link-part col-lg-6 col-md-12">
                          <p className="widget-title">À propos</p><li><Link to="/a-propos/">À propos</Link></li>
                          <li> <Link to="/contactez-nous/">Contactez-nous</Link></li><li><Link to="/faq/">FAQ</Link></li>
                          </div></ul>
                          </div></div></div></div></div></div></div>
                          <div className="container">
                          <div className="footer-bottom d-flex justify-content-between align-items-center flex-wrap">
                          <p className="footer-copyright py-3 pr-4 mb-0">© Alfaprint 2021. Tous droits réservés</p>
                          <img src="assets/imagev2/visa.webp" alt="paiement" style={{width: '48rem'}} /></div></div></footer>
                            </div>
                          );
                          }
                          export default Footer;
