// import './App.css';
import Navbar from './components/navbar';
import { Route, Switch, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import { useState, useEffect, createContext } from 'react';
import Acceuil from './view/acceuil/acceuil.js';
import LandingPage from './view/landingpage/landingPage';
import PrivacyPolicy from './view/privacy/privacy-policy';
import NavbarMobile from './components/navbarmobile';
import Footer from './components/footer';
import './bootstrap.min.css';
import './App.css';
import { ThingsProvider } from './context';
// import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";

// const TRACKING_ID = "G-BSEK0C0D7R";
// ReactGA.initialize(TRACKING_ID);

const Faq = loadable(() =>
  import(/* webpackChunkName: "faq" */ './view/faq/faq')
);

const Myaccount = loadable(() =>
  import(/* webpackChunkName: "myaccount" */ './view/myaccount/myaccount')
);

const Abonnement = loadable(() =>
  import(/* webpackChunkName: "abonnement" */ './view/abonnement')
);

const Singelarticle = loadable(() =>
  import(/* webpackChunkName: "article" */ './view/singelarticle')
);

const _404 = loadable(() =>
  import(/* webpackChunkName: "_404" */ './view/404/404')
);
const Failed_payment = loadable(() =>
  import(/* webpackChunkName: "failed" */ './view/404/failed')
);

const Success_payment = loadable(() =>
  import(/* webpackChunkName: "succes" */ './view/404/succes')
);

const Imprimante = loadable(() =>
  import(/* webpackChunkName: "imprimante" */ './view/imprimante')
);
const Imprimantenoref = loadable(() =>
  import(/* webpackChunkName: "imprimante" */ './view/imprimantenoref')
);
const Consommablesnoref = loadable(() =>
  import(/* webpackChunkName: "imprimante" */ './view/consomablesnoref')
);
const Consommables = loadable(() =>
  import(/* webpackChunkName: "consomables" */ './view/consomables')
);
const Consommable = loadable(() =>
  import(/* webpackChunkName: "consomable" */ './view/consomable/consomable')
);
const Demandedevis = loadable(() =>
  import(
    /* webpackChunkName: "demandedevice" */ './view/deamndedevis/demandedevice'
  )
);
const Contrat = loadable(() =>
  import(/* webpackChunkName: "contrat" */ './view/contrat/contrat')
);
const Contratgoads = loadable(() =>
  import(/* webpackChunkName: "contrat" */ './view/contrat/contratgoads')
);
const Contratecom = loadable(() =>
  import(/* webpackChunkName: "contrat" */ './view/contrat/contratecom')
);
const Contratemailing = loadable(() =>
  import(/* webpackChunkName: "contrat" */ './view/contrat/contratemailing')
);

const Bobinethermique = loadable(() =>
  import(/* webpackChunkName: "bobine" */ './view/consomable/bobinethermique')
);
const Ruban = loadable(() =>
  import(/* webpackChunkName: "ruban" */ './view/consomable/ruban')
);
const Toner = loadable(() =>
  import(/* webpackChunkName: "toner" */ './view/consomable/toner')
);
const Recherche = loadable(() =>
  import(/* webpackChunkName: "toner" */ './view/consomable/rechercheAvance')
);

const Cartouche = loadable(() =>
  import(/* webpackChunkName: "cartouche" */ './view/consomable/cartouche')
);

const Login = loadable(() =>
  import(/* webpackChunkName: "login" */ './view/login/login')
);
const LoginUser = loadable(() =>
  import(/* webpackChunkName: "login" */ './view/login/loginUser')
);
const RegisterUser = loadable(() =>
  import(/* webpackChunkName: "login" */ './view/register/register')
);

const Imprimanteshp = loadable(() =>
  import(/* webpackChunkName: "hp" */ './view/touteslescategories/marque/hp')
);
const Imprimantesbrother = loadable(() =>
  import(
    /* webpackChunkName: "brother" */ './view/touteslescategories/marque/brother'
  )
);
const Imprimanteslexmark = loadable(() =>
  import(
    /* webpackChunkName: "lexmark" */ './view/touteslescategories/marque/lexmark'
  )
);
const Imprimantessamsung = loadable(() =>
  import(
    /* webpackChunkName: "samsung" */ './view/touteslescategories/marque/samsung'
  )
);
const Promotion = loadable(() =>
  import(
    /* webpackChunkName: "promotion" */ './view/touteslescategories/promotions/promotion'
  )
);
const Imprimantesepson = loadable(() =>
  import(
    /* webpackChunkName: "epson" */ './view/touteslescategories/marque/epson'
  )
);
const Imprimantesricoh = loadable(() =>
  import(
    /* webpackChunkName: "ricoh" */ './view/touteslescategories/marque/ricoh'
  )
);

const RechercheAdvanced = loadable(() =>
  import(
    './components/lister/RechercheAdvanced'
  )
);
const Imprimantescanon = loadable(() =>
  import('./view/touteslescategories/marque/canon')
);
const Imprimanteskyocera = loadable(() =>
  import(
    /* webpackChunkName: "kyocera" */ './view/touteslescategories/marque/kyocera'
  )
);
const Imprimantesoki = loadable(() =>
  import(/* webpackChunkName: "oki" */ './view/touteslescategories/marque/oki')
);
const Imprimantesxerox = loadable(() =>
  import(
    /* webpackChunkName: "xerox" */ './view/touteslescategories/marque/xerox'
  )
);
const Jetdencre = loadable(() =>
  import(
    /* webpackChunkName: "jetdencre" */ './view/touteslescategories/jetdencre/jetdencre'
  )
);
const Multifonction = loadable(() =>
  import(
    /* webpackChunkName: "multifonction" */ './view/touteslescategories/multifonctions/multifonctions'
  )
);
const Laser = loadable(() =>
  import(
    /* webpackChunkName: "laser" */ './view/touteslescategories/laser/laser'
  )
);
const Checkout = loadable(() =>
  import(/* webpackChunkName: "checkout" */ './view/checkout/checkout')
);

const Traceur = loadable(() =>
  import(
    /* webpackChunkName: "traceur" */ './view/touteslescategories/traceur/traceur'
  )
);
const PrinterConsomables = loadable(() =>
  import(/* webpackChunkName: "PrinterConsomables" */ './view/consomable/printerConsomables')
);
const Etiquette = loadable(() =>
  import(
    /* webpackChunkName: "etiquette" */ './view/touteslescategories/imprimanteEtiquette/etiquette'
  )
);
const Pointdevente = loadable(() =>
  import(
    /* webpackChunkName: "pointdevente" */ './view/touteslescategories/impirmantepointdevente/pointdevente'
  )
);
const Aboutus = loadable(() =>
  import(/* webpackChunkName: "aboutus" */ './view/aboutus/aboutus')
);

const Contactus = loadable(() =>
  import(/* webpackChunkName: "contactus" */ './view/contactus/contactus')
);
const Blog = loadable(() =>
  import(/* webpackChunkName: "blog" */ './view/blog/blog')
);
const Cart = loadable(() =>
  import(/* webpackChunkName: "cart" */ './view/cart/cart')
);
const Virement = loadable(() =>
  import(/* webpackChunkName: "virement" */ './view/virement')
);
const Deconexion = loadable(() =>
  import(/* webpackChunkName: "deconnecter" */ './view/deconnecter')
);

function App() {
  const typeclient = {
    client: {
      adresse: '',
      company_id: '',
      cp: '',
      dataConnexion: '',
      email: '',
      fonction: '',
      gender: '',
      id: '',
      login: '',
      nom: '',
      prenom: '',
    },
    token: '',
  };
  const [pannier, setPannier] = useState([]);
  const [recherche, setRecherche] = useState([]);
  const [client, setclient] = useState(typeclient);
  const [rib, setrib] = useState('');
  const [ext, setExt] = useState('webp');
  const MyContext = createContext('webp');

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('copylist_Products'));

    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(
        !window['safari'] ||
        (typeof safari !== 'undefined' && window['safari'].pushNotification)
      );
    if (isSafari) {
      setExt('png');
    }

    if (data) {
      setPannier(data);
    }
    setTimeout(() => {
      const clienttest = localStorage.getItem('client');
      if (clienttest) {
        setclient(JSON.parse(clienttest));
      }
      if (window.innerWidth > 700) {
        if (!sessionStorage.fontsLoaded) {
          require(['webfontloader'], function (WebFont) {
            WebFont.load({
              google: {
                families: ['Montserrat:500,700&display=swap', 'sans-serif'],
              },
            });
            document.querySelector('body').style.fontFamily = 'Montserrat';
          });
        }
      }
    }, 1500);
    if (navigator.userAgent != 'ReactSnap') {
      setTimeout(() => {
        const script = document.createElement('script');

        script.src = '/assets/js/chat.js';
        script.async = true;
        document.body.appendChild(script);
      }, 6000);
    }
    // eslint-disable-next-line no-use-before-define
  }, []);
  function emptyCache() {
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });

      // Makes sure the page reloads. Changes are only visible after you refresh.
    }
  }
  const type = (url) => {
    return url.slice(0, url.indexOf('/'));
  };


  const addindex = (product) => {
    setPannier((prevState) => {
      if (prevState) {
        const copylist_Products = [...prevState];
        copylist_Products.map((item) => {
          if (item.product_id == product.product_id) {
            item.index = product.index;
          }
          return item;
        });

        // ajout=false=>incrementer le nombre de cette produit dans la liste

        setPannier(copylist_Products);
        localStorage.setItem(
          'copylist_Products',
          JSON.stringify(copylist_Products)
        );
      }
    });
  };
  const modifier_Pannier = (product, index) => {
    if (!product.index) {
      if (index) {
        product.index = index;
      } else {
        product.index = 1;
      }
    }
    if (type(product.url_fiche) == 'imprimantes') {
      product.vp = 'pr';
    } else if (type(product.url_fiche) == 'consommables') {
      product.vp = 'cn';
    }
    setPannier((prevState) => {
      let ajout = true;
      if (prevState) {
        const copylist_Products = [...prevState];
        copylist_Products.map((item) => {
          if (item.product_id == product.product_id) {
            if (index) {
              item.index = item.index + index;
            } else {
              item.index++;
            }
            ajout = false;
          }
          return item;
        });
        if (ajout) {
          //if ajout=true=> nouveau produit dans la list donc ajouter le produit dans la liste
          copylist_Products.push(product);
          setPannier(copylist_Products);
          localStorage.setItem(
            'copylist_Products',
            JSON.stringify(copylist_Products)
          );
        } else {
          setPannier(copylist_Products);
          localStorage.setItem(
            'copylist_Products',
            JSON.stringify(copylist_Products)
          );
        }
      } else {
        // ajout=false=>incrementer le nombre de cette produit dans la liste
        if (localStorage.getItem('copylist_Products')) {
          const copylist_Products = JSON.parse(
            localStorage.getItem('copylist_Products')
          );
          copylist_Products.push(product);
          localStorage.setItem(
            'copylist_Products',
            JSON.stringify(copylist_Products)
          );
        } else {
          const copylist_Products = [];
          setPannier(copylist_Products);
          localStorage.setItem(
            'copylist_Products',
            JSON.stringify(copylist_Products)
          );
        }
      }
    });
  };
  const connecter = (data) => {
    setclient(data);
  };
  const deconnecter = (data) => {
    localStorage.removeItem('client');
    setclient(typeclient);
    vider_Pannier();
  };

  const delete_From_Pannier = (id) => {
    let i;
    setPannier((prevState) => {
      if (prevState) {
        const list_Produit = [...prevState];
        list_Produit.map((item, index) => {
          if (item.product_id == id) {
            i = index;
          }
          return item;
        });

        list_Produit.splice(i, 1);

        setPannier(list_Produit);
        localStorage.setItem('copylist_Products', JSON.stringify(list_Produit));
      }
    });
  };
  const envoyer = (data) => {
    setrib(data);
  };
  const vider_Pannier = () => {
    setPannier([]);
    localStorage.removeItem('copylist_Products');
  };
  const send_Results = (data) => {
    setRecherche(data);
  };
  useEffect(() => {

  }, [pannier]);
  const [connecte, setConnecte] = useState(false);




  function connexion() {
    if (client.token === "") {
      setConnecte(false);
    }
    else {
      setConnecte(true)
    }
  }
  const token = localStorage.getItem('client');


  return (
    <div className='App'>
      <Switch>
        <Route path="/photocopieur/" exact>
          <LandingPage />
          <Footer />
        </Route>
        <Route path='/*'>
        <ThingsProvider value={ext}>
        <NavbarMobile></NavbarMobile>
        <Navbar
          send_Results={send_Results}
          deletfromepannier={delete_From_Pannier}
          pannier={pannier}
          client={client}
          deconnecter={deconnecter}
        />
        <div className='switch' id='switch'>
          <Switch>
            <Route path='/' exact>
              <Acceuil modifierpannier={modifier_Pannier} deletepannier={delete_From_Pannier} />
            </Route>
            <Route exact path='/imprimante/consomables/:id'>
              <PrinterConsomables modifierpannier={modifier_Pannier} />
            </Route>
            {/* <Route path='/Categorie' exact>
            <Categorihe
              modifierpannier={modifier_Pannier}
              recherches={recherche}
            />
          </Route> */}
            <Route path='/a-propos/' exact component={Aboutus}></Route>
            <Route path='/privacy-policy/' exact component={PrivacyPolicy}></Route>
            <Route path='/contactez-nous/' exact component={Contactus}></Route>
            <Route path='/blog/' exact component={Blog}></Route>

            <Route
              path='/blog/:nomarticle/'
              exact
              component={Singelarticle}
            ></Route>

            <Route path='/connecter/' exact>
              <Login connecter={connecter} deconnecter={deconnecter} pannier={pannier} />
            </Route>
            <Route path='/connect/' exact>
              <LoginUser connecter={connecter} deconnecter={deconnecter} />
            </Route>
            <Route path='/inscription/' exact>
              <RegisterUser connecter={connecter} deconnecter={deconnecter} />
            </Route>

            <Route path='/deconnexion/' exact>
              <Deconexion deconnecter={deconnecter} />
            </Route>
            <Route path='/cart/' exact>
              {' '}
              <Cart
                vider_Pannier={vider_Pannier}
                modifierpannier={addindex}
                pannier={pannier}
                deletfromepannier={delete_From_Pannier}
              />
            </Route>
            <Route exact path='/consommable/'>
              <Consommable modifierpannier={modifier_Pannier} />
            </Route>
            <Route exact path='/consommable/toner/'>
              <Toner modifierpannier={modifier_Pannier} />
            </Route>
            <Route exact path='/consommable/cartouche/'>
              <Cartouche modifierpannier={modifier_Pannier} />
            </Route>
            <Route exact path='/consommable/toner/page=:pageNumber'>
              <Toner modifierpannier={modifier_Pannier} />
            </Route>
            <Route exact path='/consommable/cartouche/page=:pageNumber'>
              <Cartouche modifierpannier={modifier_Pannier} />
            </Route>
            <Route exact path='/consommable/ruban/'>
              <Ruban modifierpannier={modifier_Pannier} />
            </Route>
            <Route exact path='/consommable/bobine-thermique/'>
              <Bobinethermique modifierpannier={modifier_Pannier} />
            </Route>
            <Route exact path='/paymentsucess/'>
              <Success_payment vider_Pannier={vider_Pannier} />
            </Route>
            <Route exact path='/paymentfail/'>
              <Failed_payment />
            </Route>

            <Route exact path='/imprimante-jet-d-encre/'>
              <Jetdencre modifierpannier={modifier_Pannier} />
            </Route>


            <Route exact path='/imprimante-multifonction/'>
              <Multifonction modifierpannier={modifier_Pannier} />
            </Route>



            <Route exact path='/imprimante-laser/'>
              <Laser modifierpannier={modifier_Pannier} />
            </Route>
            <Route exact path='/traceur/'>
              <Traceur modifierpannier={modifier_Pannier} />
            </Route>
            <Route path='/my-account/'>
              <Myaccount />
            </Route>
            <Route exact path='/demande-devis/'>
              <Demandedevis />
            </Route>
            <Route exact path='/autres-materiels/imprimante-etiquette/'>
              <Etiquette modifierpannier={modifier_Pannier} />
            </Route>
            <Route exact path='/autres-materiels/imprimante-point-de-vente/'>
              <Pointdevente modifierpannier={modifier_Pannier} />
            </Route>
            <Route path='/consommables/:id/:brand/'>
              <Consommables modifierpannier={modifier_Pannier} deletepannier={delete_From_Pannier} />
            </Route>


            <Route path='/imprimante/consomables/'>
              <RechercheAdvanced modifierpannier={modifier_Pannier} deletepannier={delete_From_Pannier} />
            </Route>
            {token &&
              <Route exact path='/checkout/'>
                <Checkout
                  pannier={pannier}
                  envoyer={envoyer}
                  vider_Pannier={vider_Pannier}
                />

              </Route>
            }

            <Route exact path='/checkout/virement/'>
              <Virement rib={rib} />
            </Route>
            {/* <Route path='/imprimantes/:id/:imprimante'>
          <Imprimantes modifierpannier={modifier_Pannier} />,
        </Route> */}
            <Route path='/marques/imprimante-hp/'>
              <Imprimanteshp modifierpannier={modifier_Pannier} deletepannier={delete_From_Pannier} />,
            </Route>
            <Route path='/marques/imprimante-brother/'>
              <Imprimantesbrother modifierpannier={modifier_Pannier} deletepannier={delete_From_Pannier} />,
            </Route>
            <Route path='/marques/imprimante-lexmark/'>
              <Imprimanteslexmark modifierpannier={modifier_Pannier} deletepannier={delete_From_Pannier} />,
            </Route>
            <Route path='/marques/imprimante-samsung/'>
              <Imprimantessamsung modifierpannier={modifier_Pannier} deletepannier={delete_From_Pannier} />,
            </Route>
            <Route path='/faq/'>
              <Faq />
            </Route>
            <Route path='/marques/imprimante-epson/'>
              <Imprimantesepson modifierpannier={modifier_Pannier} deletepannier={delete_From_Pannier} />,
            </Route>
            <Route path='/marques/imprimante-ricoh/'>
              <Imprimantesricoh modifierpannier={modifier_Pannier} deletepannier={delete_From_Pannier} />,
            </Route>
            <Route path='/marques/imprimante-canon/'>
              <Imprimantescanon modifierpannier={modifier_Pannier} deletepannier={delete_From_Pannier} />,
            </Route>
            <Route path='/marques/imprimante-kyocera/'>
              <Imprimanteskyocera modifierpannier={modifier_Pannier} deletepannier={delete_From_Pannier} />,
            </Route>
            <Route path='/marques/imprimante-oki/'>
              <Imprimantesoki modifierpannier={modifier_Pannier} deletepannier={delete_From_Pannier} />,
            </Route>
            <Route path='/marques/imprimante-xerox/'>
              <Imprimantesxerox modifierpannier={modifier_Pannier} deletepannier={delete_From_Pannier} />,
            </Route>
            <Route path='/imprimantes/:id/:brand/'>
              <Imprimante modifierpannier={modifier_Pannier} deletepannier={delete_From_Pannier} />
            </Route>

            <Route path='/produit-imprimantes/'>
              <Imprimantenoref modifierpannier={modifier_Pannier} deletepannier={delete_From_Pannier} />
            </Route>
            <Route path='/produit-consommables/'>
              <Consommablesnoref modifierpannier={modifier_Pannier} deletepannier={delete_From_Pannier} />
            </Route>
            <Route path='/404/' status={404}>
              <_404 />
            </Route>
            <Route path='/404/' status={404}>
              <_404 />
            </Route>

            <Route exact path='/contrat/'>
              <Abonnement />
            </Route>

            <Route exact path='/search/'>
              <Recherche modifierpannier={modifier_Pannier} />
            </Route>
            <Route exact path='/contrat/goads/'>
              <Contratgoads />
            </Route>
            <Route exact path='/contrat/ecomcoup2021/'>
              <Contratecom />
            </Route>
            <Route exact path='/contrat/emailingcoup2021/'>
              <Contratemailing />
            </Route>
            <Route exact path='/promotions/'>
              <Promotion modifierpannier={modifier_Pannier} />
            </Route>
            <Route exact path='/inscription/'>
              <Contrat />
            </Route>
            <Route path='/*' status={404}>
              <Redirect to='/404/' />{' '}
            </Route>
          </Switch>
        </div>
        <Footer />
      </ThingsProvider>
        </Route>
      </Switch>
      
    </div>
  );
}
export default App;
