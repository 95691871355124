import Modal from "../modal/modal3new";

import { useEffect } from 'react';
import React, { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import ThingsContext from '../../context';
import { useContext } from 'react';
import ReactStars from "react-stars";
import Avis from "../avis/avis";
import './product.css';
function Product2(props) {
  const context = useContext(ThingsContext);
  const [items, setItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isscrolled, setisscrolled] = useState(false);
  const [productitle, setproducttitle] = useState('');
  const [prixmodal, setprixmodal] = useState(0);
  const [idmodal, setidmodal] = useState(0);
  const [vp, setvp] = useState('');
  const [img, setimg] = useState('');
  const [moyenne, setMoyenne] = useState(5);
  const getmoyenne = (data, liste) => {
    setMoyenne(parseFloat(data)); // moyenne general
  };
  function isScrolledIntoView(el) {
    let element = document.getElementById(el);
    if (element) {
      var rect = element.getBoundingClientRect();
      var elemTop = rect.top;
      var elemBottom = rect.bottom;
      // Only completely visible elements return true:
      var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
      // Partially visible elements return true:
      //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
      return isVisible;
    }
  }
  useEffect(() => {
    // POST request using fetch inside useEffect React hook
    let elementclick = document.querySelector('.modalclick');
    if (elementclick) {
      elementclick.onclick = function () {
        document.querySelector('.modal').classList.toggle('open');
      };
    }

    if (isscrolled) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type: props.type,
          min_price: props.min_price,
          max_price: props.max_price,
          top: 8,
        }),
      };
      fetch(
        'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/top/ventes',
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          data = Object.values(data);
          setItems(data);

          setIsLoaded(true);
          if (isLoaded) {
            // const script = document.createElement('script');
            // script.src = '../assets/js/product.js';
            // document.body.appendChild(script);
          }
        });
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [isscrolled]);
  useEffect(() => {
    window.addEventListener('scroll', checkscroll);

    return () => {
      window.removeEventListener('scroll', checkscroll);
    };
  }, []);
  const checkscroll = () => {
    if (isLoaded == false) {
      const bool = isScrolledIntoView(props.type);
      if (bool == true) {
        setisscrolled(bool);
        window.removeEventListener('scroll', checkscroll);
      }
    } else {
      window.removeEventListener('scroll', checkscroll);
    }
  };
  const ajouterpanier = (data) => {
    props.ajouteraupannier(data);
  };
  const price = (prix) => {
    return (prix * 0.1 + prix).toFixed(2);
  };
  const type = (url) => {
    return url.slice(0, url.indexOf('/'));
  };
  const openpannier = (data) => {
    data.name_in_cart = data.url_fiche.split('/')[2].replace('-', ' ') + " " + data.color
    ajouterpanier(data);
    setidmodal(data.model_id);

    if (type(data.url_fiche) == 'consommables') {
      setproducttitle(data.oem + ' ' + data.color);
      setvp('cn');
      setimg(
        `https://alfaprint.fr/assets/img/supplies_image/${data.model_id}.${context}`
      );
    } else {
      setproducttitle(data.brand + ' ' + data.model);
      setvp('pr');
      setimg(
        `https://alfaprint.fr/assets/img/printers_image/${data.model_id}-0.${context}`
      );
    }
    setprixmodal(data.prix);
  };
  if (isLoaded) {
    return (
      <div className='container-fluid' id={props.type}>
        {!props.h1 && (
          <h2 className='section-title' style={{ textTransform: 'none' }}>
            {props.nom}
          </h2>
        )}
        {props.h1 && (
          <h1
            className='section-title big-title'
            style={{
              textTransform: 'none',
              fontWeight: '700',
              fontSize: '30px',
            }}
          >
            {props.nom}
          </h1>
        )}
        <OwlCarousel
          className='products-slider owl-carousel owl-theme dots-top'
          margin={10}
          items={4}
          lazyLoad
          responsive={{
            0: { items: 2 },
            700: { items: 3, margin: 15 },
            1200: { items: 5 },
          }}
        >
          {' '}
          {items.map((data) => {
            return (
              <div
                className='product-default inner-quickview inner-icon'
                key={data.product_id}
              >
                <div style={{ display: "none" }}>
                  <Avis id={data.product_id} sendmoyene={getmoyenne} />
                  {/* End .comment-list */}
                </div>
                <figure
                  style={{
                    height: '220px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Link to={`/${data.url_fiche}/`} className='image-product'>
                    {/* <img
                      height='220'
                      style={{
                        width: 'auto',
                        padding: '10px',
                        maxHeight: '220px',
                      }}
                      className='product-image owl-lazy'
                      data-src={`https://ns3156326.ip-51-91-212.eu/ecom_api/public/assets/img${data.url2} `}
                    /> */}
                    {type(data.url_fiche) == 'imprimantes' && (
                      <img
                        className='product-image owl-lazy'
                        height='220'
                        style={{
                          width: 'auto',
                          padding: '10px',
                          maxHeight: '220px',
                        }}
                        data-src={`https://alfaprint.fr/assets/img/printers_image/${data.model_id}-0.${context}`}
                        alt={data.brand + ' ' + data.model}
                      />
                    )}
                    {type(data.url_fiche) == 'consommables' && (
                      <img
                        className='product-image owl-lazy'
                        height='220'
                        style={{
                          width: 'auto',
                          padding: '10px',
                          maxHeight: '220px',
                        }}
                        data-src={`https://alfaprint.fr/assets/img/supplies_image/${data.model_id}.${context} `}
                        alt={
                          data.brand +
                          ' ' +
                          data.partnr +
                          ' ' +
                          data.oem +
                          ' ' +
                          data.color
                        }
                      />
                    )}
                  </Link>
                  <div className='label-group'>
                    {/* <div className='product-label label-hot'>HOT</div>
                    <div className='product-label label-sale'>20% Off</div> */}
                  </div>
                  <div className='btn-icon-group'>
                    <button
                      onClick={() => {
                        openpannier(data);
                      }}
                      className='btn-icon btn-add-cart modalclick'
                      data-toggle='modal'
                      data-target={`#${props.type}aa`}
                      alt='ajouter au panier'
                    >
                      <svg
                        className='icon'
                        style={{ width: '1.5rem', height: '1.5rem' }}
                        alt='ajouter au panier'
                      >
                        <use xlinkHref='assets/icons.svg#icon-shopping-bag' />
                      </svg>{' '}
                    </button>
                  </div>
                </figure>
                <div className='product-details'>
                  <div className='category-wrap'>
                    <div className='category-list'>
                      <div className='price-box'>
                        {/* <span className='old-price'>{price(data.prix)}</span> */}
                        <span
                          className='product-price'
                          style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            color: 'black',
                            marginTop: '-1rem',
                            width: 'max-content',
                            fontSize: '2.8rem',
                          }}
                        >
                          {(data.prix * 1.2).toFixed(2)} €{' '}
                          <span
                            style={{ fontSize: '1.2rem', color: '#565656' }}
                          >
                            TTC
                          </span>
                        </span>
                      </div>
                      <div style={{ height: '4rem' }}>
                        <p
                          id='productTitle'
                          style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            color: 'gray',
                            fontSize: '1.2rem',
                            bottom: '1%',
                            width: '100%',
                            textAlign: 'center',
                            height: '4rem',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {type(data.url_fiche) == 'consommables'
                            ? data.oem + ' ' + data.color
                            : data.brand + ' ' + data.model}
                        </p>
                      </div>
                    </div>

                    {/* <a href='#' className='btn-icon-wish'>
                      <i className='icon-heart' />
                    </a> */}
                  </div>
                  {/* <h3 className='product-title'>
                      <Link to={`${data.url_fiche}`}>{data.brand}</Link>
                  </h3> */}
                  <div
                    className='ratings-container'
                    style={{
                      position: 'absolute',
                      left: '50%',
                      bottom: '1%',
                      transform: 'translateX(-50%)',
                    }}
                  >
                    <div className='product-ratings'>
                      {/* <span className='ratings' style={{ width: '100%' }} /> */}
                      <ReactStars
                        value={moyenne}
                        edit={false}
                        size={24}
                        color2={"#ffd700"}
                      />
                      {/* <svg
                        className='icon'
                        style={{
                          width: '1.5rem',
                          height: '1.5rem',
                          fill: '#ffc107',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg> */}

                      {/* End .ratings */}
                      <span className='tooltiptext tooltip-top' />
                    </div>
                    {/* End .product-ratings */}
                  </div>
                  {/* End .product-container */}

                  {/* End .price-box */}
                </div>
                {/* End .product-details */}
              </div>
            );
          })}
        </OwlCarousel>

        <div
          id={props.type + 'aa'}
          className='modal custommodal'
          style={{ overflow: 'hidden' }}
        >
          {idmodal.length > 0 && (
            <Modal
            img={img}
            ajouteraupannier={ajouterpanier}
            deletepannier={props.deletepannier}
            type={props.type}
            title={productitle}
            prix={prixmodal}
            id={idmodal}
            vp={vp}
          />
          )}
        </div>
      </div>
    );
  } else
    return (
      <div>
        <div className='container-fluid' id={props.type}>
          {!props.h1 && (
            <h2 className='section-title' style={{ textTransform: 'none' }}>
              {props.nom}
            </h2>
          )}
          {props.h1 && (
            <h1
              className='section-title'
              style={{
                textTransform: 'none',
                fontWeight: '700',
                fontSize: ' 3.5rem',
              }}
            >
              {props.nom}
            </h1>
          )}
          <div className='flex-container'>
            <div className='item'>
              <div className='skelt'>
                <div className='white'></div>
              </div>
            </div>
            <div className='item'>
              <div className='skelt'>
                <div className='white'></div>
              </div>
            </div>
            <div className=' item item-3'>
              <div className='skelt'>
                <div className='white'></div>
              </div>
            </div>
            <div className='item item-4'>
              <div className='skelt'>
                <div className='white'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default Product2;
