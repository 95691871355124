import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import withClearCache from './update2';

const ClearCacheComponent = withClearCache(App);
// import { unregister } from './serviceWorkerRegistration';
// unregister();
const Failed_payment = loadable(() =>
  import(/* webpackChunkName: "failed2" */ './view/404/failed2')
);
const Success_payment = loadable(() =>
  import(/* webpackChunkName: "sicces2" */ './view/404/succes2')
);
const Deconnexion = loadable(() =>
  import(/* webpackChunkName: "dec" */ './view/deconnexion')
);
const Contratvide = loadable(() =>
  import(/* webpackChunkName: "vide" */ './view/contrat/contractvide')
);
console.log('fff');
// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <Switch>
//         <Route path='/deconnection'>
//           <Deconnexion />
//         </Route>
//         <Route exact path='/failedPayment'>
//           <Failed_payment />
//         </Route>
//         <Route exact path='/successfulPayment'>
//           <Success_payment />
//         </Route>
//         <Route path='/*'>
//           <App />
//         </Route>
//       </Switch>
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// );
const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <BrowserRouter>
        <Switch>
          <Route path='/deconnection'>
            <Deconnexion />
          </Route>
          <Route exact path='/failedPayment'>
            <Failed_payment />
          </Route>
          <Route exact path='/successfulPayment'>
            <Success_payment />
          </Route>
          <Route exact path='/contrat/ovh'>
            <Contratvide />
          </Route>
          <Route path='/*'>
            <ClearCacheComponent />
          </Route>
        </Switch>
      </BrowserRouter>
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <BrowserRouter>
        <Switch>
          <Route path='/deconnection'>
            <Deconnexion />
          </Route>
          <Route exact path='/failedPayment'>
            <Failed_payment />
          </Route>
          <Route exact path='/successfulPayment'>
            <Success_payment />
          </Route>
          <Route exact path='/contrat/ovh'>
            <Contratvide />
          </Route>
          <Route path='/*'>
            <App />
          </Route>
        </Switch>
      </BrowserRouter>
    </React.StrictMode>,
    rootElement
  );
}
// serviceWorkerRegistration.register();
