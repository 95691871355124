import ThingsContext from '../context';
import { useContext } from 'react';
function Iteminfo() {
  const context = useContext(ThingsContext);
  const width = window.screen.width;

  return (
    <div className='container-fluid' style={{ marginTop: '29px' }}>
      <div className='info-boxes-container row row-joined mb-1'>
        <div className='info-box info-box-icon-left col-lg-3'>
          <img
            className='icon-down'
            src={'assets/imagev2/icon9.' + context}
            style={{
              width: '4rem',
              height: '4rem',
              marginRight: '1rem',
            }}
            alt='Ils nous font confiance '
          />
          <div className='info-box-content'>
            <p style={{ textTransform: 'none' }}>
              +2000 Clients <br /> PEM-TPE satisfaits
            </p>
          </div>
        </div>
        <div className='info-box info-box-icon-left col-lg-3'>
          <img
            className='icon-down'
            src={'assets/imagev2/icon10.' + context}
            style={{
              width: '4rem',
              height: '4rem',
              marginRight: '1rem',
            }}
            alt='Service de livraison ultra-rapide'
          />
          <div className='info-box-content'>
            <p style={{ textTransform: 'none' }}>
              Service de <br />
              livraison rapide
            </p>
          </div>
        </div>
        <div className='info-box info-box-icon-left col-lg-3'>
          <img
            className='icon-down'
            src={'assets/imagev2/AY.' + context}
            style={{
              width: '4rem',
              height: '4rem',
              marginRight: '1rem',
            }}
            alt='Des offres exclusives jusqu’à -50%'
          />
          <div className='info-box-content'>
            <p style={{ textTransform: 'none' }}>
              Prix web <br />
              Exclusif{' '}
            </p>
          </div>
        </div>
        <div className='info-box info-box-icon-left col-lg-3'>
          <img
            className='icon-down'
            src={'assets/imagev2/icon11.' + context}
            style={{
              width: '4rem',
              height: '4rem',
              marginRight: '1rem',
            }}
            alt='+70 000 produits sur notre site web'
          />
          <div className='info-box-content'>
            <p style={{ textTransform: 'none' }}>
              +70.000
              <br />
              Produits{' '}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Iteminfo;
