import './chercher.css';

import { Link, useHistory } from 'react-router-dom';
import React from 'react';
import { useState, useEffect } from 'react';

function Chercher(props) {
  const history = useHistory();

  const [input, setInput] = useState('');

  const [focus, setFocus] = useState(false);

  const [select, setSelect] = useState(0);

  const [loading, setLoading] = useState(false);

  const [vide, setvide] = useState(false);

  const [liste, setListe] = useState(props.list);

  const [shortliste, setshortlist] = useState([]);

  const [route, setroute] = useState('');

  const [json, setJson] = useState([]);
  const refDel = React.createRef();
  const refInput = React.createRef();
  const handleInput = (e) => {
    setInput(e.target.value);

    if (e.target.value === '') {
      setFocus(false);
    } else {
      setFocus(true);
    }
  };

  useEffect(() => {
    if (input != '') {
      refDel.current.style.display = 'flex';
    } else {
      refDel.current.style.display = 'none';
    }

    var sh = '';
    if (input.length > 1 && liste.length > 0) {
      var tex = input.match(/[a-zA-Z]+|[0-9]+/g);
      for (let i = 0; i < tex.length; i++) {
        if (tex[i] == '') {
          tex.splice(i, 1);
        }
      }
      setvide(true);

      let index2 = 0;

      const liste2 = [];

      for (let index = 0; index < liste.length; index++) {
        var fnd = true;

        for (let i = 0; i < tex.length; i++) {
          sh = tex[i].toUpperCase();
          if (liste[index].name.toUpperCase().indexOf(sh) == -1) fnd = false;
        }

        if (fnd == true) {
          liste2.push(liste[index]);

          index2++;
        }

        if (index2 > 9) {
          break;
        }
      }

      setshortlist(liste2);

      if (liste2.length > 0) {
        setLoading(false);

        setFocus(true);
      }
    } else if (focus) {
      setFocus(false);
    }

    // let criteres = new FormData();

    // criteres.append('criteres[consommable]', 'non');

    // criteres.append('criteres[printer]', 'oui');

    // criteres.append('criteres[technology]', '');

    // switch (select) {

    // case '0':

    // criteres.append('criteres[categorie]', '');

    // break;

    // case '1':

    // criteres.append('criteres[categorie]', 'Multifonctions');

    // break;

    // case '2':

    // criteres.append('criteres[categorie]', 'Imprimantes laser');

    // break;

    // case '3':

    // criteres.append('criteres[categorie]', 'Imprimantes jets');

    // break;

    // case '4':

    // criteres.append('criteres[categorie]', 'Imprimantes pour étiquettes');

    // break;

    // case '5':

    // criteres.append('criteres[categorie]', 'Imprimantes grand format');

    // break;

    // case '6':

    // criteres.append('criteres[categorie]', '');

    // break;

    // default:

    // criteres.append('criteres[categorie]', '');

    // break;

    // }

    // criteres.append('criteres[type]', '');

    // criteres.append('criteres[multifonction]', '');

    // criteres.append('criteres[couleur]', '');

    // criteres.append('criteres[format]', '');

    // criteres.append('criteres[connectivite]', '');

    // criteres.append('criteres[brand]', '');

    // criteres.append('criteres[criteres]', '');

    // criteres.append('criteres[text]', input);

    // const requestOptions = {

    // method: 'POST',

    // body: criteres,

    // };

    // fetch(

    // 'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/search/produits1',

    // requestOptions

    // ).then((res) =>

    // res.json().then((response) => {

    // //console.log(response);

    // response = Object.values(response);

    // setJson(response);

    // props.send_Results(response);

    // response = response.slice(0, 10);

    // //console.log(response);

    // setListe(response);

    // setLoading(false);

    // })

    // );

    return () => {};
  }, [input, select]);

  useEffect(() => {
    setListe(props.list);
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [props.list]);

  const handleScroll = (e) => {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      // setInput('');

      setFocus(false);
    }
  };

  const submit = () => {
    if (route != '') history.push(route);
  };
  const delInput = () => {
    setInput('');
    refInput.current.value = '';
  };

  const handleselect = (e) => {
    setSelect(e.target.value);

    if (e.target.value == 1) {
      setroute('/imprimante-multifonction');
    } else if (e.target.value == 2) {
      setroute('/imprimante-laser');
    } else if (e.target.value == 3) {
      setroute('/imprimante-jet-d-encre');
    } else if (e.target.value == 4) {
      setroute('/autres-materiels/imprimante-etiquette');
    } else if (e.target.value == 5) {
      setroute('/traceur');
    } else if (e.target.value == 6) {
      setroute('/consommable');
    } else if (e.target.value == 0) {
      setroute('');
    }
  };

  if (props.device == 'web') {
    return (
      <div
        className={
          'header-icon header-search header-search-inline header-search-category w-lg-max pl-3 ' +
          props.class
        }
      >
        <a href='#' className='search-toggle' role='button'>
          <i className='icon-search-3' />
        </a>

        <div className='header-search-wrapper'>
          <input
            className='form-control'
            ref={refInput}
            placeholder={liste.length < 1 ? 'Loading' : 'Chercher...'}
            disabled={liste.length < 1}
            required
            style={{
              background: liste.length < 1 ? 'rgb(179 178 178 / 37%)' : '',
            }}
            onChange={handleInput}
            // onBlur={() => {

            // setFocus(false);

            // // setInput('');

            // }}

            onFocus={() => {
              if (input.length > 1) {
                setFocus(true);
              }
            }}
          />
          <button
            className='btn icon-search-3'
            ref={refDel}
            style={{
              display: 'none',

              justifyContent: 'center',
              borderLeft: '0px',
              alignItems: 'center',
              borderRadius: '0',
            }}
            onClick={delInput}
          >
            <svg
              className='icon-down'
              style={{
                fill: '#777',

                width: '20px',

                height: '20px',
              }}
            >
              <use xlinkHref='assets/icons.svg#icon-cross' />
            </svg>
          </button>
          <div className='select-custom display-flex'>
            <select id='cat' name='cat' onChange={handleselect}>
              <option value={0}>Tous</option>

              <option value={1}>Imprimante Multifonction</option>

              <option value={2}>Imprimante Laser</option>

              <option value={3}>Imprimante Jet d'encre</option>

              <option value={4}>Etiquetteuse</option>

              <option value={5}>Traceurs</option>

              <option value={6}>Consommables</option>
            </select>

            <svg
              className='icon-down'
              style={{
                fill: '#777',

                position: 'absolute',

                right: '20%',
              }}
            >
              <use xlinkHref='assets/icons.svg#icon-cheveron-down' />
            </svg>
          </div>
          {/* End .select-custom */}{' '}
          <button
            className='btn icon-search-3'
            style={{
              display: 'flex',

              justifyContent: 'center',

              alignItems: 'center',
            }}
            onClick={submit}
          >
            <svg
              className='icon-down'
              style={{
                fill: '#777',

                width: '20px',

                height: '20px',
              }}
            >
              <use xlinkHref='assets/icons.svg#icon-search' />
            </svg>
          </button>
          {focus && (
            <div className='choix'>
              {shortliste.length > 0 &&
                shortliste.map((data, index) => {
                  return (
                    <div key={index}>
                      <Link
                        className='recherche_res'
                        to={data.url_fiche.includes('produit') ? `/${data.url_fiche}` : `/${data.url_fiche}/`}
                        onClick={() => {
                          setFocus(false);
                        }}
                        alt={data.name}
                        title={data.name}
                      >
                        {data.sp != 'Supply' && (
                          <img
                            className='image-recherche'
                            src={`/assets/img/printers_image/${data.model_id}.webp `}
                            alt="pas d'image de produit "
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = 'assets/img/impr.webp';
                            }}
                          />
                        )}
                        {data.sp == 'Supply' && (
                          <img
                            className='image-recherche'
                            src={`/assets/img/supplies_image/${data.model_id}.webp `}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = 'assets/img/impr.webp';
                            }}
                          />
                        )}{' '}
                        {data.name}
                      </Link>
                    </div>
                  );
                })}{' '}
              {loading && (
                <div className='bounce-loader'>
                  <div className='bounce1'></div>

                  <div className='bounce2'></div>

                  <div className='bounce3'></div>
                </div>
              )}
              {!loading && vide && liste.length == 0 && 'aucun résultat trouvé'}
            </div>
          )}
        </div>

        {/* End .header-search-wrapper */}
      </div>
    );
  } else {
    return (
      <div
        className={
          'header-icon header-search header-search-inline header-search-category w-lg-max pl-3 ' +
          props.class
        }
      >
        <a href='#' className='search-toggle' role='button'>
          <i className='icon-search-3' />
        </a>

        <div className='header-search-wrapper mobile-wrap'>
          <input
            className='form-control'
            ref={refInput}
            placeholder={liste.length < 1 ? 'Loading' : 'Chercher...'}
            disabled={liste.length < 1}
            required
            style={{
              background: liste.length < 1 ? 'rgb(179 178 178 / 37%)' : '',
            }}
            onChange={handleInput}
            // onBlur={() => {

            // setFocus(false);

            // // setInput('');

            // }}

            onFocus={() => {
              if (input.length > 1) {
                setFocus(true);
              }
            }}
          />
          <button
            className='btn icon-search-3'
            ref={refDel}
            style={{
              display: 'none',

              justifyContent: 'center',
              borderLeft: '0px',
              alignItems: 'center',
              borderRadius: '0',
            }}
            onClick={delInput}
          >
            <svg
              className='icon-down'
              style={{
                fill: '#777',

                width: '20px',

                height: '20px',
              }}
            >
              <use xlinkHref='assets/icons.svg#icon-cross' />
            </svg>
          </button>
          <div className='select-custom display-flex'>
            <select id='cat' name='cat' onChange={handleselect}>
              <option value={0}>Tous</option>

              <option value={1}>Imprimante Multifonction</option>

              <option value={2}>Imprimante Laser</option>

              <option value={3}>Imprimante Jet d'encre</option>

              <option value={4}>Etiquetteuse</option>

              <option value={5}>Traceurs</option>

              <option value={6}>Consommables</option>
            </select>

            <svg
              className='icon-down'
              style={{
                fill: '#777',

                position: 'absolute',

                right: '20%',
              }}
            >
              <use xlinkHref='assets/icons.svg#icon-cheveron-down' />
            </svg>
          </div>
          {/* End .select-custom */}{' '}
          <button
            className='btn icon-search-3'
            style={{
              display: 'flex',

              justifyContent: 'center',

              alignItems: 'center',
            }}
            onClick={submit}
          >
            <svg
              className='icon-down'
              style={{
                fill: '#777',

                width: '20px',

                height: '20px',
              }}
            >
              <use xlinkHref='assets/icons.svg#icon-search' />
            </svg>
          </button>
          {focus && (
            <div className='choix choix-mobile'>
              {shortliste.length > 0 &&
                shortliste.map((data, index) => {
                  return (
                    <div key={index}>
                      <Link
                        className='recherche_res'
                        to={data.url_fiche.includes('produit') ? `/${data.url_fiche}` : `/${data.url_fiche}/`}
                        onClick={() => {
                          setFocus(false);
                        }}
                        alt={data.name}
                        title={data.name}
                      >
                        {data.sp != 'Supply' && (
                          <img
                            src={`/assets/img/printer_image/${data.modelid}.webp`}
                          />
                        )}
                        {data.sp == 'Supply' && (
                          <img
                            src={`/assets/img/supplies_image/${data.model_id}.webp `}
                          />
                        )}
                        {data.name}
                      </Link>
                    </div>
                  );
                })}{' '}
              {loading && (
                <div className='bounce-loader'>
                  <div className='bounce1'></div>

                  <div className='bounce2'></div>

                  <div className='bounce3'></div>
                </div>
              )}
              {!loading && vide && liste.length == 0 && 'aucun résultat trouvé'}
            </div>
          )}
        </div>

        {/* End .header-search-wrapper */}
      </div>
    );
  }
}

export default Chercher;
