import OwlCarousel from 'react-owl-carousel';
import './actualite.css';
import { useEffect, useState } from 'react';
import ThingsContext from '../../context';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import ReactStars from 'react-stars';
function Actualite() {
  const context = useContext(ThingsContext);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // POST request using fetch inside useEffect React hook

    setIsLoaded(true);

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  const [count,setCount]=useState(1000);
  const [popup, setpopup] = useState(false);
  function incrementer (){

    setCount(count+1)
setpopup(true)
    setTimeout(() => {
  
      setpopup(false);


    }, 2000);
  }


    return (
      <div className='testimonials-section' style={{ height: 'max-content' }}>
          <div className="AvisSection">
          <div className="AvisSection1">
      <h2 className="infoAvis"> Votre satisfaction est notre priorité et votre avis important !</h2>
            
         

            <form>
          <div class="row">
          <div className="title"><p style={{marginTop:"10px",marginBottom:"0"}}>Service</p></div>
          <div className="rating"> 
          <ReactStars
                
                value={5}
                        size={28}
                        color2={'#ffd700'}
                      />
          </div>
    
          </div>
          <hr></hr>
          <div class="row">
          <div className="title"><p style={{marginTop:"10px",marginBottom:"0"}}>Prix</p></div>
          <div className="rating"> 
          <ReactStars
                
                value={4}
                        size={28}
                        color2={'#ffd700'}
                      />
          </div>
    
          </div>
          <hr></hr>
          <div class="row">
          <div className="title"><p style={{marginTop:"10px",marginBottom:"0"}}>Qualité</p></div>
          <div className="rating"> 
          <ReactStars
                
                value={4}
                        size={28}
                        color2={'#ffd700'}
                      />
          </div>
    
          </div>
          <hr></hr>
          <div class="row">
          <div className="title"><p style={{marginTop:"10px",marginBottom:"0"}}>Rapidité</p></div>
          <div className="rating"> 
          <ReactStars
                
                value={4.5}    
                        size={28}
                        color2={'#ffd700'}
                      />
          </div>
    
          </div>
          <hr></hr>
          <div class="row">
          <div className="title"><p style={{marginTop:"10px",marginBottom:"0"}}>Service après-vente</p></div>
          <div className="rating"> 
          <ReactStars
                
                        value={5}
                        size={28}
                        color2={'#ffd700'}
                      />
          </div>
    
          </div>
          <hr></hr>
            </form>
       <div className="AvisSection2">
       <p className="infoAvis"> N'hésitez pas à noter nos services et à partager votre expérience avec Alfa Print </p>

       </div>
     <div className="AvisSection3">
        <button      className="buttonAction" onClick={()=>incrementer()}
                          >Partager vos avis</button>
     </div>

            </div>
          </div>
        {/* End .container */}
        {popup == true && (
              <div className="popup-succes">
                <div className="popup-succes-container">
                  <svg
                    className="popup-succes-icon"
                    style={{ marginTop: "3rem" }}
                  >
                    <use xlinkHref="assets/icons.svg#icon-verified_user" />
                  </svg>
                  <div
                    className="popup-succes-text"
                    style={{ marginTop: "10rem" }}
                  >
                    Votre avis a été bien enregistré<br />
              
                  </div>

                  <div className="popup-succes-text2">
                    {/* <button
                className='btn btn-light'
                style={{ padding: '.2rem 2rem', color: '#4caf50' }}
                onClick={gotocart}
              >
                Continuer
              </button> */}
                  </div>
                </div>
              </div>
            )}
   
      </div>
      
    );
  

}
export default Actualite;
