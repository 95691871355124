// import OwlCarousel from 'react-owl-carousel';
// import { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { useHistory } from "react-router-dom";
// import { isMobile } from 'react-device-detect';
// import ThingsContext from '../context';
// import { useContext } from 'react';
// import jsonFile_sp from '../assets/response.json'
// import Cherchermobile from './chercher/chercher2';

// import { Fade } from "react-slideshow-image";

// function Carousel() {
//   const context = useContext(ThingsContext);

//   const [listeproduits_sp, setListeproduits_sp] = useState();
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [iscarousel, setiscarousel] = useState(false);
//   useEffect(() => {
//     // POST request using fetch inside useEffect React hook

//     setIsLoaded(true);
//     setTimeout(() => {
//       setiscarousel(true);
//     }, 2000);
//     setListeproduits_sp(jsonFile_sp);
//     // empty dependency array means this effect will only run once (like componentDidMount in classes)
//   }, []);
//   const history = useHistory();
//   const routeChange = () =>{
//     let path = `/contrat/ecomcoup2021`;
//     history.push(path);
//   }
//   if (isLoaded && !isMobile) {
//     return (
//       <div
//         className='container-fluid'
//         style={{ padding: '0', marginBottom: '1rem' }}
//       >
//         <div>
//           <div style={{ margin: '0 auto' }}>
//             <OwlCarousel
//               className='home-slider  owl-theme '
//               loop
//               items={1}
//               margin={10}
//               autoplay
//               autoplayTimeout={6000}
//               lazyLoad
//               center
//               responsiveClass={true}

//             >
//               <div className='home-slide home-slide5 banner banner-md-vw banner-sm-vw'>
//                 <img
//                   className='slide-bg owl-lazy'
//                   data-src={'assets/imagev2/sliderst.' + context}
//                   sizes='100vw'
//                   style={{ height: '64vh', width: '100%', maxHeight: '500px' }}
//                   alt='Offre d’Alfa Print pour les entreprises'
//                 />
//                 <div className='banner-layer banner-layer-middle text-uppercase'>
//                   <p
//                     className='m-b-2 slider-p'
//                     style={{
//                       textTransform: 'none',
//                       color: 'white',
//                       fontSize: '50px',
//                       marginTop: '0rem',
//                       fontWeight: '700',
//                       lineHeight: '6rem',
//                     }}
//                   >
//                     Impression <br />
//                     <span
//                       style={{
//                         fontSize: '75px',
//                         color: '#e90039',
//                         fontWeight: '700',
//                         marginLeft: '5px',
//                       }}
//                     >
//                       illimitée
//                     </span>{' '}
//                   </p>
//                   <p
//                     style={{
//                       color: 'white',
//                       width: '50%',
//                       fontSize: '1.2rem',
//                       textTransform: 'none',
//                       fontFamily: 'Montserrat ,sans-serif',
//                     }}
//                   >
//                     à partir de 14,90 euros par mois *
//                   </p>
//                   <br />
//                   <div style={{ width: '26vw' }}>
//                     <Link to='/contrat/'>
//                       <button
//                         className='item-text-button '
//                         style={{
//                           borderWidth: '3px',
//                           padding: '1rem 5rem',
//                           backgroundColor: '#fffcfc26',
//                           fontWeight: '500',
//                           textTransform: 'none',
//                           fontSize: '1.6rem',
//                         }}
//                       >
//                         En profiter
//                       </button>
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//               <div className='home-slide home-slide1 banner banner-md-vw banner-sm-vw'>
//                 <img
//                   className='slide-bg owl-lazy '
//                   alt='Offre spéciale conçue pour les pros'
//                   data-src={'assets/imagev2/sliderconso.' + context}
//                   style={{ height: '64vh', width: '100%', maxHeight: '500px' }}
//                 />
//                 <div className='banner-layer banner-layer-middle text-uppercase'>
//                   <p
//                     className='m-b-2 slider-p'
//                     style={{
//                       textTransform: 'none',
//                       color: 'white',
//                       fontSize: '48px',
//                       marginTop: '2rem',
//                       fontWeight: '700',
//                       lineHeight: '5rem',
//                     }}
//                   >
//                     Economisez <br />
//                     <span
//                       style={{
//                         fontSize: '60px',
//                         color: 'rgb(45 93 142)',
//                         fontWeight: '700',
//                       }}
//                     >
//                       Jusqu'à 20%
//                     </span>{' '}
//                   </p>

//                   <br />
//                   <div style={{ width: '26vw' }}>
//                     <Link to='/consommable/'>
//                       {' '}
//                       <button
//                         className='item-text-button '
//                         style={{
//                           borderWidth: '3px',
//                           padding: '1rem 5rem',
//                           backgroundColor: '#fffcfc26',
//                           fontWeight: '500',
//                           marginTop: '0rem',
//                           textTransform: 'capitalize',
//                           fontSize: '1.6rem',
//                         }}
//                       >
//                         En profiter
//                       </button>
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//               <div className='home-slide home-slide2 banner banner-md-vw banner-sm-vw'>
//                 <img
//                   className='slide-bg owl-lazy'
//                   alt='meilleures ventes '
//                   data-src={'assets/imagev2/slider 33.' + context}
//                   style={{ height: '64vh', width: '100%', maxHeight: '500px' }}
//                 />
//                 <div className='banner-layer banner-layer-middle text-uppercase'>

//                   <p
//                     className='m-b-2 slider-p'
//                     style={{
//                       textTransform: 'none',
//                       color: 'white',
//                       fontSize: '50px',
//                       marginTop: '0rem',
//                       fontWeight: '700',
//                       lineHeight: '4rem',
//                     }}
//                   >
//                     Meilleures <br /> Ventes
//                   </p>
//                   <p
//                     style={{
//                       color: 'white',
//                       width: '50%',
//                       fontSize: '1.8rem',
//                       textTransform: 'none',
//                     }}
//                   >
//                     en imprimantes multifonctions
//                   </p>
//                   <br />
//                   <div style={{ width: '26vw' }}>
//                     <Link to='/imprimante-multifonction/'>
//                       <button
//                         className='item-text-button '
//                         style={{
//                           borderWidth: '3px',
//                           padding: '1rem 5rem',
//                           backgroundColor: '#fffcfc26',
//                           fontWeight: '500',
//                           textTransform: 'none',
//                           fontSize: '1.6rem',
//                         }}
//                       >
//                         En profiter
//                       </button>
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </OwlCarousel>
//           </div>
//         </div>
//       </div>
//     );
//   } else if (iscarousel) {
//     return (

//        // slidermobile01 | slidermobile03 | slidermobile02

//       <div
//         className='container-fluid'
//         style={{ padding: '0', marginBottom: '1rem' }}
//       >

//           <Cherchermobile liste_json={listeproduits_sp}
//           />

//         <div>
//           <div style={{ margin: '0 auto' }}>
//             <OwlCarousel
//               className='home-slider  owl-theme '
//               loop
//               margin={10}
//               items={1}
//               autoplay
//               autoplayTimeout={4000}
//               lazyLoad
//             >

//               <div className='home-slide home-slide5 banner banner-md-vw banner-sm-vw'>
//                 <img
//                   className='slide-bg owl-lazy'
//                   data-src={'assets/imagev2/sliderst.' + context}
//                   sizes='100vw'
//                   style={{ height: '64vh' }}
//                   alt='Offre d’Alfa Print pour les entreprises'
//                 />
//                 <div className='banner-layer banner-layer-middle text-uppercase'>
//                   <p
//                     className='m-b-2 slider-p'
//                     style={{
//                       textTransform: 'none',
//                       color: 'white',
//                       fontSize: '50px',
//                       marginTop: '0rem',
//                       fontWeight: '700',
//                       lineHeight: '6rem',
//                     }}
//                   >
//                     Impression <br />
//                     <span
//                       style={{
//                         fontSize: '75px',
//                         color: '#e90039',
//                         fontWeight: '700',
//                         marginLeft: '5px',
//                       }}
//                     >
//                       illimitée
//                     </span>{' '}
//                   </p>
//                   <p
//                     style={{
//                       color: 'white',
//                       width: '50%',
//                       fontSize: '1.2rem',
//                       textTransform: 'none',
//                       fontFamily: 'Montserrat ,sans-serif',
//                     }}
//                   >
//                     à partir de 14,90 euros par mois *
//                   </p>
//                   <br />
//                   <div style={{ width: '26vw' }}>
//                     <Link to='/contrat/'>
//                       <button
//                         className='item-text-button '
//                         style={{
//                           borderWidth: '3px',
//                           padding: '1rem 5rem',
//                           backgroundColor: '#fffcfc26',
//                           fontWeight: '500',
//                           textTransform: 'none',
//                           fontSize: '1.6rem',
//                         }}
//                       >
//                         En profiter
//                       </button>
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//               <div className='home-slide home-slide1 banner banner-md-vw banner-sm-vw'>
//                 <img
//                   className='slide-bg owl-lazy '
//                   alt='Offre spéciale conçue pour les pros'
//                   data-src={'assets/imagev2/sliderconso.' + context}
//                   style={{ height: '64vh' }}
//                 />
//                 <div className='banner-layer banner-layer-middle text-uppercase'>
//                   <p
//                     className='m-b-2 slider-p'
//                     style={{
//                       textTransform: 'none',
//                       color: 'white',
//                       fontSize: '48px',
//                       marginTop: '2rem',
//                       fontWeight: '700',
//                       lineHeight: '5rem',
//                     }}
//                   >
//                     Economisez <br />
//                     <span
//                       style={{
//                         fontSize: '60px',
//                         color: 'rgb(45 93 142)',
//                         fontWeight: '700',
//                       }}
//                     >
//                       Jusqu'à 20%
//                     </span>{' '}
//                   </p>

//                   <br />
//                   <div style={{ width: '26vw' }}>
//                     <Link to='/consommable/'>
//                       {' '}
//                       <button
//                         className='item-text-button '
//                         style={{
//                           borderWidth: '3px',
//                           padding: '1rem 5rem',
//                           backgroundColor: '#fffcfc26',
//                           fontWeight: '500',
//                           marginTop: '0rem',
//                           textTransform: 'capitalize',
//                           fontSize: '1.6rem',
//                         }}
//                       >
//                         En profiter
//                       </button>
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//               <div className='home-slide home-slide2 banner banner-md-vw banner-sm-vw'>
//                 <img
//                   className='slide-bg owl-lazy'
//                   alt='meilleures ventes '
//                   data-src={'assets/imagev2/slider 33.' + context}
//                   style={{ height: '64vh' }}
//                 />
//                 <div className='banner-layer banner-layer-middle text-uppercase'>

//                   <p
//                     className='m-b-2 slider-p'
//                     style={{
//                       textTransform: 'none',
//                       color: 'white',
//                       fontSize: '50px',
//                       marginTop: '0rem',
//                       fontWeight: '700',
//                       lineHeight: '4rem',
//                     }}
//                   >
//                     Meilleurs <br /> ventes
//                   </p>
//                   <p
//                     style={{
//                       color: 'white',
//                       width: '50%',
//                       fontSize: '1.8rem',
//                       textTransform: 'none',
//                     }}
//                   >
//                     En Imprimantes Multifontions
//                   </p>
//                   <br />
//                   <div style={{ width: '26vw' }}>
//                     <Link to='/imprimante-multifonction/'>
//                       <button
//                         className='item-text-button '
//                         style={{
//                           borderWidth: '3px',
//                           padding: '1rem 5rem',
//                           backgroundColor: '#fffcfc26',
//                           fontWeight: '500',
//                           textTransform: 'none',
//                           fontSize: '1.6rem',
//                         }}
//                       >
//                         En profiter
//                       </button>
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </OwlCarousel>
//           </div>
//         </div>
//       </div>
//     );
//   } else {
//     return (
//       <div>
//       <div className='home-slide home-slide5 banner banner-md-vw banner-sm-vw'>
//         <img
//           className='slide-bg owl-lazy'
//           data-src={'assets/imagev2/slidermobile01.' + context}
//           sizes='100vw'
//           style={{ height: '64vh' , width: '100%'}}
//           alt='Offre d’Alfa Print pour les entreprises'
//         />
//         <div className='banner-layer banner-layer-middle text-uppercase'>
//           <p
//             className='m-b-2 slider-p'
//             style={{
//               textTransform: 'none',
//               color: 'white',
//               fontSize: '50px',
//               marginTop: '0rem',
//               fontWeight: '700',
//               lineHeight: '6rem',
//             }}
//           >
//             Impression <br />
//             <span
//               style={{
//                 fontSize: '75px',
//                 color: '#e90039',
//                 fontWeight: '700',
//                 marginLeft: '5px',
//               }}
//             >
//               illimitée
//             </span>{' '}
//           </p>
//           <p
//             style={{
//               color: 'white',
//               width: '50%',
//               fontSize: '1.2rem',
//               textTransform: 'none',
//               fontFamily: 'Montserrat ,sans-serif',
//             }}
//           >
//             à partir de 14,90 euros par mois *
//           </p>
//           <br />
//           <div style={{ width: '26vw' }}>
//             <Link to='/contrat/'>
//               <button
//                 className='item-text-button '
//                 style={{
//                   borderWidth: '3px',
//                   padding: '1rem 5rem',
//                   backgroundColor: '#fffcfc26',
//                   fontWeight: '500',
//                   textTransform: 'none',
//                   fontSize: '1.6rem',
//                 }}
//               >
//                 En profiter
//               </button>
//             </Link>
//           </div>
//         </div>
//       </div>
//       </div>
//     );
//   }
// }
// export default Carousel;

// ----------------------------

import OwlCarousel from "react-owl-carousel";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import ThingsContext from "../context";
import { useContext } from "react";

function Carousel() {
  const context = useContext(ThingsContext);

  const [isLoaded, setIsLoaded] = useState(false);
  const [iscarousel, setiscarousel] = useState(false);
  useEffect(() => {
    // POST request using fetch inside useEffect React hook
    // setIsLoaded(true);
    // setTimeout(() => {
    //   setIsLoaded(true);
    //   setiscarousel(true);
    // }, 2000);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  const history = useHistory();
  const routeChange = () => {
    let path = `/contrat/ecomcoup2021`;
    history.push(path);
  };

  if (isLoaded) {
    console.log("------- >ISLOADED");
    if (isMobile) {
      return (
        <div
          className='container-fluid'
          style={{ padding: '0', marginBottom: '1rem' }}
        >
          <div>
            <div style={{ margin: '0 auto' }}>
              <OwlCarousel
                className='home-slider  owl-theme '
                loop
                margin={10}
                items={1}
                autoplay
                autoplayTimeout={4000}
                lazyLoad
              >

                <div className='home-slide home-slide5 banner banner-md-vw banner-sm-vw'>
                  <img
                    className='slide-bg owl-lazy'
                    data-src={'assets/imagev2/slidermobile01.' + context}
                    sizes='100vw'
                    style={{ height: '64vh' }}
                    alt='Offre d’Alfa Print pour les entreprises'
                  />
                  <div className='banner-layer banner-layer-middle text-uppercase'>
                    <p
                      className='m-b-2 slider-p'
                      style={{
                        textTransform: 'none',
                        color: 'white',
                        fontSize: '50px',
                        marginTop: '0rem',
                        fontWeight: '700',
                        lineHeight: '6rem',
                      }}
                    >
                      Impression <br />
                      <span
                        style={{
                          fontSize: '75px',
                          color: '#e90039',
                          fontWeight: '700',
                          marginLeft: '5px',
                        }}
                      >
                        illimitée
                      </span>{' '}
                    </p>
                    <p
                      style={{
                        color: 'white',
                        width: '50%',
                        fontSize: '1.2rem',
                        textTransform: 'none',
                        fontFamily: 'Montserrat ,sans-serif',
                      }}
                    >
                      à partir de 14,90 euros par mois *
                    </p>
                    <br />
                    <div style={{ width: '26vw' }}>
                      <Link to='/contrat/'>
                        <button
                          className='item-text-button '
                          style={{
                            borderWidth: '3px',
                            padding: '1rem 5rem',
                            backgroundColor: '#fffcfc26',
                            fontWeight: '500',
                            textTransform: 'none',
                            fontSize: '1.6rem',
                          }}
                        >
                          En profiter
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='home-slide home-slide1 banner banner-md-vw banner-sm-vw'>
                  <img
                    className='slide-bg owl-lazy '
                    alt='Offre spéciale conçue pour les pros'
                    data-src={'assets/imagev2/slidermobile03.' + context}
                    style={{ height: '64vh' }}
                  />
                  <div className='banner-layer banner-layer-middle text-uppercase'>
                    <p
                      className='m-b-2 slider-p'
                      style={{
                        textTransform: 'none',
                        color: 'white',
                        fontSize: '48px',
                        marginTop: '2rem',
                        fontWeight: '700',
                        lineHeight: '5rem',
                      }}
                    >
                      Economisez <br />
                      <span
                        style={{
                          fontSize: '60px',
                          color: 'rgb(45 93 142)',
                          fontWeight: '700',
                        }}
                      >
                        Jusqu'à 20%
                      </span>{' '}
                    </p>

                    <br />
                    <div style={{ width: '26vw' }}>
                      <Link to='/consommable/'>
                        {' '}
                        <button
                          className='item-text-button '
                          style={{
                            borderWidth: '3px',
                            padding: '1rem 5rem',
                            backgroundColor: '#fffcfc26',
                            fontWeight: '500',
                            marginTop: '0rem',
                            textTransform: 'capitalize',
                            fontSize: '1.6rem',
                          }}
                        >
                          En profiter
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='home-slide home-slide2 banner banner-md-vw banner-sm-vw'>
                  <img
                    className='slide-bg owl-lazy'
                    alt='meilleures ventes '
                    data-src={'assets/imagev2/slidermobile02.' + context}
                    style={{ height: '64vh' }}
                  />
                  <div className='banner-layer banner-layer-middle text-uppercase'>

                    <p
                      className='m-b-2 slider-p'
                      style={{
                        textTransform: 'none',
                        color: 'white',
                        fontSize: '50px',
                        marginTop: '0rem',
                        fontWeight: '700',
                        lineHeight: '4rem',
                      }}
                    >
                      Meilleurs <br /> ventes
                    </p>
                    <p
                      style={{
                        color: 'white',
                        width: '50%',
                        fontSize: '1.8rem',
                        textTransform: 'none',
                      }}
                    >
                      En Imprimantes Multifontions
                    </p>
                    <br />
                    <div style={{ width: '26vw' }}>
                      <Link to='/imprimante-multifonction/'>
                        <button
                          className='item-text-button '
                          style={{
                            borderWidth: '3px',
                            padding: '1rem 5rem',
                            backgroundColor: '#fffcfc26',
                            fontWeight: '500',
                            textTransform: 'none',
                            fontSize: '1.6rem',
                          }}
                        >
                          En profiter
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="container-fluid"
          style={{ padding: "0", marginBottom: "1rem" }}
        >
          <div>
            <div style={{ margin: "0 auto" }}>
              <OwlCarousel
                className="home-slider  owl-theme "
                loop
                margin={10}
                items={1}
                autoplay
                autoplayTimeout={6000}
                lazyLoad
              >
                <div className="home-slide home-slide5 banner banner-md-vw banner-sm-vw">
                  <img
                    className="slide-bg owl-lazy"
                    data-src={"assets/imagev2/sliderst." + context}
                    sizes="100vw"
                    style={{ height: "64vh", width: "100%", maxHeight: "500px" }}
                    alt="Offre d’Alfa Print pour les entreprises"
                  />
                  <div className="banner-layer banner-layer-middle text-uppercase">
                    <p
                      className="m-b-2 slider-p"
                      style={{
                        textTransform: "none",
                        color: "white",
                        fontSize: "50px",
                        marginTop: "0rem",
                        fontWeight: "700",
                        lineHeight: "6rem",
                      }}
                    >
                      Impression <br />
                      <span
                        style={{
                          fontSize: "75px",
                          color: "#e90039",
                          fontWeight: "700",
                          marginLeft: "5px",
                        }}
                      >
                        illimitée
                      </span>{" "}
                    </p>
                    <p
                      style={{
                        color: "white",
                        width: "50%",
                        fontSize: "1.2rem",
                        textTransform: "none",
                        fontFamily: "Montserrat ,sans-serif",
                      }}
                    >
                      à partir de 14,90 euros par mois *
                    </p>
                    <br />
                    <div style={{ width: "26vw" }}>
                      <Link to="/contrat/">
                        <button
                          className="item-text-button "
                          style={{
                            borderWidth: "3px",
                            padding: "1rem 5rem",
                            backgroundColor: "#fffcfc26",
                            fontWeight: "500",
                            textTransform: "none",
                            fontSize: "1.6rem",
                          }}
                        >
                          En profiter
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="home-slide home-slide1 banner banner-md-vw banner-sm-vw">
                  <img
                    className="slide-bg owl-lazy "
                    alt="Offre spéciale conçue pour les pros"
                    data-src={"assets/imagev2/sliderconso." + context}
                    style={{ height: "64vh", width: "100%", maxHeight: "500px" }}
                  />
                  <div className="banner-layer banner-layer-middle text-uppercase">
                    <p
                      className="m-b-2 slider-p"
                      style={{
                        textTransform: "none",
                        color: "white",
                        fontSize: "48px",
                        marginTop: "2rem",
                        fontWeight: "700",
                        lineHeight: "5rem",
                      }}
                    >
                      Economisez <br />
                      <span
                        style={{
                          fontSize: "60px",
                          color: "rgb(45 93 142)",
                          fontWeight: "700",
                        }}
                      >
                        Jusqu'à 20%
                      </span>{" "}
                    </p>

                    <br />
                    <div style={{ width: "26vw" }}>
                      <Link to="/consommable/">
                        {" "}
                        <button
                          className="item-text-button "
                          style={{
                            borderWidth: "3px",
                            padding: "1rem 5rem",
                            backgroundColor: "#fffcfc26",
                            fontWeight: "500",
                            marginTop: "0rem",
                            textTransform: "capitalize",
                            fontSize: "1.6rem",
                          }}
                        >
                          En profiter
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="home-slide home-slide2 banner banner-md-vw banner-sm-vw">
                  <img
                    className="slide-bg owl-lazy"
                    alt="meilleures ventes "
                    data-src={"assets/imagev2/slider 33." + context}
                    style={{ height: "64vh", width: "100%", maxHeight: "500px" }}
                  />
                  <div className="banner-layer banner-layer-middle text-uppercase">
                    <p
                      className="m-b-2 slider-p"
                      style={{
                        textTransform: "none",
                        color: "white",
                        fontSize: "50px",
                        marginTop: "0rem",
                        fontWeight: "700",
                        lineHeight: "4rem",
                      }}
                    >
                      Meilleures <br /> Ventes
                    </p>
                    <p
                      style={{
                        color: "white",
                        width: "50%",
                        fontSize: "1.8rem",
                        textTransform: "none",
                      }}
                    >
                      en imprimantes multifonctions
                    </p>
                    <br />
                    <div style={{ width: "26vw" }}>
                      <Link to="/imprimante-multifonction/">
                        <button
                          className="item-text-button "
                          style={{
                            borderWidth: "3px",
                            padding: "1rem 5rem",
                            backgroundColor: "#fffcfc26",
                            fontWeight: "500",
                            textTransform: "none",
                            fontSize: "1.6rem",
                          }}
                        >
                          En profiter
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      );
    }
  } else {
    console.log("------->not carousel - not loaded");
    setTimeout(() => {
      setIsLoaded(true);
      setiscarousel(true);
    }, 3000);
    return (
      <div style={{ height: 'auto', width: '1800px', margin: "auto", position: "relative" }}>
        <div className="home-slide home-slide5 banner banner-md-vw banner-sm-vw">
          <img
            className="slide-bg owl-lazy"
            src={"assets/imagev2/sliderst." + context}
            sizes="100vw"
            style={{ maxHeight: "100%", maxWidth: "100%" }}
            alt="Offre d’Alfa Print pour les entreprises"
          />
          <div className="banner-layer banner-layer-middle text-uppercase">
            <p
              className="m-b-2 slider-p"
              style={{
                textTransform: "none",
                color: "white",
                fontSize: "50px",
                marginTop: "0rem",
                fontWeight: "700",
                lineHeight: "6rem",
              }}
            >
              Impression <br />
              <span
                style={{
                  fontSize: "75px",
                  color: "#e90039",
                  fontWeight: "700",
                  marginLeft: "5px",
                }}
              >
                illimitée
              </span>{" "}
            </p>
            <p
              style={{
                color: "white",
                width: "50%",
                fontSize: "1.2rem",
                textTransform: "none",
                fontFamily: "Montserrat ,sans-serif",
              }}
            >
              à partir de 14,90 euros par mois *
            </p>
            <br />
            <div style={{ width: "26vw" }}>
              <Link to="/contrat/">
                <button
                  className="item-text-button "
                  style={{
                    borderWidth: "3px",
                    padding: "1rem 5rem",
                    backgroundColor: "#fffcfc26",
                    fontWeight: "500",
                    textTransform: "none",
                    fontSize: "1.6rem",
                  }}
                >
                  En profiter
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Carousel;
