import Carousel from '../../components/carousel';
import Iteminfo from '../../components/iteminfo';
import React ,{useState,useEffect}from 'react';
import Product2 from '../../components/carouselproudct/productstest';
import ProdTest2 from '../../components/carouselproudct/productstest2';
import MetaTags from 'react-meta-tags';
import Popup from '../../components/popup/popup';
import Items from '../../components/items/items';
import Brands from '../../components/brands';
import Writing from '../../components/writing';
import ActuEval from '../../components/actualiteevaluation/actuevaluation';
import Flashvente from '../../components/flashvente/flashvente';
import './acceuil.css';
import BadgeoffreFev from "../../assets/BadgeoffreFev.png";
import Cookie from '../../components/cookies/cookie';
// import AvisGoogle from "../../assets/badgeAccueil.webp";
import AvisGoogle from "../../assets/avisgoogle.webp";
import iconeClosefrom from "../../assets/icone.png";
// import Reference from '../components/reference';
import { useForm } from "react-hook-form";
function Acceuil(props) {
  const ajouteraupannier = (data) => {
    props.modifierpannier(data);
  };

  const [Show, setShow] = useState(false);
  const envoyer = (data) => {
    let form = new FormData();
    form.append("nom", data.nom);
    form.append("prenom", data.prenom);
    form.append("email", data.email);
    form.append("pro", data.pro);

    form.append("src_contrat", "abonnement_contrat");
    form.append("tel", data.tel);
    const requestOptions = {
      method: "POST",
      body: form,
    };
    fetch(
      "https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/addleeds",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setShow(false);
      });
  };
  const { register,handleSubmit } = useForm();
  const [visible, setVisible] = useState(true);
  const [visiblePromo, setVisiblePromo] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
     
    }, 5000);
  }, []);

  const [isloaded, setLoaded] = useState(false);
  const [popupcookies, setpopupcookies] = useState(false);
  const [Po, setPo] = useState(false);
  useEffect(() => {
    let id=localStorage.getItem('badge2');
    console.log(id,"zz")

    if (id==="false"){
      setpopupcookies(false);
      setLoaded(false)
    }
    setTimeout(() => {
      let cookie = localStorage.getItem('badge2');
 
      if (cookie == null) {
        setpopupcookies(true);
        setLoaded(true)
      }
      
    }, 5000);
  }, []);
  function closemodal() {
    setPo(false);
    localStorage.setItem('badge2', false);
  }
  return (
    <div>
      <MetaTags>
        <title>Imprimante & scanner au meilleur prix chez Alfa Print</title>
        <meta
          id='meta-description'
          name='description'
          content='Parcourez notre catalogue dédié aux imprimantes et scanners à un prix compétitif sur les plus grandes marques: imprimante hp, brother, epson'
        />
        <meta
          id='og-meta-description'
          property='og:description'
          content='Parcourez notre catalogue dédié aux imprimantes et scanners à un prix compétitif sur les plus grandes marques: imprimante hp, brother, epson'
        />
        <meta id='og-meta-type' property='og:type' content='website' />
        <link id='id-canonical' rel='canonical' href='https://alfaprint.fr' />
        <meta id='og-url' property='og:url' content='https://alfaprint.fr' />
        <link
          id='hreflang'
          rel='alternate'
          hrefLang='fr-fr'
          href='https://alfaprint.fr'
        />
        <link
          id='hreflangdefault'
          rel='alternate'
          hrefLang='x-default'
          href='https://alfaprint.fr'
        />
        <meta id='twitter-card' name="twitter:card" content="summary" />
        <meta id='twitter-site' name="twitter:site" content="Alfa print" />
        <meta
          id='title'
          property='title'
          content='Imprimante & scanner au meilleur prix chez Alfa Print'
        />
        <meta
          id='og-title'
          property='og:title'
          content='Imprimante & scanner au meilleur prix chez Alfa Print'
        />

        <meta
          id='og-image'
          property='og:image'
          content='https://alfaprint.fr/assets/images/head/acceuil.webp'
        />
        <script id='structured-data' type='application/ld+json'>
          {`
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "Alfa print",
            "url": "https://example.com",
            "sameAs": ["https://www.facebook.com/www.alfaprint.fr/",
                       "https://twitter.com/AlfaPrint01",
                       "https://www.instagram.com/alfaprint.fr/"]
          }`}
        </script>
      </MetaTags>
      <main className='main' id='main'>
  
        <div>
          <Carousel />
        </div>
        {visible ? (
            <></>
          ) : (
            <div className="Badge">
              <img
                src={AvisGoogle}
                onClick={() => setVisible(true)}
                alt="Widget avis Google"
                className="googlebadge"
              ></img>
            </div>
          )}
        <div className='products-section'>
          <ProdTest2
            type='TOPVENTE'
            min_price='500'
            max_price='95000'
            nom="Choisir l'imprimante qui vous convient pour votre entreprise"
            ajouteraupannier={ajouteraupannier}
            deletepannier={props.deletepannier}
            h1='oui'
          />
        </div>
        

        <Items />

        <div className='products-section '>
          <Product2
            type='consommable'
            min_price='0'
            max_price='95000'
            nom="Meilleures cartouches d'encre pour les professionnels"
            ajouteraupannier={ajouteraupannier}
            deletepannier={props.deletepannier}
          />
        </div>
   
              
   
        <Product2
          type='multifonction'
          min_price='500'
          max_price='95000'
          nom='La sélection recommandée par notre équipe'
          ajouteraupannier={ajouteraupannier}
          deletepannier={props.deletepannier}
        />
        <Product2
          type='Consommable_TOPVENTE'
          min_price='0'
          max_price='95000'
          nom='Meilleurs toners conçus pour les professionnels'
          ajouteraupannier={ajouteraupannier}
          deletepannier={props.deletepannier}
        />
        <Brands />
        <ActuEval />
        <Iteminfo />
        <Writing />
      
      </main>
      {/* End .main */}
      {/* End .page-wrapper */}

      <a id='scroll-top' href='#top' title='Top' role='button'>
        <img
          className='icon-down'
          src='assets/icons/chevron-small-up.svg'
          alt='Icone chevron'
          style={{ width: '16px', height: '16px', marginTop: '-3px' }}
        />
      </a>

      <Cookie />
    </div>
  );
}

export default Acceuil;
