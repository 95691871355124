import OwlCarousel from 'react-owl-carousel';

function Testimonials() {
  return (
    <div className='testimonials-section' style={{ height: 'max-content' }}>
      <div className='container'>
        <h2 className='h2Comp'>Ils nous font confiance</h2>
        <OwlCarousel
          // autoplay
          loop
          dots={false}
          autoplayTimeout={3000}
          responsive={{
            0: { items: 1 },
            990: { items: 2 },
          }}
          className='testimonials-carousel owl-carousel owl-theme images-left'
        >
          <div className='testimonial'>
            <div className='testimonial-owner'>
              <figure>
                <img src='assets/images/clients/client1.webp' alt='client' />
              </figure>
              <div>
                <p className='pComp'>Jawad Bachare </p>
                <span>Directeur Executi chez CCIF* ADDH</span>
                <div className='product-ratings '>
                      <span className='ratings' style={{ width: '100%' }} />
                      <svg
                        className='icon'
                        style={{
                          width: '1.5rem',
                          height: '1.5rem',
                          fill: '#ffc107',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>

                      {/* End .ratings */}
                    </div>
              </div>
            </div>
            {/* End .testimonial-owner */}
            <blockquote>
              <p className='pComp'>
                Excellent service , des réponses et une assistance efficace !
                Qualité du matériel excellente ! Bref une entreprise 5 étoiles.
              </p>
            </blockquote>
          </div>
          {/* End .testimonial */}
          <div className='testimonial'>
            <div className='testimonial-owner'>
              <figure>
                <img src='assets/images/clients/client5.webp' alt='client' />
              </figure>
              <div>
                <p className='pComp'>Jacqueline JOSSERAND</p>
                <span>Avocate</span>
                <div className='product-ratings '>
                      <span className='ratings' style={{ width: '100%' }} />
                      <svg
                        className='icon'
                        style={{
                          width: '1.5rem',
                          height: '1.5rem',
                          fill: '#ffc107',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>

                      {/* End .ratings */}
                    </div>
              </div>
            </div>
            {/* End .testimonial-owner */}
            <blockquote>
              <p className='pComp'>
                Franchement c'est très pratique,commande faite le matin,
                récupérée le soir.Prix intéressant. Parfait
              </p>
            </blockquote>
          </div>
          {/* End .testimonial */}
          <div className='testimonial'>
            <div className='testimonial-owner'>
              <figure>
                <img src='assets/images/clients/client4.webp' alt='client' />
              </figure>



                    {/* End .product-ratings */}
                  {/* End .product-container */}

                  {/* End .price-box */}
                {/* End .product-details */}
              <div>

                <p className='pComp'>Anne FLAMANT</p>
                <span>Assistante de direction</span>
                <div className='product-ratings '>
                      <span className='ratings' style={{ width: '100%' }} />
                      <svg
                        className='icon'
                        style={{
                          width: '1.5rem',
                          height: '1.5rem',
                          fill: '#ffc107',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>

                      {/* End .ratings */}
                    </div>
              </div>

            </div>
            {/* End .testimonial-owner */}

            <blockquote>
              <p className='pComp'>Très professionnel Rapide Je recommande !</p>
            </blockquote>
          </div>
          {/* End .testimonial */}
          <div className='testimonial'>
            <div className='testimonial-owner'>
              <figure>
                <img src='assets/images/clients/client8.webp' alt='client' />
              </figure>
              <div>
                <p className='pComp'>Hervé brites</p>
                <span>Directeur d’agence de transport</span>
                <div className='product-ratings '>
                      <span className='ratings' style={{ width: '100%' }} />
                      <svg
                        className='icon'
                        style={{
                          width: '1.5rem',
                          height: '1.5rem',
                          fill: '#ffc107',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>

                      {/* End .ratings */}
                    </div>
              </div>
            </div>
            {/* End .testimonial-owner */}
            <blockquote>
              <p className='pComp'>
                Aspects positifs : Professionnalisme Vendeur réactif et
                compétent qui propose toujours une solution pour satisfaire le
                client. J'ai commandé une imprimante pendant le confinement et
                l'imprimante n'était pas disponible, le vendeur m'a proposé une
                machine équivalente dans la foulée et à même assuré le suivie de
                ma commande ! Top
              </p>
            </blockquote>
          </div>
          {/* End .testimonial */}
          <div className='testimonial'>
            <div className='testimonial-owner'>
              <figure>
                <img src='assets/images/clients/client3.webp' alt='client' />
              </figure>
              <div>
                <p className='pComp'>Christopher Guillou</p>
                <span>Responsable d'achat</span>
                <div className='product-ratings '>
                      <span className='ratings' style={{ width: '100%' }} />
                      <svg
                        className='icon'
                        style={{
                          width: '1.5rem',
                          height: '1.5rem',
                          fill: '#ffc107',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>

                      {/* End .ratings */}
                    </div>
              </div>
            </div>
            {/* End .testimonial-owner */}
            <blockquote>
              <p className='pComp'>
                Aspects positifs: Professionnalisme, Réactivité Un service
                professionnel impeccable et un service relationnel client précis
                et très aimable.
              </p>
            </blockquote>
          </div>
          {/* End .testimonial */}
          <div className='testimonial'>
            <div className='testimonial-owner'>
              <figure>
                <img src='assets/images/clients/client7.webp' alt='client' />
              </figure>
              <div>
                <p className='pComp'>Rudy Pasqual</p>
                <span>Gérant</span>
                <div className='product-ratings '>
                      <span className='ratings' style={{ width: '100%' }} />
                      <svg
                        className='icon'
                        style={{
                          width: '1.5rem',
                          height: '1.5rem',
                          fill: '#ffc107',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>
                      <svg
                        className='icon'
                        style={{
                          fill: '#ffc107',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      >
                        <use xlinkHref='assets/icons.svg#icon-star-full' />
                      </svg>

                      {/* End .ratings */}
                    </div>
              </div>
            </div>
            {/* End .testimonial-owner */}
            <blockquote>
              <p className='pComp'>
                Bon professionnel. A l'écoute, réactif et rapport qualité / prix
                très compétitif.
              </p>
            </blockquote>
          </div>
          {/* End .testimonial */}
        </OwlCarousel>
        {/* End .testimonials-slider */}
      </div>
      {/* End .container */}
    </div>
  );
}
export default Testimonials;
