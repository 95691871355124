import { Link } from 'react-router-dom';

function NavbarMobile() {
  const toggle = (id) => {
    let element = document.getElementById(id);
    if (element.classList.contains('open')) {
      element.classList.remove('open');
    } else {
      element.classList.add('open');
    }
  };


  return (
    <div>
      <div
        className='mobile-menu-overlay'
        onClick={() => {
          document.body.classList.remove('mmenu-active');
        }}
      ></div>
      <div className='mobile-menu-container'>
        <div className='mobile-menu-wrapper'>
          <span className='mobile-menu-close'>
            <i className='icon-cancel' />
          </span>
          <nav className='mobile-nav'>
            <ul className='mobile-menu mb-3'>
              <li className='active'>
                <Link to='/'>Accueil</Link>
              </li>
              <li
                onClick={() => {
                  toggle('imprim-mobile');
                }}
              >
                <Link to='#'>
                  Imprimantes{' '}
                  <svg
                    className='icon-down'
                    style={{
                      fill: 'white',
                      position: 'absolute',
                      right: '-6%',
                      top: '33%',
                    }}
                  >
                    <use xlinkHref='assets/icons.svg#icon-cheveron-down' />
                  </svg>
                </Link>
                <ul id='imprim-mobile'>
                  <li>
                    <Link to='/imprimante-multifonction/'>Multifonction</Link>
                  </li>
                  <li>
                    <Link to='/imprimante-jet-d-encre/'>Jet d'encre </Link>
                  </li>
                  <li>
                    <Link to='/imprimante-laser/'>Laser</Link>
                  </li>
                </ul>
              </li>
              <li
                onClick={() => {
                  toggle('consommable');
                }}
              >
                <Link to='/consommable/'>
                  Consommables{' '}
                  <svg
                    className='icon-down'
                    style={{
                      fill: 'white',
                      position: 'absolute',
                      right: '-6%',
                      top: '33%',
                    }}
                  >
                    <use xlinkHref='assets/icons.svg#icon-cheveron-down' />
                  </svg>
                </Link>
                <ul id='consommable'>
                  <li>
                    <Link to='/consommable/toner/'>Toners</Link>
                  </li>
                  <li>
                    <Link to='/consommable/cartouche/'>Cartouches</Link>
                  </li>
                  <li>
                    <Link to='/consommable/ruban/'>Rubans</Link>
                  </li>
                  <li>
                    <Link to='/consommable/bobine-thermique/'>
                      Bobines thermiques
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                onClick={() => {
                  toggle('mobile-autre');
                }}
              >
                <Link to='#'>
                  Autres matériels
                  <svg
                    className='icon-down'
                    style={{
                      fill: 'white',
                      position: 'absolute',
                      right: '-6%',
                      top: '33%',
                    }}
                  >
                    <use xlinkHref='assets/icons.svg#icon-cheveron-down' />
                  </svg>
                </Link>
                <ul id='mobile-autre'>
                  <li>
                    <Link to='/autres-materiels/imprimante-etiquette/'>
                      Imprimantes étiquéttes
                    </Link>
                  </li>
                  <li>
                    <Link to='/autres-materiels/imprimante-point-de-vente/'>
                      Imprimantes point de vente
                    </Link>
                  </li>
                  <li>
                    <Link to='/traceur/'>Traceur</Link>
                  </li>
                </ul>
              </li>
              <li
                onClick={() => {
                  toggle('mobile-marques');
                }}
              >
                <Link to='#'>
                  Marques{' '}
                  <svg
                    className='icon-down'
                    style={{
                      fill: 'white',
                      position: 'absolute',
                      right: '-6%',
                      top: '33%',
                    }}
                  >
                    <use xlinkHref='assets/icons.svg#icon-cheveron-down' />
                  </svg>
                </Link>

                <ul id='mobile-marques'>
                  <li>
                    <Link to='/marques/imprimante-hp/'>Hp</Link>
                  </li>

                  <li>
                    <Link to='/marques/imprimante-brother/'>Brother</Link>
                  </li>
                  <li>
                    <Link to='/marques/imprimante-epson/'>Epson</Link>
                  </li>
                  <li>
                    <Link to='/marques/imprimante-samsung/'>Samsung</Link>
                  </li>
                  <li>
                    <Link to='/marques/imprimante-canon/'>Canon</Link>
                  </li>
                  <li>
                    <Link to='/marques/imprimante-lexmark/'>Lexmark</Link>
                  </li>
                  <li>
                    <Link to='/marques/imprimante-ricoh/'>Ricoh</Link>
                  </li>
                  <li>
                    <Link to='/marques/imprimante-xerox/'>Xerox</Link>
                  </li>
                  <li>
                    <Link to='/marques/imprimante-kyocera/'>Kyocera</Link>
                  </li>
                  <li>
                    <Link to='/marques/imprimante-oki/'>Oki</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to='/promotions/'>
                  Promotion{' '}
                  <span
                    className='tip tip-hot'
                    style={{ width: 'max-content', position: 'absolute' }}
                  >
                    Prix exceptionnel !
                  </span>
                </Link>
              </li>
              <li>
                <Link to='/contrat/'>
                  Impression illimitée*
                  <span
                    className='tip t tip-new'
                    style={{ position: 'absolute' }}
                  >
                    Nouveau
                  </span>
                </Link>
              </li>
              <li>
                <Link to='/demande-devis/'>Demander un devis</Link>
              </li>
            </ul>
          </nav>
          <div className='social-icons'>
            <a
              href='https://www.facebook.com/www.alfaprint.fr/'
              className='social-icon'
              target='_blank'
              rel='noopener'
            >
              <i className='icon-facebook' />
            </a>
            <a
              href='https://twitter.com/AlfaPrint01'
              rel='noopener'
              className='social-icon'
              target='_blank'
            >
              <i className='icon-twitter' />
            </a>
            <a
              href='https://www.instagram.com/alfaprint.fr/'
              className='social-icon'
              target='_blank'
              rel='noopener'
            >
              <i className='icon-instagram' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default NavbarMobile;
