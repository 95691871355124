import OwlCarousel from 'react-owl-carousel';
import { useEffect, useState } from 'react';
import ThingsContext from '../context';
import { useContext } from 'react';
function Brands() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isscrolled, setisscrolled] = useState(false);
  const context = useContext(ThingsContext);
  useEffect(() => {
    // POST request using fetch inside useEffect React hook
    if (isscrolled) {
      setIsLoaded(true);
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [isscrolled]);
  useEffect(() => {
    window.addEventListener('scroll', checkscroll);
    return () => {
      window.removeEventListener('scroll', checkscroll);
    };
  }, []);
  function isScrolledIntoView(el) {
    let element = document.getElementById(el);
    var rect = element.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    // Only completely visible elements return true:
    // var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:
    var isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }
  const checkscroll = () => {
    if (isLoaded == false) {
      const bool = isScrolledIntoView('brands');

      if (bool == true) {
        setisscrolled(bool);
        window.removeEventListener('scroll', checkscroll);
      }
    } else {
      window.removeEventListener('scroll', checkscroll);
    }
  };
  if (isLoaded == true) {
    return (
      <div className='brands-section ' id='brands'>
        <div className='container-fluid'>
          <h2
            className='section-title'
            style={{ textTransform: 'none', borderBottom: '1px solid #e4e4e4' }}
          >
            Nos partenaires{' '}
          </h2>{' '}
          <OwlCarousel
            loop
            autoplayTimeout={3000}
            className='brands-slider owl-carousel owl-theme images-center'
            items={2}
            lazyLoad
            dots={false}
            margin={20}
            responsive={{
              576: { items: 4 },
              768: { items: 6 },
            }}
          >
            <div
              style={{
                height: '170px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                className='owl-lazy'
                data-src={'assets/images/brands/dell.' + context}
                width={140}
                height={60}
                alt='Imprimantes de la marque Dell'
              />
            </div>
            <div
              style={{
                height: '170px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                className='owl-lazy'
                data-src={'assets/images/brands/hp.' + context}
                width={140}
                height={60}
                alt='Imprimantes et cartouches HP'
              />
            </div>
            <div
              style={{
                height: '170px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                className='owl-lazy'
                data-src={'assets/images/brands/canon.' + context}
                width={140}
                height={60}
                alt='Imprimantes et cartouches Canon'
              />
            </div>
            <div
              style={{
                height: '170px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                className='owl-lazy'
                data-src={'assets/images/brands/brother.' + context}
                width={140}
                height={60}
                alt='Imprimantes et cartouches Brother'
              />
            </div>
            <div
              style={{
                height: '170px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                className='owl-lazy'
                data-src={'assets/images/brands/epson.' + context}
                width={140}
                height={60}
                alt='Imprimantes de la marque Epson'
              />
            </div>
            <div
              style={{
                height: '170px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                className='owl-lazy'
                data-src={'assets/images/brands/intermec.' + context}
                width={140}
                height={60}
                alt='Imprimantes de la marque Intermec'
              />
            </div>
            <div
              style={{
                height: '170px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                className='owl-lazy'
                data-src={'assets/images/brands/lexmarklogo.' + context}
                width={140}
                height={60}
                alt='Imprimantes et cartouches de lexmark '
              />
            </div>
            <div
              style={{
                height: '170px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                className='owl-lazy'
                data-src={'assets/images/brands/Kyoceralogo.' + context}
                width={140}
                height={60}
                alt='Imprimantes et cartouches de Kyocera'
              />
            </div>
          </OwlCarousel>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className='brands-section'
        style={{ minHeight: '20rem' }}
        id='brands'
      ></div>
    );
  }
}
export default Brands;
