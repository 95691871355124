import { Link } from 'react-router-dom';
import './items.css';

import ThingsContext from '../../context';

import { useEffect, useState, useContext } from 'react';
function Griditem() {
  const [isscrolled, setisscrolled] = useState(false);
  const [isloaded, setisloaded] = useState(false);
  const context = useContext(ThingsContext);
  useEffect(() => {
    window.addEventListener('scroll', checkscroll);
    return () => {
      window.removeEventListener('scroll', checkscroll);
    };
  }, []);

  useEffect(() => {
    if (isscrolled) {
      setisloaded(true);
    }
  }, [isscrolled]);

  function isScrolledIntoView(el) {
    let element = document.getElementById(el);
    var rect = element.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    // Only completely visible elements return true:
    // var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:
    var isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }
  const checkscroll = () => {
    if (isloaded == false) {
      setisscrolled(isScrolledIntoView('items'));
    } else {
      window.removeEventListener('scroll', checkscroll);
    }
  };
  if (isloaded) {
    return (
      <div className='container-fluid' id='items'>
        <div
          className='display-flex'
          style={{ flexWrap: 'wrap', justifyContent: 'center' }}
        >
          <div className='item-new'>
            {' '}
            <img
              src={'assets/imagev2/multi1.' + context}
              alt='Imprimante multifonction recommandé par l’équipe alfa Print '
            />
            <div className='item-text'>
              <p>Imprimante multifonction</p>
              <Link to='imprimante-multifonction/'>
                <button className='item-text-button'>En profiter</button>
              </Link>
            </div>
          </div>

          <div className='item-new'>
            <img
              src={'assets/imagev2/laser2.' + context}
              alt='Notre sélection d’imprimante jet d’encre '
            />
            <div className='item-text'>
              <p>Imprimante jet d'encre</p>
              <Link to='imprimante-jet-d-encre/'>
                <button className='item-text-button'>En profiter</button>
              </Link>{' '}
            </div>
          </div>
          <div className='item-new'>
            <img
              src={'assets/imagev2/jet3.' + context}
              alt='Imprimante laser au meilleur prix en France '
            />
            <div className='item-text'>
              <p>Imprimante Laser</p>
              <Link to='imprimante-laser/'>
                <button className='item-text-button'>En profiter</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className='container-fluid' id='items'>
        <div
          className='display-flex'
          style={{ flexWrap: 'wrap', justifyContent: 'center' }}
        >
          <div className='item-new'></div>

          <div className='item-new'></div>

          <div className='item-new'></div>
        </div>
      </div>
    );
}
export default Griditem;
