exports.login = (data) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ login: data.email, password: data.password }),
  };
  return fetch(
    'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/client/login',
    requestOptions
  );
};
exports.contact = (data) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email: data.email, content: data.content }),
  };
  return fetch(
    'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/addemailclient',
    requestOptions
  )
    .then((response) => response.json())
    .then((res) => {
      return res;
    });
};
exports.email = (data) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email: data.email }),
  };
  return fetch(
    'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/add/email',
    requestOptions
  )
    .then((response) => response.json())
    .then((res) => {
      if (res == data.email) {
        return true;
      }
    });
};
exports.inscrire = (data) => {


  if(data.pro=="Professionel"){
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      gender: data.gender,
      nom: data.nom,
      prenom: data.prenom,
      raison_sociale: data.rs,
      telephone: data.telephone,
      adresse: data.adresse,
      ville: data.ville,
      cp: data.cp,
      email: data.email,
      fonction: data.fonction,
      pro: data.pro,
      country: data.country,
      siret: data.siret,
      ville2: '',
      contact2: '',
      street2: '',
      rs2: data.rs,
      zp2: '',
    }),
  };
  return fetch(
    'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/client/register',
    requestOptions
  );
}
else{

  const requestOptions = {

    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      gender: data.gender,
      nom: data.nom,
      prenom: data.prenom,
      raison_sociale: data.rs,
      telephone: data.telephone,
      adresse: data.adresse,
      ville: data.ville,
      cp: data.cp,
      email: data.email,
      pro: data.pro,
      country: data.country,
      siret: data.siret,
      ville2: '',
      contact2: '',
      street2: '',
      rs2: '',
      zp2: '',
    }),
  };
  return fetch(
    'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/client/register',
    requestOptions
  );
}

};
exports.getprix = (id) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ produit_id: id }),
  };
  return fetch(
    'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/get/price',
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
exports.chercher = () => {};
exports.sendMail = (data) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email: data.email }),
  };
  return fetch(
    'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/reset/password/send-code-validation'
    ,
    requestOptions
  )

};
exports.oublie = (data) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email: data.email }),
  };
  return fetch(
    'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/reset/password/send-mail'
    ,
    requestOptions
  )

};
exports.verficationCode = (data) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email: data.email ,code_validation:data.code_validation}),
  };
  return fetch(
    'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/reset/password/get-code-validation'
    ,
    requestOptions
  )

};