import './chercher2.css';
import { isMobile } from 'react-device-detect';

import { Link, useHistory } from 'react-router-dom';
import React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
const Item = styled(Paper)(({ theme }) => ({

  ...theme.typography.body2,
 
  textAlign: 'start',
 
}));
function Cherchermobile(props) {
  const history = useHistory();
  const [shortlisteImprimante, setshortlistImprimante] = useState([]);
  const [input, setInput] = useState('');

  const [focus, setFocus] = useState(false);

  const [select, setSelect] = useState(0);

  const [loading, setLoading] = useState(false);

  const [vide, setvide] = useState(false);

  const [liste, setListe] = useState(props.list);

  const [shortliste, setshortlist] = useState([]);

  const [route, setroute] = useState('');

  const [json, setJson] = useState([]);
  const refDel = React.createRef();
  const refInput = React.createRef();
  const handleInput = (e) => {
    setInput(e.target.value);

    if (e.target.value === '') {
      setFocus(false);
    } else {
      setFocus(true);
    }
  };
  const liste_json = props.liste_json;  
  const liste_sp=[]
	useEffect(() => {
    if (input != "") {
      refDel.current.style.display = "block";
    } else {
      refDel.current.style.display = "none";
    }

	
	  var sh = ""; 
      var liste_result = []; 
      var liste_result_sp_fin = []; 
      var liste_result_pr_fin = []; 
      var liste_res = []; 
      var liste_pr = []; 
      var liste_sp = []; 
	  
      if (input.length > 1 && liste_json.length > 0) { 
      var  tex=input.split(" "); 
      var  chaine='';
      for (let i = 0; i < tex.length; i++) 
      { 
		sh = tex[i]; 
        // sub=sh.substring(0,2);        
        // sh = sh.replace(sub,' '+sub+' ');  
        sh=sh+' ';
        sh = sh.replace('TN','TN ');
        sh = sh.replace('tn','tn ');
        sh = sh.replace('dr','dr ');
        sh = sh.replace('DR','DR '); 
        sh = sh.replace('CF','CF ');
        sh = sh.replace('cf','cf ');
        sh = sh.replace('tb','tb ');
        sh = sh.replace('cl','cl ');
        sh = sh.replace('fc','fc ');
        sh = sh.replace('lc','lc ');
        sh = sh.replace('tk','tk ');
        sh = sh.replace('dv','dv ');
        sh = sh.replace('hg','hg ');
        sh = sh.replace('wx','wx ');
        sh = sh.replace('bci','bci ');
        sh = sh.replace('mk','mk ');
        sh = sh.replace('no','no ');
        sh = sh.replace('tze','tze ');
        sh = sh.replace('clt','clt ');
        sh = sh.replace('fx','fx ');
        sh = sh.replace('ep','ep ');
        sh = sh.replace('tx','tx ');
        sh = sh.replace('cz','cz ');
        sh = sh.replace('mlt','mlt ');
        sh = sh.replace('mc','mc ');
        sh = sh.replace('tnp','tnp '); 
        sh = sh.replace('type','type ');
        sh = sh.replace('pfi','pfi '); 
        sh = sh.replace('npg','npg ');
        chaine=chaine+sh;
      }   
      tex=chaine.split(" ");  
      setvide(true); 
      let index2 = 0;
      var count=tex.length;
      var sub=''; 

      const liste_name = [];
      const liste_names = [];
      const liste_names2 = [];
      for (let index = 0; index < liste_json.length; index++) 
	  {
        let inc=0; 
        let inc2=0;  
        for (let i = 0; i < tex.length; i++) 
		{  
          sh =tex[i].toUpperCase();   
          if (liste_json[index].name.toUpperCase().includes(sh))
		  inc++;  
          if (liste_json[index].names.toUpperCase().includes(sh))
		  inc2++;   
        }
        if(count==inc){ 
          liste_name.push(liste_json[index]);
        } 
        if(count==inc2){ 
          liste_names.push(liste_json[index]);
        }   
      }

		const liste_name_p1=[];
		const liste_name_p2=[];
		let found_supply=0;
		let found_printer=0;
		let p=0;
		
		for (let index = 0; index < liste_name.length; index++) 
		{     
			let text=liste_name[index].name;

			if(liste_name[index].names2!="" && p==0)
			{
			  liste_names2.push(liste_name[index].names2);
			  p++;
			}
			if(text.indexOf(input.toUpperCase())>=0) 
			{
			  liste_name_p1.push(liste_name[index]); 
			} 
			else
			liste_name_p2.push(liste_name[index]); 
		} 
		
	 
		let liste_res=[]; 
		//resulat = recherche par name 
		liste_result = liste_name_p1.concat(liste_name_p2);
		//resulat = recherche par names( en complement) 
		liste_result = liste_result.concat(liste_names);

		if(liste_names2.length>0)
		{
			let names2=liste_names2[0].split(","); 
			console.log(liste_name,'liste_names222');  
			for (let m = 0; m < liste_json.length; m++) 
			{  
				if(liste_res.length>10)
				break;
				for (let n = 0; n < names2.length; n++) 
				{ 
					if(liste_json[m].product_id==names2[n] ){
					liste_res.push(liste_json[m]);
					}     
				} 
			} 
	    }
 
		var liste_result_sp=liste_result.filter((data) => data.sp === "Supply");
		var liste_result_pr=liste_result.filter((data) => data.sp === "Printer");
		
		var liste_sp=liste_res.filter((data) => data.sp === "Supply");
		var liste_pr=liste_res.filter((data) => data.sp === "Printer");
		
		 
		  
	    // if(liste_sp.length>0 && liste_result_sp.length==0)
	    var liste_result_sp_fin=liste_result_sp.concat(liste_sp);
	    var liste_result_pr_fin=liste_result_pr.concat(liste_pr);
		 
		 
		
		console.log(liste_sp,'liste_sp');
		console.log(liste_pr,'liste_pr');
		console.log(liste_result_pr,'liste_result_pr');
		console.log(liste_result_sp,'liste_result_sp');
		
        if (liste_result.length > 0) 
        setLoading(false); 
        setFocus(true);
	  }
	  
	
 
 

  
	setshortlist(liste_result_sp_fin);
    setshortlistImprimante(liste_result_pr_fin); 
   
    return () => {};
  }, [input]);
 
  useEffect(() => {
    setListe(props.list);
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [props.list]);

  const handleload = () => {
    const requestOptions = {
      method: 'GET',
    };

    fetch(
      'https://ns3156326.ip-51-91-212.eu/ecom_api/public/api/all/produits?_=1615800308062',

      requestOptions
    ).then((res) =>
      res.json().then((response) => {
        // response = Object.values(response);

        setListe(response);
      })
    );
  };


 
 
  const handleScroll = (e) => {
    if (
      document.body.scrollTop > 200 ||
      document.documentElement.scrollTop > 200
    ) {
      // setInput('');

      setFocus(false);
    }
  };

  const submit = () => {
    if (route != '') history.push(route);
  };
  const delInput = () => {
    setInput('');
    refInput.current.value = '';
  };

  
  const handleselect = (e) => {
    setSelect(e.target.value);

    if (e.target.value == 1) {
      setroute('/imprimante-multifonction');
    } else if (e.target.value == 2) {
      setroute('/imprimante-laser');
    } else if (e.target.value == 3) {
      setroute('/imprimante-jet-d-encre');
    } else if (e.target.value == 4) {
      setroute('/autres-materiels/imprimante-etiquette');
    } else if (e.target.value == 5) {
      setroute('/traceur');
    } else if (e.target.value == 6) {
      setroute('/consommable');
    } else if (e.target.value == 0) {
      setroute('');
    }
  };
  function countEspace(string){
return string.split(' ').length - 1;
  }
 
console.log(countEspace('HP PAGEWIDE 477 DW'),"dwdd")
  return (
    <div
      className={
        'header-icon header-search header-search-inline header-search-category w-lg-max pl-12 ' +
        props.class
      }
    >
      <a href='#' className='search-toggle' role='button'>
        <i className='icon-search-3' />
      </a>

      <div className='header-search-wrapper mobile-wrap'>
        <input
          className='form-control'
          ref={refInput}

          placeholder={liste_json.length < 1 ? 'Loading' : 'Chercher...'}
          disabled={liste_json.length < 1}
          required
          onChange={handleInput}
          // onBlur={() => {

          // setFocus(false);

          // // setInput('');

          // }}

          onFocus={() => {
            if (liste_json.length > 1) {
              setFocus(true);
            }
          }}
        />
        <button
          className='btn2 icon-search-6'
          ref={refDel}
          style={{
            display: 'none',

            justifyContent: 'center',
            borderLeft: '0px',
            alignItems: 'center',
            borderRadius: '0',
          }}
          onClick={delInput}
        >
          <svg
            className='icon-down'
            style={{
              fill: '#777',

              width: '20px',

              height: '20px',
            }}
          >
            <use xlinkHref='assets/icons.svg#icon-cross' />
          </svg>
        </button>
    
        {/* End .select-custom */}{' '}
        <button
          className='btn icon-search-3'
          style={{
            display: 'flex',

            justifyContent: 'center',

            alignItems: 'center',
          }}
          onClick={submit}
        >
          <svg
            className='icon-down'
            style={{
              fill: '#777',

              width: '20px',

              height: '20px',
            }}
          >
            <use xlinkHref='assets/icons.svg#icon-search' />
          </svg>
        </button>
        {focus && (
          <div className='choix choix-mobile'>{shortlisteImprimante.length > 0 &&
                       <h4 style={{marginBottom:"0,5rem"}}>Choisissez votre imprimante</h4>}
            {shortlisteImprimante.length > 0 &&
              shortlisteImprimante.slice(0,10).map((data, index) => {
                return (
                  <Paper sx={{ flexGrow: 1,elevation:0 }}style={{boxShadow:"none"}}>

                  <Grid container spacing={2} justifyContent="flex-start"
    alignItems="center"style={{marginLeft:"0px"}} >

                    <Grid item xs={3} md={4} style={{boxShadow:"none",paddingLeft:"0px",marginLeft:"0px !important"}}>
           
                      <Item style={{boxShadow:"none"}}><Link
                                  className='recherche_res'
                                  to={`/${data.url_fiche}/`}
                                  onClick={() => {
                                    setFocus(false);
                                  }}
                                  alt={data.name}
                                  title={data.name}
                                >
                                  {data.sp !== 'Supply' && (
                                    <img
                                      className='image-recherche'
                                      src={`/assets/img/printers_image/${data.model_id}.webp `}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = 'assets/img/impr.webp';
                                      }}
                                    />
                                  )}
                          
                                </Link></Item>
                    </Grid>
                    <Grid item xs={8} md={4} style={{paddingLeft:"0px",boxShadow:"none"}}>
                      <Link to={`/${data.url_fiche}/`}>
                    <Item style={{textAlign:"start",fontSize:"14px",boxShadow:"none"}}>   {data.name}</Item> 
                    </Link>
                    </Grid>
                 
                  </Grid>
                </Paper>
                );
              })}{' '}
{shortliste.length > 0 &&
<h4 style={{marginBottom:"0,5rem"}}>      En complément </h4>
}
{shortliste.length > 0 &&
              shortliste.slice(0,10).map((data, index) => {
                return (
      
                  <Paper sx={{ flexGrow: 1,elevation:0 }}style={{boxShadow:"none"}}>
                <Grid container spacing={2} justifyContent="flex-start"
  alignItems="center" style={{marginLeft:"0px"}}>
                  <Grid item xs={3} md={4} style={{boxShadow:"none",paddingLeft:"0px",marginLeft:"0px !important" }}>
                    <Item style={{boxShadow:"none",marginLeft:"0px !important" }}><Link
                                className='recherche_res'
                                to={`/${data.url_fiche}/`}
                                onClick={() => {
                                  setFocus(false);
                                }}
                                alt={data.name}
                                title={data.name}
                              >
                                {data.sp = 'Supply' && (
                                  <img
                                    className='image-recherche'
                                    src={`/assets/img/supplies_image/${data.model_id}.webp `}
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = 'assets/img/impr.webp';
                                    }}
                                  />
                                )}
                        
                              </Link></Item>
                  </Grid>
                  <Grid item xs={8} md={4} style={{paddingLeft:"0px",boxShadow:"none"}}>
                  <Link to={`/${data.url_fiche}/`}>
                  <Item style={{textAlign:"start",fontSize:"14px",boxShadow:"none"}}>   {data.name}</Item> 
                  </Link>
                  </Grid>
               
                </Grid>
              </Paper>
                );
              
              })}{' '}
      
           
          </div>
        )}
      </div>

      {/* End .header-search-wrapper */}
    </div>
  );
}

export default Cherchermobile;
